import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedSurveyResultsRoutingModule } from './shared-survey-results-routing.module';
import { SharedSurveyResultsComponent } from './shared-survey-results.component';
import { AgChartsAngularModule } from 'ag-charts-angular';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    SharedSurveyResultsRoutingModule,
    AgChartsAngularModule
  ],
  declarations: [SharedSurveyResultsComponent]
})
export class SharedSurveyResultsModule { }
