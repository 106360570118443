import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Logger } from '@app/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
const log = new Logger('Add Zipcodes');

@Component({
  selector: 'app-add-zipcode',
  templateUrl: './add-zipcode.component.html',
  styleUrls: ['./add-zipcode.component.scss']
})
export class AddZipcodeComponent implements OnInit {
  public isLoading: boolean = false;
  public colonies: any[] = [];
  zipcode: string;

  constructor(
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.zipcodes();
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }

  async zipcodes(): Promise<void> {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const resp = await this.afs
        .collection('zipcodes-suggestion')
        .doc(this.zipcode)
        .collection('colonies')
        .ref.get();
      this.colonies = resp.docs.map(item => {
        const data: any = item.data();
        const id: string = item.id;
        return { id, ...data };
      });
      console.log(this.colonies);
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
    return;
  }

  public async addPostalcodeAlert(location: any) {
    console.log(location);
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const data = {
        city: location && location.city ? location.city : '',
        colony: location && location.colony ? location.colony : '',
        postal_code: location && location.postal_code ? location.postal_code : '',
        state: location && location.state ? location.state : ''
      };
      const REF1 = this.afs.collection('zipcodes').doc(this.zipcode).ref;
      const REF2 = this.afs.collection('zipcodes-suggestion').doc(this.zipcode).ref;
      const zip = await REF1.get();
      if (!(zip.exists === true)) {
        await REF1.set({
          city: data.city,
          state: data.state
        });
      }
      await REF1.collection('colonies').add(data);
      const respUsers = await REF2.collection('colonies')
        .doc(location.id)
        .collection('users')
        .get();
      const users: string[] = respUsers.docs.map(element => {
        const data: any = element.data();
        return String(data.uid);
      });
      for (let user of users) {
        const refUser = this.afs.collection('medico-meta').doc(user).ref;
        const respUser = await refUser.get();
        const dataUser: any = respUser.data();
        if (
          dataUser &&
          dataUser.address1 &&
          dataUser.address1.postalCode &&
          dataUser.address1.postalCode === data.postal_code
        ) {
          refUser.update({
            'address1.colony': data.colony
          });
        } else if (
          dataUser &&
          dataUser.address2 &&
          dataUser.address2.postalCode &&
          dataUser.address2.postalCode === data.postal_code
        ) {
          refUser.update({
            'address2.colony': data.colony
          });
        } else {
          log.error('=== No econtrado ===');
        }
        await REF2.collection('colonies')
          .doc(location.id)
          .collection('users')
          .doc(user)
          .delete();
      }
      //DELETE
      await REF2.collection('colonies')
        .doc(location.id)
        .delete();
      const resp = await REF2.collection('colonies').get();
      if (resp.empty === true) {
        await REF2.delete();
      }
      this.activateConfirm();
      this.closeModal();
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
    return;
  }

  async activateConfirm() {
    const alert = await this.alertController.create({
      header: '¡Correcto!',
      message: 'Código postal agregado correctamente.',
      buttons: [
        {
          text: 'Enterado',
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }
}
