import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatComponent } from './chat.component';
import { CreateChatComponent } from './create-chat/create-chat.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: '', component: ChatComponent, data: { title: 'Chat' } },
  { path: 'create-chat', component: CreateChatComponent, data: { title: 'Crear Chat' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ChatRoutingModule { }
