import { Injectable } from '@angular/core';
import { WpService } from '@app/shared/services/wp.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { Logger } from '@app/core';
const log = new Logger('SearchService');

export interface Member {
  name: string;
  id: number;
  especialidad1: string;
  especialidad2: string;
  type: string;
  empresa?: string;
  giro?: string;
  estado1?: string;
  searchEstado1?: string;
  ciudad1?: string;
  colonia1?: string;
  colonia2?: string;
  estado2?: string;
  searchEstado2?: string;
  ciudad2?: string;
  avatar: string;
}

export interface SearchInterface {
  totalPages: number;
  results: Member[];
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  members: any[];
  products: any[];

  private searchMemberType: string;
  private currentPage: number;
  private totalPages: number;
  public searchByLocation: boolean;
  public searchBySpecialty: boolean;
  public memberType: string;
  public normalize = (function () {
    var from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç',
      to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc',
      mapping: any = {};
    for (var i = 0, j = from.length; i < j; i++) mapping[from.charAt(i)] = to.charAt(i);
    return function (str: string) {
      var ret = [];
      for (var i = 0, j = str.length; i < j; i++) {
        var c = str.charAt(i);
        if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
        else ret.push(c);
      }
      return ret.join('');
    };
  })();

  constructor(private wp: WpService, private credential: CredentialsService, private utilities: UtilitiesService) {
    this.memberType = this.credential.credentials.type;
    this.searchMemberType = this.memberType === 'medico' ? 'representante-medico' : 'medico';
  }

  strip(html: any) {
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  async getProducts(
    page?: number,
    search?: string
  ): Promise<{ products: any[]; totalPages: number; currentPage: number }> {
    try {
      const response = await this.wp.content('medicines', 50, page, 'title', 'asc', [], search);
      this.currentPage = page ? page : 1;
      this.totalPages = Number(response.headers.get('x-wp-totalpages'));
      if (page && page > 1) {
        this.products = this.products.concat(response.body);
      } else {
        this.products = response.body;
      }
    } catch (error) {
      log.error(error);
    }
    return {
      products: this.products,
      totalPages: this.totalPages,
      currentPage: this.currentPage
    };
  }

  public async getNewMembers(
    user_id?: string,
    exclude?: number[]
  ): Promise<{ posts: any[]; totalPages: number; currentPage: number }> {
    try {
      const posts = await this.wp.members(20, 1, this.searchMemberType, '', user_id, '', exclude);
      this.currentPage = 1;
      this.totalPages = Number(posts.headers.get('x-wp-totalpages'));
      let members = Array.from(posts.body).map((item: any) => {
        let data = this.utilities.mapMemberData(item);
        return data;
      });
      this.members = members;
    } catch (error) {
      log.error(error);
    }
    return {
      posts: this.members,
      totalPages: this.totalPages,
      currentPage: this.currentPage
    };
  }

  public async getMembersV2(
    type: string,
    perPage?: number,
    page?: number,
    search?: string,
    state?: string,
    specialty?: number,
    company?: string
  ): Promise<SearchInterface> {
    let totalPages: number = 0;
    try {
      page = page ? page : 1;
      const posts = await this.wp.members2(type, page, perPage, search, state, specialty, company);
      totalPages = posts.totalPages;
      let members = Array.from(posts.results).map((item: any) => {
        return {
          avatar: item && item.avatar ? item.avatar : '',
          ciudad1: item && item.city_1 ? item.city_1 : '',
          ciudad2: item && item.city_2 ? item.city_2 : '',
          estado1: item && item.state_1 ? item.state_1 : '',
          colonia1: item && item.municipality_1 ? item.municipality_1 : '',
          estado2: item && item.state_2 ? item.state_2 : '',
          colonia2: item && item.municipality_2 ? item.municipality_2 : '',
          empresa: item && item.company ? item.company : '',
          giro: item && item.business_rotation ? item.business_rotation : '',
          especialidad1: item && item.speciality_1 ? item.speciality_1 : '',
          especialidad2: item && item.speciality_2 ? item.speciality_2 : '',
          id: Number(item && item.id ? item.id : 0),
          name:
            (item && item.name ? item.name : '') +
            ' ' +
            (item && item.first_name ? item.first_name : '') +
            ' ' +
            (item && item.last_name ? item.last_name : ''),
          type: item && item.member_type ? item.member_type : ''
        };
      });
      this.members = members;
    } catch (error) {
      log.error(error);
    }
    return { results: this.members, totalPages: totalPages };
  }
}
