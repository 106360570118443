import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { PointsComponent } from './points.component';

const routes: Routes = [{ path: '', component: PointsComponent }];

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule.forChild(routes), ReactiveFormsModule, FormsModule],
  declarations: [PointsComponent]
})
export class PointsModule { }
