<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> Detalle de cuenta de usuario</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item lines="none">
            <input type="text" [value]="user?.uid || ''" disabled="true" style="display: inline-block; width: 100%;" />
          </ion-item>
          <ion-item lines="none">
            <ion-label><strong>Email:</strong></ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="changeEmailAlert()" style="text-transform: lowercase;">
                {{ user?.email }}
                <ion-icon
                  slot="end"
                  name="checkmark-circle"
                  [color]="user?.emailVerified === true ? 'success' : 'medium'"
                ></ion-icon>
                <ion-icon slot="end" name="refresh"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
          <ion-item lines="none">
            <ion-toggle  [(ngModel)]="disabled" (ionChange)="enabled($event)"><strong>Habilitado:</strong></ion-toggle>
          </ion-item>
          <ion-item lines="none">
            <ion-label><strong>Fecha de creación:</strong></ion-label>
            <ion-buttons slot="end">
              <ion-button disabled="true">
                {{ user?.metadata?.creationTime }}
              </ion-button>
            </ion-buttons>
          </ion-item>
          <ion-item lines="none">
            <ion-label><strong>Fecha de último ingreso:</strong></ion-label>
            <ion-buttons slot="end">
              <ion-button disabled="true">
                {{ user?.metadata?.lastSignInTime }}
              </ion-button>
            </ion-buttons>
          </ion-item>
          <ion-button
            (click)="goInformation()"
            expand="block"
            color="warning"
            [disabled]="isLoading === true"
          >
            Modificar información de usuario
          </ion-button>
          <ion-button
            (click)="sendEmailVerification()"
            expand="block"
            color="tertiary"
            *ngIf="user?.emailVerified !== true"
            [disabled]="isLoading === true"
          >
            Enviar verificación de email
          </ion-button>
          <ion-button (click)="sendResetPassword()" expand="block" color="primary" [disabled]="isLoading === true">
            Enviar cambio de contraseña
          </ion-button>
          <ion-button (click)="changeEmailAlert()" expand="block" color="medium" [disabled]="isLoading === true">
            Cambiar Email
          </ion-button>
          <ion-button (click)="changePasswordAlert()" expand="block" color="secondary" [disabled]="isLoading === true">
            Actualizar contraseña
          </ion-button>
          <ion-button (click)="deleteAlert()" expand="block" color="danger" [disabled]="isLoading === true">
            Eliminar usuario
          </ion-button>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
