import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-notifications-sended',
  templateUrl: './notifications-sended.component.html',
  styleUrls: ['./notifications-sended.component.scss']
})
export class NotificationsSendedComponent implements OnInit {
  public items: any[] = [];
  constructor(
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private alertController: AlertController
  ) {}

  async init() {
    try {
      const col: AngularFirestoreCollection<any> = this.afs.collection('notifications-batch');
      const resp = await col.ref
        .orderBy('date', 'desc')
        .limit(100)
        .get();
      this.items = resp.docs.map(e => {
        let data: any = e.data();
        try {
          data.dateString = data && data.date ? moment(data.date.toDate()).format('YYYY-MM-DD HH:mm') : '';
        } catch (error) {
          console.error(error);
        }
        return {
          id: e.id,
          ...data
        };
      });
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit() {
    this.init();
  }

  closeModal(id?: string) {
    this.modalCtrl.dismiss({ id });
  }

  async alertConfirm(id: string) {
    const alert = await this.alertController.create({
      header: `Confirmar reenvio`,
      message: `¿Está seguro de reenviar esta campaña (${id})?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Reenviar',
          handler: async () => {
            this.closeModal(id);
          }
        }
      ]
    });
    await alert.present();
  }
}
