import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Routes, RouterModule } from '@angular/router';
import { DeleteContactsComponent } from './delete-contacts.component';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [{ path: '', component: DeleteContactsComponent }];

@NgModule({
  declarations: [DeleteContactsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    SharedModule
  ]
})
export class DeleteContactsModule {}
