import { Injectable } from '@angular/core';
import { Logger } from '@app/core';
import { EmailService } from '@app/shared/services/email.service';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { AlertController, LoadingController, ModalController, PopoverController } from '@ionic/angular';
const log = new Logger('AuthAccountServicesService');

@Injectable({
  providedIn: 'root'
})
export class AuthAccountServicesService {
  constructor(
    private alertController: AlertController,
    private loadingCtrl: LoadingController,
    private firebaseService: FirebaseService,
    private utilities: UtilitiesService,
    private popoverController: PopoverController,
    private email: EmailService
  ) {}

  async deleteAlert(uid: string, component: ModalController | PopoverController) {
    try {
      const alert = await this.alertController.create({
        header: '¿Eliminar Usuario?',
        message: `¿Está seguro de eliminar a éste usuario: ${uid}?`,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: 'Eliminar',
            handler: () => {
              this.delete(uid, component);
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      log.error(e);
    }
  }

  public async delete(uid: string, component: ModalController | PopoverController) {
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      await this.firebaseService.callFunctionFB('deleteAuthUser', { uid: uid });
      try {
        component.dismiss({ uid: uid }, 'deleted');
      } catch (error) {
        log.error(error);
      }
      this.utilities.toast('Usuario eliminado correctamente.');
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
  }

  public async sendEmailVerification(user: any, component?: PopoverController) {
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      const email: string = user && user.email ? user.email : undefined;
      const uid: string = user && user.uid ? user.uid : undefined;
      const link = await this.firebaseService.callFunctionFB('emailVerificationAuthUser', {
        email: email
      });
      const data = await this.firebaseService.getUserData(uid);
      await this.email.sendEmailVerification({ email: email, link: link, name: data.name, appName: 'Conectimed' });
      if (component) {
        this.popoverController.dismiss();
      }
      this.utilities.toast(`Verificación de email enviada correctamente a ${email}.`);
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
  }

  public async sendResetPassword(user: any, component?: PopoverController) {
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      const email: string = user && user.email ? user.email : undefined;
      const uid: string = user && user.uid ? user.uid : undefined;
      const link = await this.firebaseService.callFunctionFB('resetPasswordAuthUser', {
        email: email
      });
      const data = await this.firebaseService.getUserData(uid);
      await this.email.sendResetPassword({ email: email, link: link, name: data.name, appName: 'Conectimed' });
      if (component) {
        this.popoverController.dismiss();
      }
      this.utilities.toast(`Cambio de contraseña enviada correctamente a ${email}.`);
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
  }
}
