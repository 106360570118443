import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { WpService } from '../shared/services/wp.service';
import * as XLSX from 'xlsx';
import { FirebaseService } from '@app/shared/services/firebase.service';
import * as moment from 'moment';
import { AngularFirestore, DocumentData, QuerySnapshot } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';
import { FIREBASE_API } from '../../environments/environment';
import { Logger } from '@app/core';
const log = new Logger('Import');

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  @ViewChild('inputXlsx', { read: ElementRef, static: true }) inputXlsx: HTMLInputElement;

  @Input() countProducts = 0;
  @Input() product: any = null;

  public currentUser: {
    name: string;
    email: string;
    id: string;
    role: string;
    provider_id: string;
  };

  public jsonData: any[] = [];
  public products: any[] = [];
  public incorrectMembers: any[] = [];
  public providers: any[] = [];
  public fileProducts: File;

  public members: any = [];
  public isLoading: boolean;
  private _loading: HTMLIonLoadingElement;
  public showResultsImport: boolean = false;
  public errorsImport: any = [];
  private specialties: any;
  public arrayEspecialidades: any = [];
  public arrayEspecialidadesNormal: any = [];
  public reformattedArray: any[] = [];
  public correctResults: any[] = [];

  constructor(
    private auth: AngularFireAuth,
    private http: HttpClient,
    private _toastCtrl: ToastController,
    private _loadingCtrl: LoadingController,
    private firbaseCtrl: FirebaseService,
    private wp: WpService,
    private afs: AngularFirestore
  ) { }

  async ngOnInit() {
    this.isLoading = true;
  }

  async getAuthUserByEmail(email: string): Promise<boolean> {
    try {
      const token = await this.firbaseCtrl.getToken();
      const response: any = await this.http
        .post(`${FIREBASE_API}existInFireAuth`, {
          token: token,
          email: email
        })
        .toPromise();
      return response.exist;
    } catch (error) {
      return false;
    }
  }

  async presentLoading() {
    this._loading = await this._loadingCtrl.create();
    return await this._loading.present();
  }

  async readXlsx(target: any) {
    const file: File = target.files[0];
    this.presentLoading().then(() => {
      this.fileProducts = file;
      if (file.name.includes('.xlsx') || file.name.includes('.xls')) {
        const reader = new FileReader();
        let workBook: any = null;
        let jsonData: any = null;
        reader.onload = event => {
          const data = event.target['result'];
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial: any, name: string) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          this.validateProducts(jsonData.SomeSheet);
        };

        reader.readAsBinaryString(file);
        this.presentToast('Archivo cargado con éxito');
      } else {
        this.presentToast('Archivo no soportado, solo se permiten archivos con extensión ".xlsx"');
        this.inputXlsx.value = '';
      }
    });
  }

  async validateProducts(products: any[]) {
    this.isLoading = true;

    if (products) {
      await this.initSpecialties();
      if (this.specialties) {
        this.arrayEspecialidades = await this.specialties.map((item: any) => {
          return this.replaceAcents(item['name']);
        });
        this.arrayEspecialidadesNormal = await this.specialties.map((item: any) => {
          return item['name'];
        });

        this.specialties.forEach(async (sp: any) => {
          if (!this.reformattedArray[this.replaceAcents(sp.name)]) {
            this.reformattedArray[this.replaceAcents(sp.name)] = sp.id;
          }
        });
      }

      products.forEach(async (product, i) => {
        i;
        let existe = -1;
        let existe2 = -1;
        let specialityNoacents = null;
        let specialityNoacents2 = null;
        if (product['Especialidad 1 *'] && product['Especialidad 1 *'].toString().length >= 3) {
          specialityNoacents = this.replaceAcents(product['Especialidad 1 *']);
          existe = this.arrayEspecialidades.indexOf(specialityNoacents);
        }
        if (product['Especialidad 2'] && product['Especialidad 2'].toString().length >= 3) {
          specialityNoacents2 = this.replaceAcents(product['Especialidad 2']);
          existe2 = this.arrayEspecialidades.indexOf(specialityNoacents2);
        }

        let newRow: any = {
          tipo: 'medico',
          email: product['Email *'] && this._ValidateEmail(product['Email *']) == true ? product['Email *'] : '',
          nombre: product['Nombre *'] && product['Nombre *'].toString().length >= 3 ? product['Nombre *'].trim() : '',
          apaterno:
            product['Apaterno *'] && product['Apaterno *'].toString().length >= 3 ? product['Apaterno *'].trim() : '',
          amaterno: product['Amaterno'] && product['Amaterno'].toString().length >= 3 ? product['Amaterno'].trim() : '',
          telmovil:
            product['Telefono movil *'] && product['Telefono movil *'].toString().length == 10
              ? String(product['Telefono movil *'])
              : '',
          telfijo:
            product['Telefono fijo'] && product['Telefono fijo'].toString().length == 10
              ? String(product['Telefono fijo'])
              : '',
          especialidad1: existe != -1 ? this.reformattedArray[specialityNoacents] : '',
          cedula1:
            product['Cedula profesional 1 *'] && product['Cedula profesional 1 *'].toString().length >= 3
              ? String(product['Cedula profesional 1 *'])
              : '',
          especialidad2: existe2 != -1 ? this.reformattedArray[specialityNoacents2] : '',
          cedula2:
            product['Cedula profesional 2'] && product['Cedula profesional 2'].toString().length >= 3
              ? String(product['Cedula profesional 2'])
              : '',
          calle1:
            product['Calle 1 *'] && product['Calle 1 *'].toString().length >= 3
              ? product['Calle 1 *'].trim().toLowerCase()
              : '',
          numeroint1: product['Numero interior 1'] ? product['Numero interior 1'] : '',
          numeroext1: product['Numero exterior 1 *'] ? product['Numero exterior 1 *'] : '',
          codipostal1:
            product['Codigo postal 1 *'] && product['Codigo postal 1 *'].toString().length == 5
              ? product['Codigo postal 1 *']
              : '',
          colonia1:
            product['Colonia 1 *'] && product['Colonia 1 *'].toString().length >= 3
              ? product['Colonia 1 *'].trim().toLowerCase()
              : '',
          delmun1:
            product['Delegacion municipio 1 *'] && product['Delegacion municipio 1 *'].toString().length >= 3
              ? product['Delegacion municipio 1 *']
              : '',
          hospital1:
            product['Hospital 1'] && product['Hospital 1'] && product['Hospital 1'].toString().length >= 3
              ? product['Hospital 1'].toLowerCase()
              : '',
          torre1: product['Torre 1'] && product['Torre 1'].toString().length >= 1 ? product['Torre 1'] : '',
          calle2:
            product['Calle 2 *'] && product['Calle 2 *'].toString().length >= 3
              ? product['Calle 2 *'].trim().toLowerCase()
              : '',
          numeroint2: product['Numero interior 2'] ? product['Numero interior 2'] : '',
          numeroext2: product['Numero exterior 2 *'] ? product['Numero exterior 2 *'] : '',
          codipostal2:
            product['Codigo postal 2'] && product['Codigo postal 2'].toString().length == 5
              ? product['Codigo postal 2']
              : '',
          colonia2:
            product['Colonia 2'] && product['Colonia 2'].toString().length >= 3
              ? product['Colonia 2'].trim().toLowerCase()
              : '',
          estado2:
            product['Estado 2 *'] && product['Estado 2 *'].toString().length >= 3
              ? product['Estado 2 *'].trim().toLowerCase()
              : '',
          ciudad2:
            product['Ciudad 2 *'] && product['Ciudad 2 *'].toString().length >= 3
              ? product['Ciudad 2 *'].trim().toLowerCase()
              : '',
          delmun2:
            product['Delegacion municipio 2 *'] && product['Delegacion municipio 2 *'].toString().length >= 3
              ? product['Delegacion municipio 2 *'].toLowerCase()
              : '',
          hospital2:
            product['Hospital 2'] && product['Hospital 2'].toString().length >= 3
              ? product['Hospital 2'].toLowerCase()
              : '',
          torre2: product['Torre 2 *'] && product['Torre 2 *'].toString().length >= 1 ? product['Torre 2 *'] : '',
          inWP: false,
          created: moment().toDate()
        };

        let place1: any;
        if (newRow && newRow.codipostal1 && newRow.codipostal1 !== '') {
          try {
            place1 = await this.firbaseCtrl.getZipcode(newRow.codipostal1);
          } catch (error) {
            log.error(error);
          }
        }
        let place2: any;
        if (newRow && newRow.codipostal2 && newRow.codipostal2 !== '') {
          try {
            place2 = await this.firbaseCtrl.getZipcode(newRow.codipostal2);
          } catch (error) {
            log.error(error);
          }
        }
        newRow.estado1 = place1 && place1.state ? String(place1.state) : '';
        newRow.ciudad1 = place1 && place1.city ? String(place1.city) : '';
        newRow.estado2 = place2 && place2.state ? String(place2.state) : '';
        newRow.ciudad2 = place2 && place2.city ? String(place2.city) : '';
        if (newRow.email == '') {
          this.errorsImport.push('El email es inválido');
        } else if (newRow.nombre == '') {
          this.errorsImport.push('El nombre debe ser de al menos 3 caracteres de longitud');
        } else if (newRow.apaterno == '') {
          this.errorsImport.push('El apellido paterno debe ser de al menos 3 caracteres de longitud');
        } else if (newRow.telmovil == '') {
          this.errorsImport.push('El teléfono móvil no es numérico o es menor de 10 dígitos');
        } else if (newRow.especialidad1 == '') {
          this.errorsImport.push('La especialidad no existe en la Base de información interna');
        } else if (newRow.cedu1a1 == '') {
          this.errorsImport.push('La cédula es incorrecta');
        } else if (newRow.calle1 == '') {
          this.errorsImport.push('La calle debe ser de al menos 3 caracteres');
        } else if (newRow.codpostal1 == '') {
          this.errorsImport.push('El código postal debe ser de 5 dígitos y sólo números');
        } else if (newRow.colonia1 == '') {
          this.errorsImport.push('La colonia debe ser de al menos 3 caracteres');
        } else if (newRow.delmun1 == '') {
          this.errorsImport.push('La delegación/municipio debe ser de al menos 3 caracteres');
        }

        if (
          newRow.email != '' &&
          newRow.nombre != '' &&
          newRow.apaterno != '' &&
          newRow.telmovil != '' &&
          newRow.especialidad1 != '' &&
          newRow.cedu1a1 != '' &&
          newRow.calle1 != '' &&
          newRow.codpostal1 != '' &&
          newRow.colonia1 != '' &&
          newRow.delmun1 != ''
        ) {
          this.members.push(newRow);
        } else {
          this.incorrectMembers.push(newRow);
        }
      });
      this._loading.dismiss();
      this.isLoading = false;
    } else {
      this.presentToast('Archivo no coincide con el layout');
      this._loading.dismiss();
      this.inputXlsx.value = '';
    }
  }

  _ValidateEmail(mail: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  async presentToast(message: string) {
    const toast = await this._toastCtrl.create({
      message,
      duration: 5000,
      buttons: [{ text: 'Aceptar' }]
    });
    toast.present();
  }

  async saveFile() {
    this.isLoading = true;
    const loadingOverlay = await this._loadingCtrl.create({});
    loadingOverlay.present();
    try {
      await this.insertRows();
    } catch (error) {
      log.error(error);
    }
    this.showResultsImport = true;
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async insertRows() {
    let results: any[] = [];
    for (let item of this.members) {
      try {
        let resp1: QuerySnapshot<DocumentData>;
        try {
          resp1 = await this.afs
            .collection('preregistro')
            .ref.where('email', '==', item.email)
            .get();
        } catch (error) {
          log.error();
        }
        let resp2: QuerySnapshot<DocumentData>;
        try {
          resp2 = await this.afs
            .collection('users')
            .ref.where('email', '==', item.email)
            .get();
        } catch (error) {
          log.error();
        }
        let resp3: boolean;
        try {
          resp3 = await this.getAuthUserByEmail(item.email);
        } catch (error) {
          log.error();
        }
        if (resp1.empty === false) {
          results.push({
            success: false,
            message: "¡Email ya registrado en tabla 'preregistro'!",
            code: 'preregistro-duplicado'
          });
          this.errorsImport.push('¡Email ya registrado!');
          this.incorrectMembers.push(item);
        } else if (resp2.empty === false) {
          results.push({ success: false, message: "¡Email ya registrado en tabla 'users'!", code: 'users-duplicado' });
          this.errorsImport.push('¡Email ya registrado!');
          this.incorrectMembers.push(item);
        } else if (resp3 === true) {
          results.push({
            success: false,
            message: '¡Email ya registrado en firebase authentication!',
            code: 'auth-duplicado'
          });
          this.errorsImport.push('¡Email ya registrado!');
          this.incorrectMembers.push(item);
        } else {
          try {
            await this.afs.collection('preregistro').add(item);
            results.push({ success: true, message: '¡Correcto!' });
            this.correctResults.push({ success: true, message: '¡Correcto!' });
          } catch (error) {
            results.push({ success: false, message: '¡Error desconocido!', code: 'unknown-error' });
            this.errorsImport.push('¡Error desconocido!');
            this.incorrectMembers.push(item);
          }
        }
      } catch (error) {
        log.error(error);
      }
    }
  }

  async initSpecialties(): Promise<void> {
    try {
      const specialties = await this.wp.content('speciality', 100, 1, 'name', 'asc');
      this.specialties = specialties.body;
    } catch (error) {
      log.error(error);
    }
  }

  replaceAcents(s: any) {
    var r = s.toLowerCase();
    r = r.replace(new RegExp('\\s', 'g'), '');
    r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
    r = r.replace(new RegExp('æ', 'g'), 'ae');
    r = r.replace(new RegExp('ç', 'g'), 'c');
    r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
    r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
    r = r.replace(new RegExp('ñ', 'g'), 'n');
    r = r.replace(new RegExp('[òóôõö]', 'g'), 'o');
    r = r.replace(new RegExp('œ', 'g'), 'oe');
    r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
    r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
    r = r.replace(new RegExp('\\W', 'g'), '');
    return r;
  }

  reset() {
    location.reload();
  }
}
