import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { DisableMedicalSampleRoutingModule } from './disable-medical-sample-routing.module';
import { DisableMedicalSampleComponent } from './disable-medical-sample.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DisableMedicalSampleRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [DisableMedicalSampleComponent]
})
export class DisableMedicalSampleModule {}
