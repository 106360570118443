<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Importación de usuarios</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content no-bounce>
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-no-padding ion-justify-content-center">
      <ion-col size-sm="12">
        <ion-item>
          <ion-label color="medium">Selecciona un archivo</ion-label>
          <ion-input #inputXlsx type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            (change)="readXlsx($event.target)"></ion-input>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/conectimed-9d22c.appspot.com/o/import_members.xlsx?alt=media&token=310e3f0f-544f-41af-a526-95d6be925e71​​​​​​​">
            Descargar plantilla para la importación</a>
        </ion-item>
        <ion-toolbar class="ion-padding-horizontal">
          <ion-button expand="full" color="secondary" [disabled]="isLoading" (click)="saveFile()">
            Importar archivo
          </ion-button>
          <ion-col size="12">
            <div *ngIf="showResultsImport == true">
              <h3>Resumen</h3>
              <ion-button (click)="reset()">
                <ion-icon slot="start" name="refresh"></ion-icon>
                Reiniciar
              </ion-button>
              <p>Registros creados: {{ correctResults.length }}</p>
              <p>Registros no creados: {{ incorrectMembers.length }}</p>
              <div *ngIf="incorrectMembers.length > 0">
                <p><b>Detalles:</b></p>
                <p *ngFor="let member of incorrectMembers; let x = index">
                  <strong>Nombre:</strong> {{ member.nombre }} / <strong>Email:</strong> {{ member.email }} /
                  <strong>Error:</strong>
                  <span [class]="errorsImport[x] === '¡Email ya registrado!' ? 'warning' : 'danger'">
                    {{ errorsImport[x] }}</span>
                </p>
              </div>
            </div>
          </ion-col>
        </ion-toolbar>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>