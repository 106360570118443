import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesRoutingModule } from './companies-routing.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { CompaniesComponent } from './companies.component';
import { CompanyDetailModalComponent } from './company-detail-modal/company-detail-modal.component';

@NgModule({
  declarations: [CompaniesComponent, CompanyDetailModalComponent],
  imports: [CommonModule, CompaniesRoutingModule, IonicModule, FormsModule, ReactiveFormsModule]
})
export class CompaniesModule {}
