import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PullToRefreshComponent } from './pull-to-refresh.component';
import { RefreshWebComponent } from './refresh-web/refresh-web.component';

@NgModule({
  declarations: [PullToRefreshComponent, RefreshWebComponent],
  exports: [PullToRefreshComponent, RefreshWebComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, IonicModule, RouterModule],
 // entryComponents: [PullToRefreshComponent, RefreshWebComponent]
})
export class PullToRefreshModule {}
