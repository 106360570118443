import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Logger } from '../core/logger.service';
import { lastValueFrom } from 'rxjs';
const log = new Logger('PushNotificationsService');

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  constructor(private func: AngularFireFunctions) { }

  public async sendPushNotification(settings: {
    title: string;
    body: string;
    link: string;
    refLink: string;
    token?: string;
    topic?: string;
    condition?: string;
    analytics_label?: string;
    image_url?: string;
  }): Promise<any> {
    let link_web: string;
    if (settings.analytics_label) {
      link_web = `${settings.refLink}?analytics_label=${settings.analytics_label}`;
    } else {
      link_web = settings.refLink;
    }
    let pushNotification = {
      message: {
        token: settings.token,
        topic: settings.topic,
        condition: settings.condition,
        notification: {
          title: settings.title,
          body: settings.body,
          image: settings.image_url
        },
        android: {
          notification: {
            title: settings.title,
            body: settings.body
          },
          data: {
            type: 'segmented-notification',
            data: settings.link
          },
          fcm_options: {
            analytics_label: settings.analytics_label
          }
        },
        webpush: {
          notification: {
            title: settings.title,
            body: settings.body,
            click_action: link_web
          },
          fcm_options: {
            link: link_web,
            analytics_label: settings.analytics_label
          }
        },
        apns: {
          fcm_options: {
            image: settings.image_url,
            analytics_label: settings.analytics_label
          }
        },
        fcm_options: {
          analytics_label: settings.analytics_label
        },
        data: {
          type: 'segmented-notification',
          data: settings.link
        }
      }
    };
    pushNotification = this.removeEmpty(pushNotification);
    log.debug('========== pushNotification ==========\n', JSON.stringify(pushNotification));
    const sendNotificationCall = this.func.httpsCallable('sendNotificationCall')(pushNotification);
    return await lastValueFrom(sendNotificationCall);
  }

  removeEmpty(obj: any) {
    Object.keys(obj).forEach(key => {
      (obj[key] && typeof obj[key] === 'object' && this.removeEmpty(obj[key])) ||
        ((obj[key] === '' || obj[key] === null || obj[key] === undefined) && delete obj[key]);
    });
    return obj;
  }
}
