<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Activar representantes médicos</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col>
        <ion-button (click)="clearSearch()" expand="block" fill="clear">
          Refrescar
          <ion-icon slot="end" name="refresh"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-searchbar
          class="ion-margin-top"
          type="text"
          debounce="1000"
          (ionChange)="doSearch($event)"
          (ionCancel)="clearSearch()"
          (ionClear)="clearSearch()"
          enterkeyhint="send"
          placeholder="Buscar"
          animated
          [disabled]="isLoading"
        >
        </ion-searchbar>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <div *ngIf="arrayDocs && arrayDocs[0]; else notFound">
          <ion-grid fixed>
            <ion-row>
              <ion-col>
                <ion-list>
                  <ion-item *ngFor="let item of arrayDocs; let i = index" lines="none">
                    <ion-avatar slot="start">
                      <img
                        [src]="
                          item?.avatar?.list?.url ? item.avatar.list.url : 'assets/img/default-' + item.type + '.jpg'
                        "
                      />
                    </ion-avatar>
                    <ion-label slot="start">
                      <app-select-user-card [item]="item" [showInfo]="true"> </app-select-user-card>
                    </ion-label>
                    <ion-buttons slot="end">
                      <ion-button (click)="activateRepAlert(item?.id)">
                        <ion-icon slot="icon-only" name="checkbox"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                  </ion-item>
                </ion-list>
                <ion-button
                  *ngIf="forward === true && isLoading === false"
                  (click)="nextPage()"
                  expand="block"
                  fill="clear"
                  shape="round"
                >
                  Cargar mas resultados
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <ng-template #notFound>
          <p class="ion-text-center ion-padding ion-margin">
            <strong>
              No se econtraron resultados.
            </strong>
          </p>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
