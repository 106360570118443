<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Registrar código postal</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item *ngFor="let item of mainArray">
            <ion-label>
              <h2>{{ item?.id }}</h2>
              <p>{{ item?.state }}</p>
              <p>
                <strong>{{ item?.city }}</strong>
              </p>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="zipcodes(item?.id)">
                <ion-icon slot="icon-only" name="arrow-forward"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
