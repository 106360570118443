import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@app/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
moment.locale('es');

var meses = 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_');
var semanas = 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_');
moment.updateLocale('es', { months: meses, weekdays: semanas });

const log = new Logger('CertificatesComponent');

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {
  item: any;
  item2: any;
  public file: File;
  public isLoading: boolean = false;
  myForm!: FormGroup;

  constructor(
    private storage: AngularFireStorage,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private afs: AngularFirestore,
    private alertC: AlertController,
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  private createForm() {
    this.myForm = this.formBuilder.group({
      eventName: ['', Validators.required],
      eventNameContinuation: [''],
      eventDate: ['', Validators.required],
      eventDuration: [90, Validators.required],
      eventPlatform: ['CONECTIMED', Validators.required],
      eventCity: ['México', Validators.required],
      dateOfSendingCertificates: ['', Validators.required]
    });
  }

  refillForm(data: any) {
    this.myForm.patchValue({
      eventName: data && data.eventName ? data.eventName : '',
      eventDate: data && data.eventDate ? data.eventDate : '',
      dateOfSendingCertificates: moment().toISOString(true)
    });
  }

  ngOnInit() {
    let data: any = {
      eventName: this.item && this.item.conference && this.item.conference.name ? this.item.conference.name : '',
      eventDate: this.item2 && this.item2.end_date ? moment(this.item2.end_date).toISOString(true) : ''
    };
    this.refillForm(data);
  }

  async downloadFormat() {
    try {
      const downloadURL = await this.storage
        .ref('Constancias/formats/formato-constancias.xlsx')
        .getDownloadURL()
        .toPromise();
      await this.openInNewTab(downloadURL);
    } catch (error) {
      log.error(error);
    }
  }

  private openInNewTab(href: string) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: href
    }).click();
  }

  selectFile(event: any) {
    this.isLoading = true;
    try {
      this.file = event.target.files[0];
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async onSubmit() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      if (this.file) {
        const ref = this.storage.ref(`certificates-batch/${moment().valueOf()}-${this.file.name}`);
        const updload = await ref.put(this.file);
        const fullPath = updload.ref.fullPath;
        let data: any = JSON.parse(JSON.stringify(this.myForm.value));
        data.eventDate = moment(data.eventDate)
          .format('LL')
          .slice(0, -8);
        data.dateOfSendingCertificates = moment(data.dateOfSendingCertificates).format('LL');
        data.eventDuration = String(data.eventDuration);
        const documentData: any = {
          date: moment().toDate(),
          fullPath,
          wuid: this.item && this.item.conference && this.item.conference.id ? String(this.item.conference.id) : '',
          suid: this.item2 && this.item2.id ? String(this.item2.id) : '',
          status: 'pending',
          ...data
        };
        await this.afs.collection('certificates-batch').add(documentData);
        this.successAlert();
        this.closeModal();
      }
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async successAlert(): Promise<void> {
    const alert = await this.alertC.create({
      header: '¡Correcto!',
      message: `Se a iniciado el proceso de envío de chats.`,
      buttons: [
        {
          text: 'Ok'
        }
      ]
    });
    return await alert.present();
  }
}
