<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center">
      Agregar empresa
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <form [formGroup]="dataForm" (ngSubmit)="submit()">
          <ion-list>
            <ion-list-header>
              <ion-label color="secondary">Empresa</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label position="stacked">Nombre de la empresa <ion-text color="danger">*</ion-text> </ion-label>
              <ion-input type="text" formControlName="name" autofocus="true"></ion-input>
            </ion-item>
            <ion-text
              class="item-with-error"
              color="danger"
              *ngIf="dataForm.get('name').touched && dataForm.get('name').getError('required')"
            >
              <span class="ion-padding" >El nombre de la empresa es requerida</span>
            </ion-text>
            <ion-item>
              <ion-label position="stacked">Página web de la empresa</ion-label>
              <ion-input type="text" formControlName="companyWebsite"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Giro de la empresa</ion-label>
              <ion-input type="text" formControlName="businessType"></ion-input>
            </ion-item>
          </ion-list>
          <ion-text color="danger">
            <p>* Datos obligatorios</p>
          </ion-text>
          <div class="ion-padding">
            <ion-button type="submit" color="tertiary" expand="block" [disabled]="!dataForm.valid || isLoading">
              Guardar
            </ion-button>
          </div>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
