<ion-app>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title class="ion-text-center">Editar Publicación</ion-title>

      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-grid fixed>
      <!-- Write Segment -->
      <ion-row>
        <ion-col>
          <ion-card color="light">
            <ion-card-header>
              <ion-card-subtitle>Escribe una opinión</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <!-- Titulo -->
              <h2>Titulo</h2>
              <div class="separador-h"></div>
              <ion-item style="margin-bottom: 8px;">
                <ion-textarea
                  placeholder="Título con un máximo de 150 caracteres"
                  [(ngModel)]="title"
                  [maxlength]="150"
                ></ion-textarea>
              </ion-item>

              <!-- Content Post -->
              <h2>Contenido</h2>
              <div class="separador-h"></div>
              <ion-item style="margin-bottom: 8px;">
                <ion-textarea rows="15" placeholder="Contenido" [(ngModel)]="content"></ion-textarea>
              </ion-item>

              <!-- Keywords Post -->
              <h2>Palabras Clave</h2>
              <div class="separador-h"></div>
              <ion-label class="ion-text-wrap">
                Añadir palabras clave permitirá que tu post sea más fácil de encontrar
              </ion-label>
              <ion-item>
                <ion-input type="text" [(ngModel)]="keyWord" placeholder="Palabra clave" (keyup.enter)="addKeyWord()">
                </ion-input>
                <ion-button
                  (click)="addKeyWord()"
                  fill="solid"
                  shape="round"
                  slot="end"
                  style="margin-top: auto; margin-bottom: auto;"
                >
                  Añadir
                </ion-button>
              </ion-item>
              <ion-chip
                *ngFor="let item of arrayKeyWords"
                color="primary"
                mode="ios"
                outline="true"
                class="animate__animated animate__fadeIn"
              >
                <ion-label>{{ item }}</ion-label>
                <ion-icon name="close-circle" (click)="deleteKeyWord(item)"></ion-icon>
              </ion-chip>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>

      <!-- Image Segment -->
      <ion-row>
        <ion-col>
          <!-- Input files hided -->
          <input
            #inputMedia
            type="file"
            accept="video/*, image/*"
            class="ion-hide"
            (change)="newMedia(parserany(inputMedia)?.files)"
          />
          <ion-card color="light">
            <ion-toolbar>
              <h5 style="padding-left: 8px;" mode="md" class="ion-text-wrap">Imágenes</h5>
              <ion-button
                (click)="actionSheetImages(image?.id)"
                shape="round"
                color="primary"
                slot="end"
                size="small"
                style="margin-right: 8px;"
                [disabled]="isLoading === true"
              >
                <ion-icon slot="end" name="add"></ion-icon>
                Agregar
              </ion-button>
            </ion-toolbar>
            <ion-card-content>
              <!-- Allready uploaded media files List -->
              <div *ngIf="files.length !== 0">
                <h2>Lista de archivos existentes</h2>
                <div class="separador-h"></div>
                <ion-row class="ion-justify-content-center">
                  <ion-col
                    size="auto"
                    *ngFor="let file of files; let i = index"
                    class="animate__fadeIn animate__animated"
                  >
                    <ion-card style="margin: 0px; max-width: 160px;" class="ion-text-center">
                      <!-- <h6 class="ion-text-center" style="margin-bottom: 5px; margin-top: 5px;">
                        {{ file.type.slice(0, 5) }}
                      </h6> -->
                      <!-- <ion-label style="font-size: 13px; width: 140px !important;" class="ion-text-wrap">{{
                        uploadArchives[i].titleFile
                      }}</ion-label> -->
                      <img [id]="'media_' + i" [src]="file.file" class="img-media" />
                      <!-- <video
                        [id]="'media_' + i"
                        [src]="file.file"
                        class="img-media"
                        *ngIf="file.type.slice(0, 5) === 'video'"
                      ></video> -->

                      <ion-button (click)="deleteFile(file)" expand="block" fill="clear" color="danger" size="small">
                        <ion-icon slot="icon-only" name="trash"></ion-icon>
                      </ion-button>
                    </ion-card>
                  </ion-col>
                </ion-row>
                <br />
              </div>

              <!-- media files to upload List -->
              <div *ngIf="preImages.length !== 0">
                <h2>Lista de archivos por agregar</h2>
                <div class="separador-h"></div>
                <ion-row class="ion-justify-content-center">
                  <ion-col
                    size="auto"
                    *ngFor="let item of preImages; let i = index"
                    class="animate__fadeIn animate__animated"
                  >
                    <ion-card style="margin: 0px;">
                      <img [src]="item?.image" class="img-media" />
                      <ion-button (click)="quitMedia(files)" expand="block" fill="clear" color="primary" size="small">
                        <ion-icon slot="icon-only" name="close"></ion-icon>
                      </ion-button>
                    </ion-card>
                  </ion-col>
                </ion-row>
                <br />
              </div>

              <!-- Max Space indicator -->
              <!-- <ion-progress-bar
                type="determinate"
                [value]="filesCheck.porcent"
                [color]="filesCheck.color"
              ></ion-progress-bar>
              <h2 class="ion-text-center">-- {{ filesCheck.size | number: '1.0-1' }}MB / 50MB --</h2> -->
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>

      <!-- Link Segment -->
      <ion-row>
        <ion-col>
          <ion-card color="light">
            <ion-card-header>
              <ion-card-subtitle>Agrega un link</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ion-row class="ion-align-items-center">
                <ion-col sizeXs="12" sizeMd="">
                  <h2>URL</h2>
                  <ion-item>
                    <ion-input placeholder="URL" [(ngModel)]="url" (ionChange)="checkUrl()" debounce="500"></ion-input>
                  </ion-item>
                  <ion-item
                    class="item-alert animate__animated animate__fadeIn"
                    *ngIf="!urlCheck.ok && urlCheck.urlMessage !== ''"
                    color="danger"
                  >
                    <span>{{ urlCheck.urlMessage }}</span>
                  </ion-item>
                  <br />

                  <h2>Texto de la liga</h2>
                  <ion-item>
                    <ion-input
                      placeholder="Texto de la liga"
                      [(ngModel)]="descriptionUrl"
                      (ionChange)="checkUrl()"
                      debounce="500"
                    ></ion-input>
                  </ion-item>
                  <ion-item
                    class="item-alert animate__animated animate__fadeIn"
                    *ngIf="!urlCheck.ok && urlCheck.descMessage !== ''"
                    color="danger"
                  >
                    <span>{{ urlCheck.descMessage }}</span>
                  </ion-item>
                  <br />
                </ion-col>
                <ion-col sizeXs="12" sizeMd="auto" class="ion-text-center">
                  <h2>Vista Previa</h2>
                  <ion-chip mode="md" style="background-color: white;" outline="true" color="tertiary" [title]="url">
                    <ion-icon name="link"></ion-icon>
                    <ion-label>Liga a {{ descriptionUrl || 'texto de la liga' }}</ion-label>
                  </ion-chip>
                </ion-col>
              </ion-row>
              <br />
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
    <input
      id="imageInput"
      class="hide"
      #imageInput
      name="imageInput"
      accept="image/*"
      type="file"
      name="file"
      (change)="webImage(parserany($event.target)?.files)"
    />
  </ion-content>

  <ion-footer>
    <ion-row>
      <ion-col size="6">
        <ion-button (click)="cancel()" expand="block" fill="clear" shape="round">
          Cancelar
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button (click)="checkKeyWords()" expand="block" fill="solid" shape="round" [disabled]="title == ''">
          Actualizar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-footer>
</ion-app>
