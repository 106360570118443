import { Component, OnInit } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';
//import { ScheduleEventComponent } from '@app/shared/calendarEvent/schedule-event/schedule-event.component';

@Component({
  selector: 'app-schedule-evt',
  templateUrl: './schedule-evt.component.html',
  styleUrls: ['./schedule-evt.component.scss']
})
export class ScheduleEvtComponent implements OnInit {
  idUser: any;
  phone: string;
  type: string;

  constructor(private popoverController: PopoverController, private modalCtrl: ModalController) {}

  ngOnInit() {}

  async close() {
    await this.popoverController.dismiss();
  }

  async add(type: number) {
    type;
    // await this.close();
    // const modal = await this.modalCtrl.create({
    //   component: ScheduleEventComponent,
    //   componentProps: {
    //     userId: this.idUser,
    //     typeEvent: type
    //   }
    // });
    // return await modal.present();
  }
}
