import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from './search.service';
import { IonInfiniteScroll, LoadingController } from '@ionic/angular';
import { CredentialsService } from '@app/core';
import { SpecialitesComponent } from './specialites/specialites.component';
import { LocationComponent } from './location/location.component';
import { SearchByCompanyComponent } from '../shared/search-by-company/search-by-company.component';
import { ModalController } from '@ionic/angular';
import { WpService } from '@app/shared/services/wp.service';
import { UserDetailViewComponent } from '@app/shared/user-detail-view/user-detail-view.component';
import { Logger } from '@app/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilitiesService } from '@app/shared/services/utilities.service';
const log = new Logger('Assign contacts');

@Component({
  selector: 'app-assign-contacts',
  templateUrl: './assign-contacts.component.html',
  styleUrls: ['./assign-contacts.component.scss']
})
export class AssignContactsComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, { read: {}, static: true }) infiniteScroll: IonInfiniteScroll;

  public posts: any[] = [];
  public memberType: string;
  private search: string;
  private search2: string;
  private perPage: number = 40;
  public members: any[];
  public members2: any[];
  public searchByLocation: boolean;
  public searchBySpecialty: boolean;
  //filtros
  filterData1: any[] = [];
  filterData2: any[] = [];
  filterData3: any[] = [];
  searchMemberType: string;
  newMembers: boolean = false;
  myMembers: number[];
  isLoading: boolean;
  private currentPage: number;
  private totalPages: number;
  private currentPage2: number;
  private totalPages2: number;
  representantes: number[] = [];
  medicos: number[] = [];
  step: number = 1;
  allocationType: string = 'specialty';
  myForm!: FormGroup;
  connCreated: number = 0;
  connNoCreated: number = 0;
  notAcceptedUsers: any[] = [];

  constructor(
    private credential: CredentialsService,
    private modalController: ModalController,
    private searchService: SearchService,
    private wpService: WpService,
    private loadingController: LoadingController,
    private formBuilder: FormBuilder,
    private utilities: UtilitiesService
  ) {
    this.memberType = 'medico';
    this.searchMemberType = this.memberType === 'medico' ? 'representante-medico' : 'medico';
    this.isLoading = true;
    this.initMembers().finally(() => {
      this.isLoading = false;
    });
    this.createForm();
  }

  private createForm() {
    this.myForm = this.formBuilder.group({
      ids: ['', Validators.required]
    });
  }

  public async loadData(event: any): Promise<void> {
    if (this.currentPage < this.totalPages) {
      try {
        await this.initMembers(this.currentPage + 1);
      } catch (error) {
        log.error(error);
      }
    }
    try {
      event.target.complete();
    } catch (error) {
      log.error(error);
    }
  }

  public async loadData2(event: any): Promise<void> {
    if (this.currentPage2 < this.totalPages2) {
      try {
        await this.initMembers2(this.currentPage2 + 1);
      } catch (error) {
        log.error(error);
      }
    }
    try {
      event.target.complete();
    } catch (error) {
      log.error(error);
    }
  }

  async userDetail(id: number): Promise<void> {
    const modal = await this.modalController.create({
      component: UserDetailViewComponent,
      componentProps: { userid: id }
    });
    return await modal.present();
  }

  ngOnInit() {}

  async filter1() {
    this.search2 = '';
    const modal = await this.modalController.create({
      component: SpecialitesComponent,
      componentProps: { arrayData: this.filterData2 }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      this.filterData2 = data.data.arrayData;
      this.medicos = [];
      await this.initMembers2();
      this.isLoading = false;
    });
    return await modal.present();
  }

  async filter2() {
    this.search2 = '';
    const modal = await this.modalController.create({
      component: LocationComponent,
      componentProps: { arrayData: this.filterData1 }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      this.filterData1 = data.data.arrayData;
      this.medicos = [];
      await this.initMembers2();
      this.isLoading = false;
    });
    return await modal.present();
  }

  async filter3() {
    const modal = await this.modalController.create({
      component: SearchByCompanyComponent,
      componentProps: { arrayData: this.filterData3 }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      this.filterData3 = data.data.arrayData;
      this.representantes = [];
      await this.initMembers();
      this.isLoading = false;
    });
    return await modal.present();
  }

  public async removeFilter1(index: number): Promise<void> {
    this.filterData1.splice(index, 1);
    this.isLoading = true;
    await this.initMembers2();
    this.isLoading = false;
  }

  public async removeFilter2(index: number): Promise<void> {
    this.filterData2.splice(index, 1);
    this.isLoading = true;
    await this.initMembers2();
    this.isLoading = false;
  }

  public async removeFilter3(index: number): Promise<void> {
    this.filterData3.splice(index, 1);
    this.isLoading = true;
    await this.initMembers();
    this.isLoading = false;
  }

  private async initMembers(page?: number): Promise<void> {
    this.isLoading = true;
    try {
      const response = await this.searchService.getMembersV2(
        this.searchMemberType,
        this.perPage,
        page,
        this.search,
        this.filterData1[0] && this.filterData1[0].id ? this.filterData1[0].id : null,
        this.filterData2[0] && this.filterData2[0].id ? this.filterData2[0].id : null,
        this.filterData3[0] && this.filterData3[0].id ? this.filterData3[0].id : null
      );
      this.currentPage = page ? page : 1;
      this.totalPages = Number(response.totalPages);
      let temp: any[] = Array.from(response.results).map(element => {
        return { color: '', ...element };
      });
      if (page && page > 1) {
        this.members = this.members.concat(temp);
      } else {
        this.members = temp;
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  private async initMembers2(page?: number): Promise<void> {
    this.isLoading = true;
    try {
      const response = await this.searchService.getMembersV2(
        'medico',
        this.perPage,
        page,
        this.search2,
        this.filterData1[0] && this.filterData1[0].id ? this.filterData1[0].id : null,
        this.filterData2[0] && this.filterData2[0].id ? this.filterData2[0].id : null
      );
      this.currentPage2 = page ? page : 1;
      this.totalPages2 = Number(response.totalPages);
      let temp: any[] = Array.from(response.results).map(element => {
        return { color: '', ...element };
      });
      if (page && page > 1) {
        this.members2 = this.members2.concat(temp);
      } else {
        this.members2 = temp;
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  private async getNewMembers(): Promise<void> {
    const response = await this.searchService.getNewMembers('', this.myMembers);
    this.members = response.posts;
  }

  private async getmyMembers(): Promise<void> {
    const response = await this.wpService.members(100, 1, '', 'alphabetical', String(this.credential.credentials.uid));
    this.myMembers = Array.from(response.body).map((e: any) => e.id);
  }

  public async newest() {
    this.isLoading = true;
    this.newMembers = !this.newMembers;
    this.members = [];
    if (this.newMembers == true) {
      await this.getmyMembers();
      await this.getNewMembers();
    } else {
      await this.initMembers();
    }
    this.isLoading = false;
  }

  public async getStringSearch(ev: any): Promise<void> {
    const val = ev.target.value;
    this.search = String(val);
    this.isLoading = true;
    if (this.search.length >= 3 || this.search.length == 0) {
      await this.initMembers();
    }
    this.isLoading = false;
  }

  public async getStringSearch2(ev: any): Promise<void> {
    const val = ev.target.value;
    this.search2 = String(val);
    this.isLoading = true;
    if (this.search2.length >= 3 || this.search2.length == 0) {
      await this.initMembers2();
    }
    this.isLoading = false;
  }

  public async clearSearch(): Promise<void> {
    this.search = '';
    this.isLoading = true;
    await this.initMembers();
    this.isLoading = false;
  }

  public async clearSearch2(): Promise<void> {
    this.search2 = '';
    this.isLoading = true;
    await this.initMembers2();
    this.isLoading = false;
  }

  selectUser(id: number) {
    const index: number = this.representantes.indexOf(id);
    if (index > -1) {
      this.representantes.splice(index, 1);
    } else {
      this.representantes.push(id);
    }
  }

  selectUser2(id: number) {
    const index: number = this.medicos.indexOf(id);
    if (index > -1) {
      this.medicos.splice(index, 1);
    } else {
      this.medicos.push(id);
    }
  }

  public step1() {
    this.step = 2;
  }

  public step2() {
    this.step = 3;
    if (!(this.allocationType === 'email')) {
      this.initMembers2();
    }
  }

  public step3() {
    this.step = 4;
  }

  private stringArray(): string[] {
    var str = String(this.myForm.get('ids').value);
    var res = str.replace(/\n/gi, ',');
    res = res.replace(';', ',');
    res = res.replace(/ /g, ',');
    let idsString: string[] = res.split(',');
    let idsRes: string[];
    idsRes = idsString.map((item: string) => {
      return String(item);
    });
    idsRes = idsRes.filter(item => {
      const re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/i.test(item);
      if (typeof item === 'string' && re == true) {
        return item;
      }
      return undefined;
    });
    idsRes = idsRes.filter((valor, index, arreglo) => arreglo.indexOf(valor) === index);
    return idsRes;
  }

  public async onSubmit() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      let promises1: Promise<any>[] = [];
      this.stringArray().forEach(item => {
        promises1.push(
          this.wpService
            .members(1, 1, 'medico', '', '', item)
            .then(response => {
              return response.body && response.body[0] ? response.body[0]['id'] : undefined;
            })
            .catch(error => {
              log.error(error);
              return undefined;
            })
        );
      });
      await Promise.all(promises1).then(res => {
        var ids = res.filter(el => {
          return el != null;
        });
        this.medicos = ids;
        this.step3();
      });
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initConn() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    var limit = 0;
    var bucle = 1;

    try {
      let promises1: Promise<any>[] = [];
      let promises2: Promise<any>[] = [];

      this.representantes.forEach(item1 => {
        this.medicos.forEach(item2 => {
          promises1.push(
            this.sendRequest(item1, item2).then(element => {
              return { uid: item1, ...element };
            })
          );
        });
      });
      const connections = await Promise.all(promises1);
      limit = connections.length;
      connections.forEach(async element => {
        if (element.success == true) {
          this.connCreated++;
          promises2.push(this.wpService.acceptFriendships(element.uid, element.id));
        } else {
          this.connNoCreated++;
          await this.getUsers(element.uid);
        }
        bucle++;
        if (bucle == limit) {
        }
      });
      const accepted = await Promise.all(promises2);
      this.step = 5;
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
    await loadingOverlay.dismiss();
  }

  async getUsers(uid: number) {
    const dataUser = await this.wpService.member(uid);
    this.notAcceptedUsers.push(this.utilities.mapMemberData(dataUser.body));
  }

  async sendRequest(init_user: number, userid: number) {
    const response = await this.wpService.sendFriendShip(init_user, userid);
    if (response.success == true) {
      const id = String(String(response.response).substr(String(response.response).lastIndexOf(' ') + 1)).toLowerCase();
      return { success: true, id: id };
    }
    return response;
  }
}
