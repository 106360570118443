<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Cargar puntos</span></ion-title>
  </ion-toolbar>
</ion-header>
<ion-content no-bounce>
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-no-padding ion-justify-content-center">
      <ion-col size-sm="12">
        <form (ngSubmit)="onSubmit()" [formGroup]="myForm" novalidate>
          <ion-list>
            <ion-item>
              <ion-label color="medium" position="floating" translate>Puntos</ion-label>
              <ion-input type="number" formControlName="points"></ion-input>
            </ion-item>
            <ion-text color="danger" [hidden]="myForm.controls.points.valid">
              Ingrese una cantidad válida
            </ion-text>
            <ion-item>
              <ion-label color="medium" position="floating" translate>ID del usuario</ion-label>
              <ion-input type="text" formControlName="uid"></ion-input>
            </ion-item>
            <ion-text color="danger" [hidden]="myForm.controls.uid.valid">
              Ingrese el ID de la persona
            </ion-text>
            <ion-item>
              <ion-label color="medium" position="floating" translate>E-mail del usuario</ion-label>
              <ion-input type="text" formControlName="email"></ion-input>
            </ion-item>
            <ion-text color="danger" [hidden]="myForm.controls.uid.valid">
              Ingrese un email válido
            </ion-text>
            <ion-item>
              <ion-label color="medium" position="floating" translate>Nota</ion-label>
              <ion-input type="Nota" formControlName="note"></ion-input>
            </ion-item>
            <ion-text color="danger" [hidden]="myForm.controls.note.valid">
              Ingrese la nota comentario y/o razón de la carga de puntos
            </ion-text>
          </ion-list>
          <div class="ion-padding-horizontal">
            <ion-button
              type="submit"
              [disabled]="myForm.invalid || isLoading"
              color="tertiary"
              expand="block"
              translate
            >
              Cargar puntos
            </ion-button>
          </div>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
