<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/surveys-and-tests"></ion-back-button>
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span>{{ info?.title }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <form *ngIf="myForm" (ngSubmit)="onSubmit()" [formGroup]="myForm">
          <div class="border-radius ion-padding ion-margin">
            <h2>Información de encuesta/examen</h2>
          </div>
          <div class="border-radius bg-gray ion-padding ion-margin">
            <ion-input class="custom-white" label="Título" formControlName="title" type="text" label-placement="stacked"
              fill="outline" placeholder="Escribe el título de la encuesta/examen" [autofocus]="true"></ion-input>
            <div *ngIf="myForm?.controls?.title?.errors as error">
              <ion-text color="danger" *ngIf="error?.required">
                <small>Campo requerido</small>
              </ion-text>
            </div>
            <br>
            <p>Descripción</p>
            <tiny-editor [elementId]="'my-editor-chat-group'" (onEditorContentChange)="keyupHandler($event)"
              [contentHtml]="htmlContent" [resetEditor]="resetEditor" [rows]="10"></tiny-editor>
            <br>
          </div>
          <div class="border-radius ion-padding ion-margin">
            <h2>Preguntas: </h2>
          </div>
          <div formArrayName="questions">
            <div *ngFor="let question of myForm?.get('questions')?.controls as questions; let i=index"
              [formGroupName]="i" class="border-radius bg-gray ion-padding ion-margin">
              <ion-grid fixed>
                <ion-row>
                  <ion-col size="11">
                    <h3>Pregunta {{ i + 1 }}</h3>
                  </ion-col>
                  <ion-col size="1">
                    <ion-button *ngIf="questions?.length > 1" (click)="removeQuestion(i)" fill="clear" color="medium">
                      <ion-icon slot="icon-only" name="trash"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12">
                    <ion-textarea class="custom-white" label="Texto" formControlName="text" label-placement="stacked"
                      fill="outline" [placeholder]="'Escribe el texto de la pregunta ' + (i + 1)"
                      rows="4"></ion-textarea>
                    <div *ngIf="question?.controls?.text?.errors as error">
                      <ion-text color="danger" *ngIf="error?.required">
                        <small>Campo requerido</small>
                      </ion-text>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="6">
                    <ion-select color="dark" label="Tipo de pregunta" formControlName="type"
                      placeholder="Selecciona el tipo de pregunta" (ionChange)="typeChangeEvent($event,i)">
                      <ion-select-option *ngFor="let item of questionsTypes" [value]="item?.value">{{ item?.title
                        }}</ion-select-option>
                    </ion-select>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="question?.get('type')?.value === 'multiple'">
                  <ion-col size="6">
                    <ion-toggle formControlName="singleAnswer">Respuesta única</ion-toggle><br />
                  </ion-col>
                </ion-row>
              </ion-grid>
              <div [ngSwitch]="question?.get('type')?.value">
                <!-- MULTIPLE -->
                <ng-template [ngSwitchCase]="'multiple'">
                  <div formArrayName="options"
                    class="border-radius bg-gray-tone ion-margin ion-padding-horizontal ion-padding-bottom">
                    <div
                      *ngFor="let option of myForm?.get('questions')?.at(i)?.controls?.options?.controls as options; let ii=index"
                      [formGroupName]="ii">
                      <ion-grid fixed>
                        <ion-row>
                          <ion-col size="10">
                            <p>Opción {{ ii + 1 }}</p>
                          </ion-col>
                          <ion-col size="2">
                            <ion-button *ngIf="options?.length > 2" (click)="removeOption(i,ii)" fill="clear"
                              expand="block" color="dark">
                              <ion-icon slot="icon-only" name="trash"></ion-icon>
                            </ion-button>
                          </ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-col>
                            <ion-input class="custom" label="Texto" type="text" formControlName="text"
                              label-placement="stacked" fill="outline"
                              [placeholder]="'Texto de opción ' + (ii +1) "></ion-input>
                            <div *ngIf="option?.controls?.text?.errors as error">
                              <ion-text color="danger" *ngIf="error?.required">
                                <small>Campo requerido</small>
                              </ion-text>
                            </div>
                          </ion-col>
                          <ion-col *ngIf="myForm?.get('isTest').value === true">
                            <ion-input class="custom" label="Valor" type="text" formControlName="value"
                              label-placement="stacked" fill="outline"
                              [placeholder]="'Valor de opción ' + (ii +1) "></ion-input>
                          </ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-col>
                            <ion-toggle formControlName="isOpen">Opción abierta</ion-toggle>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </div>
                    <ion-button type="button" (click)="addOption(i)" fill="solid" color="secondary">
                      <ion-icon slot="end" name="add-circle-outline"></ion-icon>
                      Agregar opción
                    </ion-button>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-button (click)="addQuestion(); scrollToBottom();">
        <ion-icon slot="end" name="add"></ion-icon>
        Agregar pregunta
      </ion-button>
      <ion-button *ngIf="myForm" (click)="onSubmit()" [disabled]="myForm.invalid || isLoading === true">
        <ion-icon slot="end" name="save"></ion-icon>
        {{ info?.button1 }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>