<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Click en Banners</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-button (click)="initializeApp()" expand="block" fill="clear" shape="round">
          Recargar
          <ion-icon slot="end" name="refresh"></ion-icon>
        </ion-button>

        <h2 style="text-align: center;">Fecha: {{ dateStr }}</h2>

        <ion-card
          *ngFor="let item of advertisings"
          color="light"
          button
          [routerDirection]="'forward'"
          [routerLink]="['/banner-click/' + dateStr + '/' + item?.id]"
        >
          <ion-card-header color="primary">
            <ion-card-title>{{ item?.content }}</ion-card-title>
            <ion-card-subtitle>N° Clicks: {{ item?.count }}</ion-card-subtitle>
            <ion-card-subtitle>ID: {{ item?.id }}</ion-card-subtitle>
          </ion-card-header>

          <ion-card-content *ngIf="item?.info?.link as data">
            <h4><strong>Link</strong></h4>
            URL: {{ data?.url }}
          </ion-card-content>

          <ion-card-content *ngIf="item?.info?.product as data">
            <h4><strong>Producto</strong></h4>
            Nombre: {{ data?.name }} <br />
            Compañía: {{ data?.company }} <br />
            Tipo : {{ data?.type }}
          </ion-card-content>

          <ion-card-content *ngIf="item?.info?.rep as data">
            <h4><strong>Representante</strong></h4>
            Nombre: {{ data?.name }} <br />
            Compañía: {{ data?.company }} <br />
            Email: {{ data?.email }}<br />
            Teléfono 1: {{ data?.phone1 }}<br />
            Teléfono 2: {{ data?.phone2 }}
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="download()" color="secondary">
        <ion-icon slot="start" name="download"></ion-icon>
        Descargar reporte
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
