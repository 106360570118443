<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Planes Conectimed para representantes médicos</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-margin ion-no-padding" color="light">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-card *ngFor="let plan of plans">
          <ion-toolbar [color]="plan?.status === 'active' ? 'secondary' : 'medium'">
            <ion-title>{{ plan?.name }} <small *ngIf="plan?.default === true">(Default)</small></ion-title>
            <ion-buttons slot="end" (click)="options($event, plan?.default, plan?.id, plan?.status)">
              <ion-button>
                <ion-icon slot="icon-only" src="assets/icons/ellipsis-vertical-outline.svg"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-content>
            <h2>
              <strong>
                Max. Productos:
              </strong>
              <ion-text color="tertiary">
                <strong>
                  {{ plan?.max_products_limit === true ? plan?.max_products : 'Ilimitado' }}
                </strong>
              </ion-text>
            </h2>
            <h2>
              <strong>
                Max. Contactos:
              </strong>
              <ion-text color="tertiary">
                <strong>
                  {{ plan?.max_contacts_limit === true ? plan?.max_contacts : 'Ilimitado' }}
                </strong>
              </ion-text>
            </h2>
            <h2>
              <strong>
                Estatus:
              </strong>
              <ion-text [color]="plan?.status === 'active' ? 'tertiary' : 'danger'">
                <strong>
                  {{ plan?.status === 'active' ? 'Activo' : 'Inactivo' }}
                </strong>
              </ion-text>
            </h2>
            <p>{{ plan?.description }}</p>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="tertiary" (click)="addPlan()" [disabled]="isLoading == true">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
