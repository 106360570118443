import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { CredentialsService } from '@app/core';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { FIREBASE_API } from '../../../../environments/environment';
import { Logger } from '@app/core';
const log = new Logger('Location');

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  public myArray: any[] = [];
  arrayData: any[] = [];
  isLoading: boolean;
  contacts: boolean;
  token: string;
  filter: string = '';

  constructor(
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private http: HttpClient,
    private credential: CredentialsService,
    private firebase: FirebaseService
  ) {}

  ngOnInit() {
    this.init();
  }

  private async init(): Promise<void> {
    this.isLoading = true;
    try {
      let inUse = await this.afs
        .collection('states-in-use')
        .ref.orderBy('name', 'asc')
        .get();
      let myArray = inUse.docs.map(element => {
        const data: any = element.data();
        return { id: data.name, label: data.name, isChecked: false, count: data.count };
      });
      if (this.contacts === true) {
        try {
          this.token = await this.firebase.getToken();
          const snap: any = await this.http
            .post(
              `${FIREBASE_API}filters`,
              { token: this.token, uid: this.credential.credentials.uid, filter: this.filter },
              { observe: 'body' }
            )
            .toPromise();
          const myFilters = snap['medico'];
          const reduced: any = myArray.reduce(function(filtered: any[], item: any) {
            try {
              if (item.id && myFilters && myFilters[item.id]) {
                filtered.push({ id: item.id, label: item.label, isChecked: item.isChecked, count: myFilters[item.id] });
              }
            } catch (error) {
              log.error(error);
            }
            return filtered;
          }, []);
          myArray = reduced;
        } catch (error) {
          log.error(error);
        }
      }
      this.myArray = myArray;
      this.refillData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  selectItem(item: any) {
    item.isChecked = true;
    const element = [item];
    this.modalCtrl.dismiss({ arrayData: element });
  }

  refillData() {
    if (this.arrayData && this.arrayData[0]) {
      this.arrayData.forEach(element => {
        const index: number = this.myArray.map(e => e.id).indexOf(element.id);
        if (index > -1) {
          this.myArray[index].isChecked = element.isChecked;
        }
      });
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({ arrayData: this.arrayData });
  }

  clearFilter() {
    this.modalCtrl.dismiss({ arrayData: [] });
  }
}
