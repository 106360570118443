import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ImportProductsRoutingModule } from './import-products-routing.module';
import { ImportProductsComponent } from './import-products.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, IonicModule, ImportProductsRoutingModule, HttpClientModule],
  declarations: [ImportProductsComponent]
})
export class ImportProductsModule { }
