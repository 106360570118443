import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { DocumentData, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { DatabaseService } from '@app/shared/services/database.service';

@Component({
  selector: 'app-search-tags',
  templateUrl: './search-tags.component.html',
  styleUrls: ['./search-tags.component.scss']
})
export class SearchTagsComponent implements OnInit {
  @Input() callback: any;
  search: string = null;
  page: number = 1;
  searchTags: any[] = [];
  @Input() tags: any[] = [];
  next: QueryDocumentSnapshot<any>;
  back: QueryDocumentSnapshot<any>;
  limit: number = 100;

  constructor(public modalC: ModalController, private toastC: ToastController, private firePage: DatabaseService) {}

  ngOnInit() {}

  async buscar() {
    this.initializeApp();
  }

  private async initializeApp(): Promise<void> {
    try {
      await this.pagination();
    } catch (error) {
      console.error(error);
    }
  }

  public async pagination(direction?: 'back' | 'next') {
    try {
      let docs: any[] = [];
      let resp: any;
      switch (direction) {
        case 'next':
          resp = await this.firePage.getDocsCollection({
            path: 'tags',
            where: [
              { fieldPath: 'status', opStr: '==', value: 'active' },
              { fieldPath: 'search', opStr: 'array-contains', value: this.search }
            ],
            orderBy: { fieldPath: 'date', directionStr: 'desc' },
            limit: this.limit,
            startAfter: this.next
          });
          docs = resp.docs;
          break;

        case 'back':
          resp = await this.firePage.getDocsCollection({
            path: 'tags',
            where: [
              { fieldPath: 'status', opStr: '==', value: 'active' },
              { fieldPath: 'search', opStr: 'array-contains', value: this.search }
            ],
            orderBy: { fieldPath: 'date', directionStr: 'asc' },
            limit: this.limit,
            startAfter: this.back
          });
          docs = resp.docs.reverse();
          break;

        default:
          resp = await this.firePage.getDocsCollection({
            path: 'tags',
            where: [
              { fieldPath: 'status', opStr: '==', value: 'active' },
              { fieldPath: 'search', opStr: 'array-contains', value: this.search }
            ],
            orderBy: { fieldPath: 'date', directionStr: 'desc' },
            limit: this.limit
          });
          docs = resp.docs;
          break;
      }
      this.mapDocs(docs);
    } catch (e) {
      console.error(e);
    }
  }

  private mapDocs(docs: QueryDocumentSnapshot<any>[]) {
    if (docs.length > 0) {
      this.next = docs[docs.length - 1];
      this.back = docs[0];
      let DOCS = docs.map(e => {
        let data: any = e.data();
        data.name = data.title;
        return { id: e.id, ...data };
      });
      this.searchTags = DOCS;
    } else {
      this.searchTags = [];
    }
  }

  async generateData() {
    if (this.tags.length === 0) {
      await (await this.toastC.create({ message: 'No ha seleccionado tags', duration: 1200 })).present();
    } else {
      const theTags = [];
      const tags = [];
      for (const tag of this.tags) {
        theTags.push({
          name: tag.name,
          id: tag.id
        });
        tags.push(tag.id);
      }
      this.callback({
        tags,
        theTags
      });
      this.modalC.dismiss();
    }
  }
}
