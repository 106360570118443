import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { PushNotificationsRoutingModule } from './push-notifications-routing.module';
import { PushNotificationsComponent } from './push-notifications.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NotificationsSendedComponent } from './notifications-sended/notifications-sended.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PushNotificationsRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [PushNotificationsComponent, NotificationsSendedComponent]
})
export class PushNotificationsModule { }
