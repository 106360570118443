import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AssignContactsV2RoutingModule } from './assign-contacts-v2-routing.module';
import { AssignContactsV2Component } from './assign-contacts-v2.component';
import { SearchModule } from '../search/search.module';
import { CardsModule } from '../shared/cards/cards.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    AssignContactsV2RoutingModule,
    HttpClientModule,
    SearchModule,
    CardsModule,
    FormsModule
  ],
  declarations: [AssignContactsV2Component]
})
export class AssignContactsV2Module {}
