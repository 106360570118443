import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, AlertController, ToastController, LoadingController } from '@ionic/angular';
import { NewAdvertisingComponent } from './new-advertising/new-advertising.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '../core/logger.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { IonReorderGroup } from '@ionic/angular';
const log = new Logger('Advertising');

@Component({
  selector: 'app-advertising',
  templateUrl: './advertising.component.html',
  styleUrls: ['./advertising.component.scss']
})
export class AdvertisingComponent implements OnInit {
  @ViewChild(IonReorderGroup, {
    static: true
  })
  reorderGroup: IonReorderGroup;
  advertisings: any[] = [];
  canReOrder: boolean = false;

  constructor(
    private modalC: ModalController,
    private firestore: AngularFirestore,
    private alertC: AlertController,
    private toastC: ToastController,
    private aStorage: AngularFireStorage,
    private loadingCtrl: LoadingController
  ) { }

  async ngOnInit() {
    // // only for the first time
    // const resp = await this.firestore.collection("advertising").ref.get();
    // const date = new Date();
    // resp.docs.forEach((e, index) => {
    //   e.ref.update({
    //     order: index,
    //     creation_date: date,
    //     update_date: date
    //   });
    // });
    this.firestore
      .collection('advertising', q => {
        return q.orderBy('order', 'asc');
      })
      .valueChanges()
      .subscribe(value => {
        this.advertisings = value;
      });
  }

  async openAdvertising(advertising: any = null, accion: 'Crear' | 'Editar' = 'Crear') {
    const modal = await this.modalC.create({
      component: NewAdvertisingComponent,
      componentProps: {
        advertising,
        accion
      },
      cssClass: 'modal-class'
    });
    modal.present();
  }

  async deleteAdvertising(id: string) {
    const alert = await this.alertC.create({
      header: 'Borrar Publicidad',
      message: '¿Esta seguro de que desea borrar la publicidad?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Borrar',
          handler: async () => {
            try {
              this.aStorage
                .ref(`advertising/${id}`)
                .delete()
                .toPromise()
                .finally(() => {
                  this.firestore
                    .doc(`advertising/${id}`)
                    .delete()
                    .then(async () => {
                      await (await this.toastC.create({ message: 'Se borro la publicidad', duration: 1200 })).present();
                    });
                });
            } catch (error) {
              log.error(error);
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async saveOrder() {
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      const batch = this.firestore.firestore.batch();
      let index: number = 0;
      for (const item of this.advertisings) {
        const ref = this.firestore.collection('advertising').doc(item.id).ref;
        batch.update(ref, { order: index });
        index++;
      }
      await batch.commit();
      this.canReOrder = !this.canReOrder;
    } catch (e) {
      log.error(e);
    }
    loading.dismiss();
  }

  doReorder(e: any) {
    let movedItem = this.advertisings.splice(e.detail.from, 1)[0];
    this.advertisings.splice(e.detail.to, 0, movedItem);
    e.detail.complete();
  }

  toggleReorderGroup() {
    this.reorderGroup.disabled = !this.reorderGroup.disabled;
  }
}
