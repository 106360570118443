<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">Detalles de respuestas</ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-label class="ion-text-wrap">
            <div *ngIf="user?.type === 'uid'">
              <ion-text color="dark">
                <h1>
                  {{ user?.info?.name }}<br>
                  <small>{{ user?.info?.uid }}</small><br>
                  <small>{{ user?.info?.email }}</small><br>
                  <small>{{ user?.info?.speciality }}</small>
                </h1>
              </ion-text>
            </div>
            <div *ngIf="!(user?.type === 'uid')">
              <ion-text color="dark">
                <h1 *ngIf="user?.info?.email">{{ user?.info?.name && user?.info?.name !== '' ?
                  user.info.name : '(Sin nombre)' }}<br><small>{{ user?.info?.email
                    }}</small></h1>
                <div *ngIf="!(user?.info?.email)">
                  <h1 *ngIf="user?.info?.name"> {{ user?.info?.name && user?.info?.name !== '' ?
                    user.info.name : '(Sin nombre)' }}</h1>
                  <h1 *ngIf="!(user?.info?.name)">Anónimo</h1>
                </div>
              </ion-text>
            </div>
            <div>
              <span>
                <ion-text color="dark">
                  Fecha de respuesta:
                </ion-text>
              </span>
              <span>
                <ion-text color="medium">
                  <small><i>{{ user?.dateStr }}</i> </small>
                </ion-text>
              </span>
            </div>

          </ion-label>
        </ion-item>
        <ion-item *ngFor="let answer of answers; let i = index" lines="none">
          <ion-label class="ion-text-wrap">
            <ion-text color="dark">
              <h2><strong>{{ (i + 1) + '.- '+answer?.text }} </strong></h2>
            </ion-text>
            <div [ngSwitch]="answer?.type">
              <div *ngSwitchCase="'multiple'">
                <div *ngFor="let item of answer?.response">
                  <ion-text [color]="item?.selected === true ? 'dark': 'medium'" class="ion-text-wrap">
                    <div *ngIf="item?.selected === true">
                      <strong>
                        {{ item?.text }} <ion-badge *ngIf="item?.isOpen === true && (answer?.singleAnswer === false)"
                          color="primary" mode="ios">Opción
                          abierta</ion-badge> <ion-icon color="success" name="checkmark-outline"></ion-icon>
                      </strong>
                      <div *ngIf="item?.openAnswer">
                        <ion-text color="secondary">
                          <strong>
                            <i><small>R: "{{ item?.openAnswer }}"</small></i>
                          </strong>
                        </ion-text>
                      </div>
                    </div>
                    <span *ngIf="!(item?.selected === true)">
                      {{ item?.text }}
                    </span>
                  </ion-text>
                </div>
              </div>
              <div *ngSwitchCase="'open'">
                <ion-text color="medium">
                  <p>{{ answer?.response }}</p>
                </ion-text>
              </div>
              <div *ngSwitchCase="'rate'">
                <div [ngSwitch]="answer?.response?.value">
                  <ion-text color="medium">
                    <p *ngSwitchCase="1"><strong>Muy mala</strong></p>
                    <p *ngSwitchCase="2"><strong>Mala</strong></p>
                    <p *ngSwitchCase="3"><strong>Regular</strong></p>
                    <p *ngSwitchCase="4"><strong>Buena</strong></p>
                    <p *ngSwitchCase="5"><strong>Muy buena</strong></p>
                  </ion-text>
                </div>
              </div>
            </div>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>