<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/click-meeting-reports"></ion-back-button>
    </ion-buttons>
    <ion-title class="ion-text-center"> ClickMeeting Detail</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-margin ion-no-padding" color="light">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-card>
          <ion-card-header color="primary">
            <ion-card-title>{{ item?.conference?.name }}</ion-card-title>
            <ion-card-subtitle><strong>Inicio: </strong>
              <small><i>{{ item?.conference?.inicio }}</i></small>
              | <strong>Fin: </strong>
              <small><i>{{ item?.conference?.fin }}</i></small></ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div [innerHTML]="item?.conference?.trustHtml"></div>
          </ion-card-content>
          <ion-card-content>
            <ion-list>
              <ion-list-header>
                <ion-label>
                  <h2><strong>Sesiones</strong></h2>
                </ion-label>
              </ion-list-header>
              <ion-item *ngFor="let item2 of sessions">
                <ion-label>
                  <p>ID: {{ item2?.id }}</p>
                  <p>
                    <strong>Inicio: </strong>
                    {{ item2?.inicio }}
                    | <strong> Fin: </strong>
                    {{ item2?.fin }}
                    | <strong> Max: </strong> {{ item2?.max_visitors }} | <strong> Total: </strong>
                    {{ item2?.total_visitors }}
                  </p>
                </ion-label>
                <ion-buttons slot="end">
                  <!-- <ion-button (click)="attendees(item2?.id)">
                    <ion-icon slot="end" name="person"></ion-icon>
                    Asistentes
                  </ion-button> -->
                  <ion-button (click)="certificates(item, item2)">
                    <ion-icon slot="end" name="ribbon"></ion-icon>
                    Constancias
                  </ion-button>
                  <ion-button (click)="getChats(item2)" *ngIf="item2?.isInBQ !== true">
                    <ion-icon slot="end" name="download"></ion-icon>
                    Sincronizar
                  </ion-button>
                  <ion-button (click)="triggerInputFile(item2)" *ngIf="item2?.isInBQ !== true">
                    <ion-icon slot="end" name="cloud-upload"></ion-icon>
                    Sincronizar Manualmente
                  </ion-button>
                  <ion-button color="success" *ngIf="item2?.isInBQ === true">
                    <ion-icon slot="end" name="checkmark-circle"></ion-icon>
                    Completado
                  </ion-button>
                </ion-buttons>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <input id="csvFile" #csvFile name="csvFile" accept=".xlsx" type="file" class="hide"
      (change)="selectFile1($event.target)" />
  </ion-grid>
</ion-content>