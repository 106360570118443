import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { Logger } from '@app/core';
import { from } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UtilitiesService } from '@app/shared/services/utilities.service';
const log = new Logger('CompanyDetailModalComponent');
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ValidateUrl } from '@app/shared/validators/url.validator';

@Component({
  selector: 'app-company-detail-modal',
  templateUrl: './company-detail-modal.component.html',
  styleUrls: ['./company-detail-modal.component.scss']
})
export class CompanyDetailModalComponent implements OnInit {
  public id: string;
  public company: any = {};
  public dataForm: FormGroup;
  public isLoading: boolean = false;
  public logo: any;
  public toUploadLogo: any;
  public justView: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private afs: AngularFirestore,
    private utilities: UtilitiesService,
    private storage: AngularFireStorage
  ) {
    this.init();
  }

  init() {
    this.initForm();
  }

  async ngOnInit() {
    if (this.id) {
      this.isLoading = true;
      const loadingOverlay = await this.loadingController.create({});
      from(loadingOverlay.present());
      try {
        const RESP = await this.afs
          .collection('companies')
          .doc(this.id)
          .ref.get();
        this.company = RESP.data();
        this.dataForm.patchValue({
          name: this.company && this.company.name ? String(this.company.name) : '',
          description: this.company && this.company.description ? String(this.company.description) : '',
          businessType: this.company && this.company.businessType ? String(this.company.businessType) : '',
          companyWebsite: this.company && this.company.companyWebsite ? String(this.company.companyWebsite) : ''
        });
        this.logo = this.company.logo ? this.company.logo : undefined;
        this.dataForm.updateValueAndValidity();
      } catch (e) {
        log.error(e);
      }
      loadingOverlay.dismiss();
      this.isLoading = false;
    }
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      businessType: ['', Validators.required],
      companyWebsite: ['', [Validators.required, ValidateUrl.isValid]]
    });
  }

  public async save() {
    try {
      await this.set(this.id);
    } catch (e) {
      log.error(e);
    }
  }

  public async set(id?: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    from(loadingOverlay.present());
    try {
      let data: any = {
        name: this.dataForm && this.dataForm.value && this.dataForm.value.name ? String(this.dataForm.value.name) : '',
        businessType:
          this.dataForm && this.dataForm.value && this.dataForm.value.businessType
            ? String(this.dataForm.value.businessType)
            : '',
        companyWebsite:
          this.dataForm && this.dataForm.value && this.dataForm.value.companyWebsite
            ? String(this.dataForm.value.companyWebsite)
            : '',
        logo: null,
        status: 'active',
        id: id ? id : null,
        slug: '',
        description:
          this.dataForm && this.dataForm.value && this.dataForm.value.description
            ? String(this.dataForm.value.description)
            : ''
      };
      data.nameStr = '';
      data.search = [];

      let message: string = '';

      if (id) {
        if (this.toUploadLogo) {
          const resp = await this.storage.upload(`companies/${id}`, this.toUploadLogo);
          data.logo = await resp.ref.getDownloadURL();
        } else {
          data.logo = this.logo;
        }
        await this.afs
          .collection('companies')
          .doc(id)
          .update(data);
        message = '¡Compañia actualizada correctamente!';
      } else {
        const resp = await this.afs.collection('companies').add(data);
        const respStorage = await this.storage.upload(`companies/${resp.id}`, this.toUploadLogo);
        const logo = await respStorage.ref.getDownloadURL();

        await this.afs
          .collection('companies')
          .doc(resp.id)
          .update({ logo, id: resp.id });

        message = '¡Compañia agregada correctamente!';
      }

      this.closeModal();
      this.utilities.toast(message);
    } catch (e) {
      log.error(e);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  public closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }

  loadImg(imgInput: any) {
    const files: File[] = imgInput.files;
    if (files.length !== 0) {
      this.toUploadLogo = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.logo = reader.result;
      };
    }
  }
}
