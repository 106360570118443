import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostsReviewComponent } from './posts-review.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: '', component: PostsReviewComponent, data: { title: 'Posts' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostsReviewRoutingModule { }
