import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@app/core/logger.service';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { UsersAnswersComponent } from './users-answers/users-answers.component';
import moment from 'moment';
import { ADMIN_APP_URL, APP_URL } from '@env/environment';
import { DetailSurveyComponent } from '../detail-survey/detail-survey.component';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { AgCartesianSeriesOptions, AgChartOptions } from 'ag-charts-community';
import * as XLSX from 'xlsx';
const log = new Logger('SurveyChartsComponent');
@Component({
  selector: 'app-survey-charts',
  templateUrl: './survey-charts.component.html',
  styleUrls: ['./survey-charts.component.scss'],
})
export class SurveyChartsComponent implements OnInit {
  public isLoading = false;
  survey: any = {};
  responses: any[] = [];
  questions: any[] = [];
  users: any[] = [];
  id: string = '';
  colors: string[] = [
    "#00bf6f",
    "#507cb6",
    "#6bc8cd",
    "#ff8b4f",
    "#7d5e90",
    "#009688",
    "#ef5350",
    "#42a5f5",
    "#66bb6a",
    "#ffca28",
    "#ab47bc",
    "#26a69a",
    "#00bf6f",
    "#507cb6",
    "#6bc8cd",
    "#ff8b4f",
    "#7d5e90",
    "#009688",
    "#ef5350",
    "#42a5f5",
    "#66bb6a",
    "#ffca28",
    "#ab47bc",
    "#26a69a"
  ];
  specialties: AgChartOptions = {
    legend: {
      enabled: true,
      orientation: 'vertical',
      position: 'right',
    },
    title: {
      text: "Especialidad"
    },
    series: [
      {
        sectorLabelKey: "amount",
        sectorLabel: {
          enabled: true,
          color: "black",
          fontWeight: "bold",
          formatter: ({ datum }) => {
            return `${datum.percentage}%`
          }
        },
        type: "pie",
        angleKey: "amount",
        legendItemKey: 'asset',
        tooltip: {
          showArrow: false,
          enabled: true,
          interaction: {
            enabled: true
          },
          renderer: ({ datum, color }) => {
            return `
            <div class="ag-chart-tooltip ag-chart-tooltip-no-interaction ag-chart-tooltip-arrow">
              <div class="ag-chart-tooltip-title" style="color: white; background-color: ${color}">${datum['asset']}:</div>
              <div class="ag-chart-tooltip-content">${datum['percentage']}% (${datum['amount']})</div>
            </div>`;
          }
        }
      }
    ]
  };
  locations: AgChartOptions = {
    legend: {
      enabled: true,
      orientation: 'vertical',
      position: 'right',
    },
    title: {
      text: "Localidad"
    },
    series: [
      {
        sectorLabelKey: "amount",
        sectorLabel: {
          enabled: true,
          color: "black",
          fontWeight: "bold",
          formatter: ({ datum }) => {
            return `${datum.percentage}%`
          }
        },
        type: "pie",
        angleKey: "amount",
        legendItemKey: 'asset',
        tooltip: {
          showArrow: false,
          enabled: true,
          interaction: {
            enabled: true
          },
          renderer: ({ datum, color }) => {
            return `
            <div class="ag-chart-tooltip ag-chart-tooltip-no-interaction ag-chart-tooltip-arrow">
              <div class="ag-chart-tooltip-title" style="color: white; background-color: ${color}">${datum['asset']}:</div>
              <div class="ag-chart-tooltip-content">${datum['percentage']}% (${datum['amount']})</div>
            </div>`;
          }
        }
      }
    ]
  };

  constructor(
    private afs: AngularFirestore,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private aRoute: ActivatedRoute,
    private router: Router,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private utilities: UtilitiesService
  ) { }

  async download() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      let responses: any[] = [];
      let title: string = this.survey && this.survey.title ? this.survey.title : 'file';
      for (let user of this.users) {
        let data: any = {};
        data["Encuesta "] = this.survey && this.survey.title ? this.survey.title : '';
        data["Nombre"] = user && user.info && user.info.name ? user.info.name : '';
        data["Correo"] = user && user.info && user.info.email ? user.info.email : '';
        for (let [index, question] of Array.from<any>(this.survey.questions).entries()) {
          let response: string = '';
          switch (question.type) {
            case 'multiple':
              if (question && question.singleAnswer === true) {
                if (question.options[user.results[index].answer].isOpen === true) {
                  response = user.results[index].openAnswer;
                } else {
                  response = question.options[user.results[index].answer].text;
                }
              } else {
                try {
                  for (let [i, item] of Array.from<any>(user.results[index].answers).entries()) {
                    if (item.answer === true) {
                      if (!(item.openAnswer === '')) {
                        response += `, ${item.openAnswer}`;
                      } else {
                        response += `, ${question.options[i].text}`;
                      }
                    }
                  }
                } catch (e) {
                  log.error(e);
                }
              }
              response = response.replace(", ", '');
              break;
            case 'rate':
              switch (Number(user.results[index])) {
                case 1:
                  response = 'Muy mala';
                  break;
                case 2:
                  response = 'Mala';
                  break;
                case 3:
                  response = 'Regular';
                  break;
                case 4:
                  response = 'Buena';
                  break;
                case 5:
                  response = 'Muy buena';
                  break;
              }
              break;
            case 'open':
              response = user.results[index];
              break;
          }
          data[question.text] = response;
        }
        responses.push(data);
      }
      let wb = XLSX.utils.book_new();
      const aoa_to_sheet: any[] = [];
      let ws = XLSX.utils.aoa_to_sheet(aoa_to_sheet);
      XLSX.utils.sheet_add_json(ws, responses, { origin: -1 });
      XLSX.utils.book_append_sheet(wb, ws, "Hoja 1");
      XLSX.writeFile(wb, `${title}.xlsx`);
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  ngOnInit() {
    this.initializeApp();
  }

  initializeApp() {
    if (this.aRoute && this.aRoute.snapshot && this.aRoute.snapshot.params && this.aRoute.snapshot.params.id) {
      this.id = this.aRoute.snapshot.params.id;
      this.init(this.id);
    }
  }

  async init(id: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const respResults = await this.afs.collection(`surveys-and-tests/${id}/tests-results`).ref.orderBy('date', 'desc').get();
      let responses: any[] = respResults.docs.map(item => {
        let data: any = item.data();
        data.dateStr = moment(data.date.toDate()).format('YYYY-MM-DD HH:mm');
        return {
          dateStr: data.dateStr,
          results: item.get('results'),
          type: data && data.type ? data.type : null,
          info: data && data?.info ? data?.info : {}
        };
      });
      this.users = responses;
      const filtered: any[] = this.users.filter(e => {
        if (e && e.info && e.info.uid) {
          return e;
        }
      }).map(e => e.info);
      for (const user of filtered) {
        try {
          if (!(user && user.state)) {
            const uid: string = user.uid;
            const resp: any = await this.afs.collection('medico-meta').doc(uid).ref.get();
            const data: any = resp.data();
            const state = data && data.address1 && data.address1.state ? data.address1.state : undefined;
            user['state'] = state;
            const ref = this.afs.doc(`surveys-and-tests/${id}/tests-results/${user.uid}`).ref;
            await ref.update({ "info.state": state || null });
          }
        } catch (error) {
          log.error(error);
        }
      }
      this.locations.data = this.chartData({ array: filtered, key: 'state', otherLabel: 'Otra', length: 7 });
      this.specialties.data = this.chartData({ array: filtered, key: 'speciality', otherLabel: 'Otra', length: 7 });
      const respSurvey = await this.afs.doc(`surveys-and-tests/${id}`).ref.get();
      this.survey = respSurvey.data();
      this.survey.html = this.utilities.trustHtml(this.survey.description);
      this.questions = Array.from(this.survey["questions"]).map((item: any, i: number) => {
        let ret: any = {};
        if (item && item.type === 'multiple') {
          let openQuestions: any[] = [];
          const data: any[] = Array.from(item.options).map((_item: any, ii: number) => {
            if (_item && _item.isOpen && _item.isOpen === true) {
              openQuestions.push(
                {
                  text: _item && _item.text ? _item.text : '',
                  opAnws: this.getOptionOpenAnws(responses, i, ii)
                }
              );
            }
            const _ret: any = {};
            _ret.asset = _item.text;
            _ret["amount" + ii] = this.getValueToChart(responses, i, ii, item.singleAnswer);
            _ret["_value"] = _ret["amount" + ii];
            return _ret;
          });

          // PERCENTAGE
          let _data: any[] = [];
          for (const [index, value] of data.entries()) {
            _data.push(value["amount" + index])
          }
          let suma = 0;
          _data.forEach(function (numero) {
            suma += numero;
          });
          for (const [index, value] of data.entries()) {
            const perc = Number(((value["amount" + index] / suma) * 100).toFixed(2));
            value["amount" + index] = perc;
          }
          // PERCENTAGE

          let total: number = 0;
          for (let item of data) {
            total += Number(item.amount);
          }

          const series: AgCartesianSeriesOptions[] = Array.from(item.options).map((item: any, index: number) => {
            item;
            const _ret: AgCartesianSeriesOptions = {
              type: 'bar',
              xKey: 'asset',
              yKey: "amount" + index,
              yName: 'Valor',
              fill: this.colors[index],
              stacked: true,
              tooltip: {
                enabled: true,
                showArrow: false,
                renderer: ({ datum, yName, color }) => {
                  return `
                  <div class="ag-chart-tooltip ag-chart-tooltip-no-interaction ag-chart-tooltip-arrow">
                    <div class="ag-chart-tooltip-title" style="color: white; background-color: ${color}">${yName}:</div>
                    <div class="ag-chart-tooltip-content">${datum['amount' + index]}% (${datum['_value']})</div>
                  </div>`;
                }
              }
            };
            return _ret
          });

          let options: AgChartOptions = {
            data,
            series: series,
            axes: [
              {
                max: 100,
                type: 'number',
                position: 'bottom',
                tick: {
                  interval: 10
                }
              },
              {
                type: 'category',
                position: 'left'
              }
            ],
            legend: {
              enabled: false
            }
          };
          ret = { type: item.type, options: options, openQuestions: openQuestions, responsed: this.users.length | 0, notResponsed: 0 }
        } else if (item && item.type === 'rate') {
          let array: any[] = [];
          for (let index = 0; index <= Number(item.max); index++) {
            array.push({ value: '', text: String(index) });
          }
          const data: any[] = Array.from(array).map((item: any, ii: number) => {
            item;
            const _ret: any = {};
            _ret.answer = this.label(String(ii));
            _ret["value" + ii] = this.getValueToChart(responses, i, ii, true);
            _ret["_value"] = _ret["value" + ii];
            return _ret
          });
          data.splice(0, 1);

          // // PERCENTAGE
          let _data: any[] = [];
          for (const [index, value] of data.entries()) {
            _data.push(value["value" + (index + 1)])
          }
          let suma = 0;
          _data.forEach(function (numero) {
            suma += numero;
          });
          for (const [index, value] of data.entries()) {
            const perc = Number(((value["value" + (index + 1)] / suma) * 100).toFixed(2));
            value["value" + (index + 1)] = perc;
          }
          // // PERCENTAGE

          const series: AgCartesianSeriesOptions[] = Array.from(array).map((item: any, index: number) => {
            item;
            const _ret: AgCartesianSeriesOptions = {
              type: 'bar',
              xKey: 'answer',
              yKey: "value" + index,
              yName: 'Valor',
              fill: this.colors[index - 1],
              stacked: true,
              tooltip: {
                enabled: true,
                showArrow: false,
                renderer: ({ datum, yName, color }) => {
                  return `
                  <div class="ag-chart-tooltip ag-chart-tooltip-no-interaction ag-chart-tooltip-arrow">
                    <div class="ag-chart-tooltip-title" style="color: white; background-color: ${color}">${yName}:</div>
                    <div class="ag-chart-tooltip-content">${datum['value' + index]}% (${datum['_value']})</div>
                  </div>`;
                }
              }
            };
            return _ret
          });
          series.splice(0, 1);
          let options: AgChartOptions = {
            data,
            series: series,
            axes: [
              {
                max: 100,
                type: 'number',
                position: 'bottom',
                tick: {
                  interval: 10
                }
              },
              {
                type: 'category',
                position: 'left'
              }
            ],
            legend: {
              enabled: false
            }
          };
          ret = { type: item.type, options, anwers: this.getValueToRange(responses, i), responsed: this.users.length | 0, notResponsed: 0 }
        } else {
          const _data: any = this.getValueTo(responses, i);
          ret = { type: 'open', anwers: _data.value, responsed: _data.responsed, notResponsed: _data.notResponsed }
        }
        ret.text = item.text;
        return ret
      });
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  label(value: string): string {
    let label: string = '';
    switch (value) {
      case '1':
        label = 'Muy mala';
        break;
      case '2':
        label = 'Mala';
        break;
      case '3':
        label = 'Regular';
        break;
      case '4':
        label = 'Buena';
        break;
      case '5':
        label = 'Muy buena';
        break;
    }
    return label;
  }

  getOptionOpenAnws(responses: any[], i: number, ii: number): any[] {
    const ret: any[] = [];
    for (let response of responses) {
      if (response && response.results && response.results[i] && response.results[i].answers) {
        if (response && response.results && response.results[i] && response.results[i].answers && response.results[i].answers[ii] && response.results[i].answers[ii].answer && response.results[i].answers[ii].answer === true) {
          ret.push({ info: response.info, answer: response.results[i].answers[ii].openAnswer, type: response.type });
        }
      } else if (response && response.results && response.results[i] && response.results[i].answer) {
        const n1: number = Number(response.results[i].answer);
        const n2: number = Number(ii);
        if (n1 === n2) {
          ret.push({ info: response.info, answer: response.results[i].openAnswer, type: response.type });
        }
      }
    }
    return ret;
  }

  getValueToChart(responses: any[], i: number, ii: number, isSingleAnswer: boolean): number {
    try {
      responses = responses.map(item => {
        let ret: any = {};
        for (let key of Object.keys(item.results)) {
          if ((item && item.results && item.results[key] && item.results[key].answer) || item.results[key].answer === 0) {
            ret[key] = item.results[key].answer;
          } else {
            ret[key] = item.results[key];
          }
        }
        return ret;
      });
      let ret: number[] = [];
      for (let item of responses) {
        if (isSingleAnswer === false) {
          try {
            let _array: number[] = [];
            for (const [index, _item] of Array.from(item[i].answers).entries()) {
              if ((_item as any).answer === true) {
                _array.push(index);
              }
            }
            ret = ret.concat(_array);
          } catch (e) {
            log.error(e);
          }
        } else {
          ret.push(Number(item[i]));
        }
      }
      const newRet = ret.filter(item => {
        if (item === ii) {
          return true;
        } else {
          return false
        }
      });
      return newRet.length;
    } catch (e) {
      log.error(e);
      return 0;
    }
  }

  getValueTo(responses: any[], i: number): any {
    let responsed: number = 0;
    let notResponsed: number = 0;
    let ret = responses.map(item => {
      let data: any = item;
      const res = data.results[i];
      if (res && res != undefined && res != null && res != '') {
        responsed++;
      } else {
        notResponsed++;
      }
      return { info: data.info, answer: res, type: data.type }
    });
    return { value: ret, responsed, notResponsed }
  }

  getValueToRange(responses: any[], i: number): { value: any[], average: number } {
    let summation: number = 0;
    let ret: any[] = responses.map(item => {
      let data: any = item;
      summation += Number(data.results[i]);
      return { info: data.info, answer: data.results[i], type: data.type }
    });
    const average: number = summation / ret.length;
    return { value: ret, average: average };
  }

  async details(user: any) {
    try {
      const modal = await this.modalCtrl.create({
        component: UsersAnswersComponent,
        componentProps: { user, survey: this.survey }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  getLink() {
    const link = `${APP_URL}/surveys/${btoa(this.id)}`;
    this.linkAlert(link);
  }

  getSharedLink() {
    const link = `${ADMIN_APP_URL}/shared-survey-results/${btoa(this.id)}`;
    this.linkAlert2(link);
  }

  async linkAlert2(link: string) {
    const alert = await this.alertController.create({
      header: `Link para compartir resultados de encuesta`,
      message: link,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Copiar',
          handler: async () => {
            this.copiarContenido(link)
          }
        }
      ]
    });
    await alert.present();
  }

  async linkAlert(link: string) {
    const alert = await this.alertController.create({
      header: `Link de encuesta`,
      message: link,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Copiar',
          handler: async () => {
            this.copiarContenido(link)
          }
        }
      ]
    });
    await alert.present();
  }

  async copiarContenido(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      this.presentToast('top', 'Contenido copiado al portapapeles');
    } catch (err) {
      this.presentToast('top', 'Error al copiar');
      log.error('Error al copiar: ', err);
    }
  }

  async presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 2500,
      position: position,
    });
    await toast.present();
  }

  async deleteSurvey() {
    const alert = await this.alertController.create({
      header: `Confirmar eliminación`,
      message: `¿Está seguro de eliminar esta encuesta?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Eliminar',
          handler: async () => {
            this._deleteSurvey(this.id);
          }
        }
      ]
    });
    await alert.present();
  }

  async _deleteSurvey(id: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      await this.afs.collection('surveys-and-tests').doc(id).delete();
      this.presentToast('top', "¡Encuesta eliminada correctamente!");
      this.router.navigate(['/surveys-and-tests']);
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async detailSurvey() {
    try {
      const modal = await this.modalCtrl.create({
        component: DetailSurveyComponent,
        componentProps: {
          id: this.id
        }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  chartData(params: { array: any[], key: string, otherLabel?: string, length?: number }) {
    let items: any = {};
    for (let item of params.array) {
      let element: any = items[item[params.key]];
      if (element) {
        items[item[params.key]] = Number(items[item[params.key]]) + 1;
      } else {
        items[item[params.key]] = 1;
      }
    }
    let data: any[] = [];
    for (let item of Object.keys(items)) {
      data.push({ asset: item, amount: items[item] })
    }
    data = data.sort((a, b) => {
      return a.amount - b.amount;
    }).reverse();
    let other: number = 0;
    data = data.filter(e => {
      if (e && !(e.asset === 'undefined' || e.asset === 'null')) {
        return true;
      } else {
        other += e.amount;
        return false;
      }
    });
    var part1: any = [];
    var part2: any = [];
    if (data.length > params.length) {
      part1 = data.slice(0, params.length);
      part2 = data.slice(params.length, data.length);
      for (let item of part2) {
        other += item.amount;
      }
    } else {
      part1 = data;
    }
    part1.push({ asset: params.otherLabel || 'Otro', amount: (Number(this.users.length - params.array.length) + other) })
    //part1.push({ asset: params.otherLabel || 'Otro', amount: other });
    let total: number = 0;
    for (let item of part1) {
      total += Number(item.amount);
    }
    for (let item of part1) {
      const perc = Number(((item["amount"] / total) * 100).toFixed(2));
      item.percentage = perc;
    }
    return part1;
  }
}
