import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LoaderComponent } from './loader/loader.component';
import { SearchByCompanyComponent } from './search-by-company/search-by-company.component';
import { SearchByCompanyV2Component } from './search-by-company-v2/search-by-company-v2.component';
import { CardsModule } from './cards/cards.module';
import { SpecialitesComponent } from './components/specialites/specialites.component';
import { LocationComponent } from './components/location/location.component';
import { SearchCompanyModule } from './search-company/search-company.module';
import { UserCardComponent } from './user-card/user-card.component';
import { DatabaseModalComponent } from './components/database-modal/database-modal.component';
import { FirebaseConstantsComponent } from './components/firebase-constants/firebase-constants.component';
import { FormsModule } from '@angular/forms';
import { ChatModule } from './chat/chat.module';

@NgModule({
  imports: [IonicModule, CommonModule, CardsModule, SearchCompanyModule, FormsModule, ChatModule],
  declarations: [
    LoaderComponent,
    SearchByCompanyComponent,
    SearchByCompanyV2Component,
    SpecialitesComponent,
    LocationComponent,
    UserCardComponent,
    DatabaseModalComponent,
    FirebaseConstantsComponent
  ],
  exports: [
    LoaderComponent,
    SearchByCompanyComponent,
    SearchByCompanyV2Component,
    SpecialitesComponent,
    LocationComponent,
    UserCardComponent
  ]
})
export class SharedModule { }
