import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveysAndTestsComponent } from './surveys-and-tests.component';
import { SurveyChartsComponent } from './survey-charts/survey-charts.component';
import { SetSurveyComponent } from './set-survey/set-survey.component';

const routes: Routes = [
  { path: '', component: SurveysAndTestsComponent, data: { title: 'Encuestas y exámenes Conectimed' } },
  { path: 'set', component: SetSurveyComponent, data: { title: 'Agregar encuesta' } },
  { path: 'set/:id', component: SetSurveyComponent, data: { title: 'Editar encuesta' } },
  { path: 'detail/:id', component: SurveyChartsComponent, data: { title: 'Resultados de encuestas' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SurveysAndTestsRoutingModule { }
