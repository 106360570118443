import { Component, OnInit, Input } from '@angular/core';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { Logger } from '@app/core';
const log = new Logger('User Card');

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() item: any;
  @Input() status: boolean;
  @Input() index: number;
  @Input() color: string;
  @Input() buttons: string;
  realIndex: number = 1;
  private userid: string;
  isLoading: boolean = false;
  @Input() doNotRouting: boolean;

  constructor(private firebase: FirebaseService) { }

  ngOnInit() {
    this.realIndex = Number(this.index);
    var str: string = String(
      this.item && this.item.avatar && this.item.avatar.thumbnail && this.item.avatar.thumbnail.url
        ? this.item.avatar.thumbnail.url
        : ''
    );
    var n = str.search('www.gravatar.com');
    if (n > -1) {
      this.item.avatar = undefined;
    }
    this.userid = this.item.uid;
  }

  async confirmCancel(): Promise<void> {
    this.isLoading = true;
    const relation = await this.firebase.getRelationShipByPaticipants(this.userid);
    this.isLoading = false;
    if (relation) {
      this.firebase.confirmCancel(relation);
    }
  }

  async confirmAccept(): Promise<void> {
    this.isLoading = true;
    const relation = await this.firebase.getRelationShipByPaticipants(this.userid);
    this.isLoading = false;
    if (relation) {
      this.firebase.confirmAccept(relation, this.userid);
    }
  }

  async confirmReject(): Promise<void> {
    this.isLoading = true;
    const relation = await this.firebase.getRelationShipByPaticipants(this.userid);
    this.isLoading = false;
    if (relation) {
      this.firebase.confirmReject(relation, this.userid);
    }
  }

  parserany(obj: any): any {
    return obj;
  }
}
