import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Logger } from '@app/core';
import { AlertController, IonContent, LoadingController, ToastController } from '@ionic/angular';
import moment from 'moment';
const log = new Logger('SetSurveyComponent');
const limitOptions: number = 10;
const minOptionsLength: number = 2;

@Component({
  selector: 'app-set-survey',
  templateUrl: './set-survey.component.html',
  styleUrls: ['./set-survey.component.scss'],
})
export class SetSurveyComponent implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  public info: any = { title: "Agregar encuesta", button1: "Guardar" };
  private id: string;
  public isLoading: boolean = false;
  //public myForm!: FormGroup;
  public myForm!: FormGroup | any;
  public questionsTypes: any[];
  htmlContent: string;
  resetEditor: number = 0;

  constructor(
    private toastCtrl: ToastController,
    private loadingController: LoadingController,
    private afs: AngularFirestore,
    private formBuilder: FormBuilder,
    private router: Router,
    private aRouter: ActivatedRoute,
    private alertController: AlertController
  ) { }

  ngOnInit(): void { }

  ionViewDidEnter() {
    this.initializeApp();
  }

  async initializeApp() {
    this.createForm();
    this.id = this.aRouter && this.aRouter.snapshot && this.aRouter.snapshot.params && this.aRouter.snapshot.params.id ? this.aRouter.snapshot.params.id : undefined;
    if (this.id) {
      this.info = { title: "Editar encuesta", button1: "Editar" };
      const result = await this.afs.collection(`surveys-and-tests/${this.id}/tests-results`).ref.get();
      if (!(result && result.empty && result.empty === true)) {
        this.presentAlert(this.id);
      } else {
        await this.initData(this.id);
      }
    } else {
      this.addQuestion();
    }
    this.setTest(this.myForm.get('isTest').value)
  }

  private createForm() {
    this.myForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      isTest: [false, Validators.required],
      questions: this.formBuilder.array([])
    });
  }

  newQuestion(): FormGroup {
    return this.formBuilder.group({
      type: ['open', Validators.required],
      text: ['', Validators.required],
      max: [5],
      singleAnswer: [true],
      options: this.formBuilder.array([])
    });
  }

  newOption(): FormGroup {
    return this.formBuilder.group({
      text: ['', Validators.required],
      isOpen: [false, Validators.required],
      value: ['']
    });
  }

  addQuestion() {
    const array: FormArray<any> = this.myForm.get('questions') as FormArray;
    array.push(this.newQuestion());
  }

  addOption(i: number) {
    const array: FormArray<any> = (this.myForm.get('questions') as FormArray).at(i).get('options') as FormArray;
    if (array.length < limitOptions || !array) {
      array.push(this.newOption());
    }
  }

  removeQuestion(i: number) {
    (this.myForm.get('questions') as FormArray).removeAt(i);
  }

  removeOption(i: number, ii: number) {
    ((this.myForm.get('questions') as FormArray).at(i).get('options') as FormArray).removeAt(ii);
  }

  async initData(id: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const data = await this.afs.doc(`surveys-and-tests/${id}`).ref.get();
      const dataForm: any = data.data();
      const questions: any[] = dataForm && dataForm.questions ? Array.from(dataForm.questions) : [];
      const description: string = dataForm && dataForm.description ? String(dataForm.description) : null;
      this.myForm.patchValue({
        title: dataForm && dataForm.title ? String(dataForm.title) : null,
        description: description,
        isTest: dataForm && dataForm.isTest && Boolean(dataForm.isTest) === true ? true : false
      });
      this.htmlContent = description;
      await this.later(100);
      this.myForm.updateValueAndValidity();
      for (const [index, question] of questions.entries()) {
        this.addQuestion();
        await this.later(100);
        const formQuestion: FormGroup = (this.myForm.get('questions') as FormArray).at(index) as FormGroup;
        formQuestion.controls['type'].patchValue(question && question.type ? String(question.type) : 'open');
        formQuestion.controls['text'].patchValue(question && question.text ? String(question.text) : '');
        formQuestion.controls['max'].patchValue(question && question.max ? Number(question.max) : 5);
        formQuestion.controls['singleAnswer'].patchValue(question && question.singleAnswer && question.singleAnswer === true ? true : false);
        await this.later(100);
        formQuestion.controls['type'].updateValueAndValidity();
        formQuestion.controls['text'].updateValueAndValidity();
        formQuestion.controls['max'].updateValueAndValidity();
        formQuestion.controls['singleAnswer'].updateValueAndValidity();
        await this.later(100);
        if (question.type === 'multiple') {
          for (const [index2, option] of question.options.entries()) {
            this.addOption(index);
            await this.later(100);
            const formOption: FormGroup = (formQuestion.get('options') as FormArray).at(index2) as FormGroup;
            formOption.controls['value'].patchValue(option && option.value ? String(option.value) : '');
            formOption.controls['text'].patchValue(option && option.text ? String(option.text) : '');
            formOption.controls['isOpen'].patchValue(option && option.isOpen ? Boolean(option.isOpen) : false);
            await this.later(100);
            formOption.controls['value'].updateValueAndValidity();
            formOption.controls['text'].updateValueAndValidity();
            formOption.controls['isOpen'].updateValueAndValidity();
          }
        }
        await this.later(100);
        this.typeChange(question.type, index);
      }
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async onSubmit() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      await (this.id ? this.updateSurvey(this.id) : this.saveSurvey());
      this.router.navigateByUrl('/surveys-and-tests?action=reload');
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  async saveSurvey() {
    try {
      let data: any = this.myForm.value;
      data.creationDate = moment().toDate();
      data.modificationDate = moment().toDate();
      data.status = 'active';
      await this.afs.collection('surveys-and-tests').add(data);
      this.presentToast('top', "¡Encuesta creada correctamente!");
    } catch (error) {
      log.error(error);
    }
  }

  async updateSurvey(id: string) {
    try {
      let data: any = this.myForm.value;
      data.modificationDate = moment().toDate();
      await this.afs.collection('surveys-and-tests').doc(id).update(data);
      this.presentToast('top', "¡Encuesta actualizada correctamente!");
    } catch (error) {
      log.error(error);
    }
  }

  typeOfTest(event: any) {
    this.setTest(event.detail.checked);
  }

  setTest(isTest: boolean) {
    if (isTest === true) {
      this.questionsTypes = [
        {
          title: 'Selección múltiple',
          value: 'multiple'
        }
      ];
    } else {
      this.questionsTypes = [
        {
          title: 'Abierta',
          value: 'open'
        },
        {
          title: 'Selección múltiple',
          value: 'multiple'
        },
        {
          title: 'Calificación',
          value: 'rate'
        }
      ];
    }
  }

  typeChangeEvent(event: any, i: number) {
    this.typeChange(event && event.detail && event.detail.value ? String(event.detail.value) : null, i);
  }

  typeChange(value: string, i: number) {
    switch (value) {
      case 'multiple':
        this.setMultipleValidator(i);
        break;
      case 'rate':
        this.setRateValidator(i);
        break;
      default:
        this.setOpenValidator(i);
        break;
    }
  }

  async setOpenValidator(i: number) {
    try {
      const group = (this.myForm.get('questions') as FormArray).at(i) as FormGroup;
      await this.removeQuestionValidity(group);
      await this.updateQuestionValidity(group);
    } catch (e) {
      log.error(e);
    }
  }

  async setRateValidator(i: number) {
    try {
      const group = (this.myForm.get('questions') as FormArray).at(i) as FormGroup;
      await this.removeQuestionValidity(group);
      group.controls['max'].setValidators([Validators.required, Validators.min(5), Validators.max(5)]);
      await this.updateQuestionValidity(group);
    } catch (e) {
      log.error(e);
    }
  }

  async setMultipleValidator(i: number) {
    try {
      const group = (this.myForm.get('questions') as FormArray).at(i) as FormGroup;
      await this.removeQuestionValidity(group);
      const array = group.controls['options'] as FormArray;
      if (array.length < minOptionsLength) {
        const currentLength: number = minOptionsLength - array.length;
        for (let ii = 0; ii < currentLength; ii++) {
          this.addOption(i);
          await this.later(100);
        }
      }
      await this.later(100);
      for (let i = 0; i < array.length; i++) {
        if (array.at(i)) {
          const fGroup = array.at(i) as FormGroup;
          fGroup.controls['isOpen'].setValidators([Validators.required]);
          fGroup.controls['text'].setValidators([Validators.required]);
          fGroup.controls['isOpen'].updateValueAndValidity();
          fGroup.controls['text'].updateValueAndValidity();
        }
      }
      await this.updateQuestionValidity(group);
    } catch (e) {
      log.error(e);
    }
  }

  async removeQuestionValidity(group: FormGroup<any>) {
    try {
      group.controls['max'].clearValidators();
      group.controls['options'].clearValidators();
      const array = group.controls['options'] as FormArray;
      await this.later(100);
      for (let i = 0; i < array.length; i++) {
        if (array.at(i)) {
          const fGroup = array.at(i) as FormGroup;
          fGroup.controls['isOpen'].clearValidators();
          fGroup.controls['text'].clearValidators();
          fGroup.controls['isOpen'].updateValueAndValidity();
          fGroup.controls['text'].updateValueAndValidity();
        }
      }
    } catch (e) {
      log.error(e);
    }
  }

  async updateQuestionValidity(group: FormGroup<any>) {
    try {
      group.controls['max'].updateValueAndValidity();
      group.controls['options'].updateValueAndValidity();
      await this.later(100);
    } catch (e) {
      log.error(e);
    }
  }

  later(delay: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, delay);
    });
  }

  async presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 2500,
      position: position,
    });
    await toast.present();
  }

  keyupHandler(event: any) {
    if (event == '' || event == undefined || event == null) {
      this.myForm.get('description').setValue('');
    } else {
      this.myForm.get('description').setValue(event);
    }
  }

  async scrollToBottom() {
    await this.later(200);
    this.content.scrollToBottom(500);
  }

  async presentAlert(id: string) {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: `
      Modificar una encuesta ya respondida puede afectar los resultados y la integridad de los datos. Los participantes basaron sus respuestas en la versión original. Cualquier cambio posterior puede generar confusiones y distorsionar los resultados. Proceda bajo su propio riesgo. 
      Gracias por su comprensión.`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok, de acuerdo',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.initData(id);
          }
        },
        {
          text: 'Cancelar',
          handler: () => {
            this.router.navigate(['/surveys-and-tests']);
          }
        }
      ]
    });
    await alert.present();
  }
}
