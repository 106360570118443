<ion-app>
  <ion-header>
    <ion-toolbar>
      <ion-title>Constantes</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="modalController.dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-item>
      <ion-label position="floating">Versión de la aplicación</ion-label>
      <ion-input type="text" [(ngModel)]="constants.pageVersion"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label>Pagos por conección</ion-label>
      <ion-toggle type="number" [(ngModel)]="constants.payments"></ion-toggle>
    </ion-item>

    <ion-item>
      <ion-label>Conecciones abiertas</ion-label>
      <ion-toggle type="number" [(ngModel)]="constants.openConections"></ion-toggle>
    </ion-item>
  </ion-content>

  <ion-footer>
    <ion-button (click)="applyChanges()" expand="block" fill="clear">
      <ion-icon slot="start" name="create"></ion-icon>
      Aplicar Cambios
    </ion-button>
  </ion-footer>
</ion-app>
