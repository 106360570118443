import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { SurveysAndTestsRoutingModule } from './surveys-and-tests-routing.module';
import { SurveysAndTestsComponent } from './surveys-and-tests.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CardsModule } from '../shared/cards/cards.module';
import { DetailSurveyComponent } from './detail-survey/detail-survey.component';
import { SetSurveyComponent } from './set-survey/set-survey.component';
import { SurveyChartsComponent } from './survey-charts/survey-charts.component';
import { UsersAnswersComponent } from './survey-charts/users-answers/users-answers.component';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { TinyEditorModule } from '@app/shared/components/tiny-editor/tiny-editor.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SurveysAndTestsRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CardsModule,
    AgChartsAngularModule,
    TinyEditorModule
  ],
  declarations: [
    SurveysAndTestsComponent,
    DetailSurveyComponent,
    SetSurveyComponent,
    SurveyChartsComponent,
    UsersAnswersComponent
  ]
})
export class SurveysAndTestsModule { }
