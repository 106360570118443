import { Injectable } from '@angular/core';
import { WP_JSON_URL } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { CredentialsService, Credentials } from '../../../core/authentication/credentials.service';
import { Logger } from '../../../core/logger.service';
const log = new Logger('Login');

@Injectable({
  providedIn: 'root'
})
export class WpServiceV2 {
  constructor(private http: HttpClient, private utilities: UtilitiesService, private credentials: CredentialsService) {}

  public getSession(): Credentials {
    return this.credentials.credentials;
  }

  async getSpecialties(): Promise<any> {
    const url: string = `${WP_JSON_URL}wp-json/wp/v2/speciality?per_page=100`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(url, { observe: 'body', headers: headers })
      .toPromise();
  }

  async getSpecialty(id: number): Promise<any> {
    const url: string = `${WP_JSON_URL}wp-json/wp/v2/speciality/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(url, { observe: 'body', headers: headers })
      .toPromise();
  }

  public deleteMedia(id: number): Promise<HttpResponse<any>> {
    let url: string = `${WP_JSON_URL}wp-json/wp/v2/media/${id}`;
    const session = this.getSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session.token}`
    });
    return this.http
      .delete<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }
}
