import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DisableMedicalSampleComponent } from './disable-medical-sample.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: '',
    component: DisableMedicalSampleComponent,
    data: { title: 'Activar/Desactivar muestras médicas' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DisableMedicalSampleRoutingModule { }
