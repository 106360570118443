<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> {{ id ? 'Actualizar información de alerta' : 'Agregar alerta' }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <form [formGroup]="dataForm" (ngSubmit)="save()">
          <ion-item>
            <ion-label position="stacked">Título <ion-text color="danger">*</ion-text> </ion-label>
            <ion-input type="text" formControlName="title"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Texto <ion-text color="danger">*</ion-text> </ion-label>
            <ion-textarea formControlName="text" rows="3"></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-label position="floating">URL <ion-text color="danger">*</ion-text> </ion-label>
            <ion-input type="url" formControlName="url"></ion-input>
          </ion-item>
          <ion-text color="danger" *ngIf="dataForm.get('url').touched && dataForm.get('url').getError('noValidUrl')">
            <span class="ion-padding" translate>URL no válida</span>
          </ion-text>
          <ion-radio-group formControlName="target">
            <ion-list-header>
              <ion-label>Objetivo:</ion-label>
            </ion-list-header>

            <ion-item>
              <ion-label>Todos</ion-label>
              <ion-radio slot="start" value="allDevices"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Médicos</ion-label>
              <ion-radio slot="start" value="medico"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Representantes médicos</ion-label>
              <ion-radio slot="start" value="representante-medico"></ion-radio>
            </ion-item>
          </ion-radio-group>

          <ion-item>
            <ion-label>Mostrar botón de cancelar</ion-label>
            <ion-toggle formControlName="show_button_cancel" (ionChange)="showButtonCancel($event)"> </ion-toggle>
          </ion-item>
          <ion-item *ngIf="this.dataForm.controls['show_button_cancel'].value === true">
            <ion-label position="stacked">Texto del botón<ion-text color="danger">*</ion-text> </ion-label>
            <ion-input type="text" formControlName="text_button_cancel"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Mostrar botón de recordarme después</ion-label>
            <ion-toggle formControlName="show_button_remember_later" (ionChange)="showButtonRememberLater($event)">
            </ion-toggle>
          </ion-item>
          <ion-item *ngIf="this.dataForm.controls['show_button_remember_later'].value === true">
            <ion-label position="stacked">Texto del botón<ion-text color="danger">*</ion-text> </ion-label>
            <ion-input type="text" formControlName="text_button_remember_later"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Mostrar botón de aceptar</ion-label>
            <ion-toggle formControlName="show_button_accept" (ionChange)="showButtonAccept($event)"></ion-toggle>
          </ion-item>
          <ion-item *ngIf="this.dataForm.controls['show_button_accept'].value === true">
            <ion-label position="stacked">Texto del botón <ion-text color="danger">*</ion-text> </ion-label>
            <ion-input type="text" formControlName="text_button_accept"></ion-input>
          </ion-item>
          <ion-text color="danger">
            <p>* Datos obligatorios</p>
          </ion-text>
          <ion-button type="submit" color="tertiary" expand="block" [disabled]="!dataForm.valid">
            {{ id ? 'Actualizar información' : 'Agregar' }}
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
