<ion-content>
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col size="12">
        <ion-text color="primary" class="ion-text-center">
          <h1>{{ survey?.title }}</h1>
        </ion-text>
        <ion-accordion-group>
          <ion-accordion value="first">
            <ion-item slot="header" color="light">
              <ion-label>Descripción</ion-label>
            </ion-item>
            <div class="ion-no-padding" slot="content">
              <div [innerHTML]="survey?.html"></div>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col *ngFor="let question of questions; let i = index" size="12">
        <ion-card>
          <ion-card-header color="secondary">
            <ion-card-title>{{ (i + 1) + '.- ' + question?.text }}</ion-card-title>
          </ion-card-header>
          <div [ngSwitch]="question?.type">
            <ng-template [ngSwitchCase]="'multiple'">
              <ion-card-content>
                <ion-item lines="none">
                  <ion-label color="medium">Respondidas: {{question?.responsed }} Omitidas: {{question?.notResponsed
                    }}</ion-label>
                </ion-item>
              </ion-card-content>
              <ion-card-content>
                <div class="ov-y">
                  <div>
                    <ag-charts-angular [options]="question?.options" *ngIf="question?.options">
                    </ag-charts-angular>
                  </div>
                  <div *ngFor="let opQ of question?.openQuestions">
                    <h3>
                      <strong>
                        {{ opQ?.text }}
                      </strong>
                    </h3>
                    <div>
                      <ion-list>
                        <ion-item *ngFor="let answer  of opQ?.opAnws">
                          <ion-label class="ion-text-wrap">
                            <div *ngIf="answer?.type === 'uid'">
                              <h2>{{answer?.answer }}</h2>
                            </div>
                            <div *ngIf="!(answer?.type === 'uid')">
                              <h2>{{answer?.answer }}</h2>
                            </div>
                          </ion-label>
                        </ion-item>
                      </ion-list>
                    </div>
                  </div>
                </div>
              </ion-card-content>
            </ng-template>
            <ng-template [ngSwitchCase]="'rate'">
              <ion-card-content>
                <ion-item lines="none">
                  <ion-label color="medium">Respondidas: {{question?.responsed }} Omitidas: {{question?.notResponsed
                    }}</ion-label>
                </ion-item>
              </ion-card-content>
              <!-- <ion-card-content>
                <ion-item lines="none">
                  <ion-label color="medium">Promedio: {{ question?.anwers?.average | number : '1.2-2'}}</ion-label>
                </ion-item>
              </ion-card-content> -->
              <ion-card-content>
                <div class="ov-y">
                  <ag-charts-angular [options]="question?.options" *ngIf="question?.options">
                  </ag-charts-angular>
                </div>
              </ion-card-content>
            </ng-template>
            <ng-template [ngSwitchCase]="'open'">
              <ion-card-content>
                <ion-item lines="none">
                  <ion-label color="medium">Respondidas: {{question?.responsed }} Omitidas: {{question?.notResponsed
                    }}</ion-label>
                </ion-item>
              </ion-card-content>
              <ion-card-content>
                <div class="ov-y">
                  <ion-list>
                    <div *ngFor="let answer of question?.anwers">
                      <ion-item *ngIf="answer?.answer && answer?.answer !== ''">
                        <ion-label class="ion-text-wrap">
                          <div *ngIf="answer?.type === 'uid'">
                            <h2>{{answer?.answer }}</h2>
                          </div>
                          <div *ngIf="!(answer?.type === 'uid')">
                            <h2>{{answer?.answer }}</h2>
                          </div>
                        </ion-label>
                      </ion-item>
                    </div>
                  </ion-list>
                </div>
              </ion-card-content>
            </ng-template>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>