<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">Modificar información de usuario</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <div [ngSwitch]="this.user?.type">
          <ng-template [ngSwitchCase]="'representante-medico'">
            <ion-list lines="full">
              <ion-item>
                <ion-label>Nombre: <strong>{{ user?.name }} {{ user?.lastName1 }} {{ user?.lastName2
                    }}</strong></ion-label>
              </ion-item>
              <ion-item>
                <ion-label>Tipo de usuario: <strong>Representante Médico</strong> </ion-label>
              </ion-item>
              <ion-item>
                <ion-toggle [disabled]="isLoading === true" [checked]="user?.openToHealthProfessionals === true"
                  (ionChange)="onChangeOpenToHealthProfessionals($event)">Abierto a profesionales de la
                  salud:</ion-toggle>
              </ion-item>
              <ion-item>
                <ion-avatar slot="start">
                  <img
                    [src]="this.user?.metadata?.company?.logo || '../../../assets/img/default-representante-medico.jpg'" />
                </ion-avatar>
                <ion-label>
                  <p> <strong> {{this.user?.metadata?.company?.name}}</strong> </p>
                  <p> {{this.user?.metadata?.company?.businessType}} </p>
                  <p> <small> {{this.user?.metadata?.company?.companyWebsite}}</small> </p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ng-template>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>