<ion-list>
  <ion-list-header>
    <ion-label>
      <strong>Opciones de plan</strong>
    </ion-label>
  </ion-list-header>
  <ion-item button lines="none" (click)="goTo()">
    <ion-label>Ver representantes</ion-label>
  </ion-item>
  <ion-item button (click)="update()" lines="none">
    <ion-label>Editar</ion-label>
  </ion-item>
  <ion-item button (click)="activateAlert()" *ngIf="_default !== true" lines="none">
    <ion-label>{{ status === 'active' ? 'Desactivar' : 'Activar' }}</ion-label>
  </ion-item>
  <ion-item button (click)="deleteAlert()" *ngIf="_default !== true" lines="none">
    <ion-label color="danger">Eliminar</ion-label>
  </ion-item>
</ion-list>
