import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { ClickBannerLogsRoutingModule } from './click-banner-logs-routing.module';
import { ClickBannerLogsComponent } from './click-banner-logs.component';
import { SearchModule } from '../search/search.module';
import { CardsModule } from '../shared/cards/cards.module';
import { FormsModule } from '@angular/forms';
import { ClickBannerDetailComponent } from './click-banner-detail/click-banner-detail.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ClickBannerLogsRoutingModule,
    HttpClientModule,
    SearchModule,
    CardsModule,
    FormsModule
  ],
  declarations: [ClickBannerLogsComponent, ClickBannerDetailComponent]
})
export class ClickBannerLogsModule {}
