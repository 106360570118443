import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { AlertController, LoadingController, PopoverController } from '@ionic/angular';
import { AuthAccountServicesService } from '../services/auth-account-services.service';
const log = new Logger('UsersActionsComponent');

@Component({
  selector: 'app-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.scss']
})
export class UserOptionsComponent implements OnInit {
  public uid: string;
  public isLoading: boolean = false;
  public user: any = {};

  constructor(
    private alertController: AlertController,
    private firebaseService: FirebaseService,
    private popoverController: PopoverController,
    private loadingCtrl: LoadingController,
    private utilities: UtilitiesService,
    private authAccountServices: AuthAccountServicesService
  ) {}

  ngOnInit() {
    this.initializeApp();
  }

  private async initializeApp() {
    this.isLoading = true;
    try {
      this.user = await this.firebaseService.callFunctionFB('getAuthUser', { uid: this.uid });
    } catch (e) {
      log.error(log);
    }
    this.isLoading = false;
  }

  public async activateAlert(disabled: boolean) {
    try {
      const alert = await this.alertController.create({
        header: `¿${disabled === false ? 'Desactivar' : 'Activar'} Usuario?`,
        message: `¿Está seguro de ${disabled === false ? 'desactivar' : 'activar'} este usuario: ${this.uid}?`,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: `${disabled === false ? 'Desactivar' : 'Activar'} `,
            handler: () => {
              this.activate(!disabled);
            }
          }
        ]
      });
      await alert.present();
    } catch (e) {
      log.error(e);
    }
  }

  private async activate(disabled: boolean) {
    this.isLoading = true;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      const user = await this.firebaseService.callFunctionFB('updateAuthUser', {
        uid: this.uid,
        data: {
          disabled: disabled
        }
      });
      this.popoverController.dismiss({ uid: this.uid, disabled: user.disabled }, 'disabled');
      this.utilities.toast(
        `Usuario ${user && user.disabled && user.disabled === false ? 'activado' : 'desactivado'} correctamente.`
      );
    } catch (error) {
      log.error(error);
    }
    loading.dismiss();
    this.isLoading = false;
  }

  public deleteAlert() {
    this.authAccountServices.deleteAlert(this.uid, this.popoverController);
  }

  public sendEmailVerification() {
    this.authAccountServices.sendEmailVerification(this.user, this.popoverController);
  }

  public sendResetPassword() {
    this.authAccountServices.sendResetPassword(this.user, this.popoverController);
  }
}
