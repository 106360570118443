import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { AuthAccountServicesService } from '../services/auth-account-services.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { UserInformationComponent } from '../user-information/user-information.component';
const log = new Logger('UserDetailComponent');
const validateEmail = (email: string) => {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  public uid: string;
  public isLoading: boolean = false;
  public user: any = {};
  public disabled: boolean;

  constructor(
    private modalCtrl: ModalController,
    private firebaseService: FirebaseService,
    private utilities: UtilitiesService,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
    private authAccountServices: AuthAccountServicesService
  ) { }

  ngOnInit() {
    this.initializeApp();
  }

  private async initializeApp() {
    this.isLoading = true;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      await this.getUser();
    } catch (e) {
      log.error(log);
    }
    this.isLoading = false;
    loading.dismiss();
  }

  private async getUser() {
    try {
      this.user = await this.firebaseService.callFunctionFB('getAuthUser', { uid: this.uid });
      this.disabled = !this.user.disabled;
    } catch (e) {
      log.error(log);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss(this.user, 'refresh');
  }

  async goInformation() {
    try {
      const popover = await this.modalCtrl.create({
        component: UserInformationComponent,
        componentProps: { uid: this.uid }
      });
      await popover.present();
    } catch (e) {
      log.error(e);
    }
  }

  public async enabled(event: any) {
    if (!event.detail.checked !== this.user.disabled) {
      this.isLoading = true;
      const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
      loading.present();
      try {
        const disabled: boolean = !event.detail.checked;
        await this.firebaseService.callFunctionFB('updateAuthUser', {
          uid: this.uid,
          data: {
            disabled: disabled
          }
        });
        await this.getUser();
        this.utilities.toast(`Usuario ${disabled === false ? 'activado' : 'desactivado'} correctamente.`);
      } catch (e) {
        log.error(log);
      }
      this.isLoading = false;
      loading.dismiss();
    }
  }

  async changeEmailAlert() {
    const alert = await this.alertController.create({
      header: 'Actualizar Email',
      subHeader: this.user.email,
      inputs: [
        {
          label: 'Nuevo Email',
          name: 'email',
          type: 'email',
          placeholder: 'correo@conectimed.com'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'danger'
        },
        {
          text: 'Actualizar',
          handler: (data: any) => {
            if (data && data.email) {
              const email: string = data.email;
              const valid: boolean = validateEmail(email);
              if (valid === true && email !== null && email !== undefined && email !== '') {
                this.changeEmail(email);
              } else {
                this.utilities.toast('El formato del Email no es válido.');
                return false;
              }
            } else {
              this.utilities.toast('El campo de Email esta vacío.');
              return false;
            }
            return true;
          }
        }
      ]
    });
    await alert.present();
  }

  async changePasswordAlert() {
    const alert = await this.alertController.create({
      header: 'Actualizar Password',

      inputs: [
        {
          label: 'Nueva contraseña',
          name: 'password1',
          type: 'password',
          placeholder: 'Nueva contraseña'
        },
        {
          label: 'Repetir contraseña',
          name: 'password2',
          type: 'password',
          placeholder: 'Repetir contraseña'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'danger'
        },
        {
          text: 'Actualizar',
          handler: (data: any) => {
            if (data && data.password1 && data.password2) {
              const password1: string = data.password1;
              const password2: string = data.password2;
              if (password1 !== '' && password2 !== '' && password1 === password2) {
                if (password1.length >= 6) {
                  this.changePassword(password1);
                } else {
                  this.utilities.toast('La nueva contraseña debe tener al menos 6 caracteres.');
                  return false;
                }
              } else {
                this.utilities.toast('Los campos de "Nueva contraseña" y "Repetir contraseña" no coinciden.');
                return false;
              }
            } else {
              this.utilities.toast('El campo de "Nueva contraseña" ó "Repetir contraseña" esta vacío.');
              return false;
            }
            return true;
          }
        }
      ]
    });
    await alert.present();
  }

  public async changeEmail(email: string) {
    this.isLoading = true;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      await this.firebaseService.callFunctionFB('updateAuthUser', {
        uid: this.uid,
        data: {
          email: email
        }
      });
      await this.getUser();
      this.utilities.toast(`Email actualizado correctamente.`);
    } catch (e) {
      log.error(log);
    }
    this.isLoading = false;
    loading.dismiss();
  }

  public async changePassword(password: string) {
    this.isLoading = true;
    const loading = await this.loadingCtrl.create({ message: 'Cargando...' });
    loading.present();
    try {
      await this.firebaseService.callFunctionFB('updateAuthUser', {
        uid: this.uid,
        data: {
          password: password
        }
      });
      await this.getUser();
      this.utilities.toast(`Contraseña actualizada correctamente.`);
    } catch (e) {
      log.error(log);
    }
    this.isLoading = false;
    loading.dismiss();
  }

  public deleteAlert() {
    this.authAccountServices.deleteAlert(this.uid, this.modalCtrl);
  }

  public sendEmailVerification() {
    this.authAccountServices.sendEmailVerification(this.user);
  }

  public sendResetPassword() {
    this.authAccountServices.sendResetPassword(this.user);
  }
}
