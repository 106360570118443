import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClickMeetingComponent } from './click-meeting.component';
import { ConferenceComponent } from './conference/conference.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: '', component: ClickMeetingComponent, data: { title: 'ClickMeeting reportes' } },
  { path: ':id', component: ConferenceComponent, data: { title: 'ClickMeeting detail' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ClickMeetingRoutingModule { }
