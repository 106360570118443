<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Mensaje de Chats</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <h2>Envíos en progreso</h2>

        <ion-card *ngFor="let item of list_in_progress" color="light">
          <ion-card-header color="secondary">
            <ion-card-title>
              <p *ngIf="item?.rep | async as rep">
                {{ parserany(rep)?.name + ' ' + parserany(rep)?.lastName1 + ' ' + parserany(rep)?.lastName2 }}
              </p>
            </ion-card-title>
            <ion-card-subtitle> Fecha: {{ item?.date }} </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content *ngFor="let _message of item?.message">
            <div #content [innerHTML]="_message?.trustHtml"></div>
            <strong>Etiqueta de analytics:</strong> <i> {{ _message?.label }}</i>
          </ion-card-content>
          <ion-card-content>
            <div *ngIf="item?.percent | async as percent">
              <ion-progress-bar color="success" [value]="percent"></ion-progress-bar>
            </div>
          </ion-card-content>
        </ion-card>

        <div *ngIf="!(list_in_progress && list_in_progress[0])">
          Sin resultados
        </div>

        <h2>Envíos completados</h2>

        <ion-card *ngFor="let item of list_complete" color="light">
          <ion-card-header color="tertiary">
            <ion-card-title>
              <p *ngIf="item?.rep | async as rep">
                {{ parserany(rep)?.name + ' ' + parserany(rep)?.lastName1 + ' ' + parserany(rep)?.lastName2 }}
              </p>
            </ion-card-title>
            <ion-card-subtitle> Fecha: {{ item?.date }} </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content *ngFor="let _message of item?.message">
            <div #content [innerHTML]="_message?.trustHtml"></div>
            <strong>Etiqueta de analytics:</strong> <i> {{ _message?.label }}</i>
          </ion-card-content>
        </ion-card>

        <div *ngIf="!(list_complete && list_complete[0])">
          Sin resultados
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="downloadFormat()" color="secondary">
        <ion-icon slot="start" name="download"></ion-icon>
        Descargar formato
      </ion-button>
      <ion-button [routerDirection]="'forward'" [routerLink]="['create-chat']" color="secondary">
        <ion-icon slot="start" name="add"></ion-icon>
        Crear envío
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>