import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Routes, RouterModule } from '@angular/router';
import { AssignContactsComponent } from './assign-contacts.component';
import { SharedModule } from '../shared/shared.module';
import { UserDetailViewModule } from '../shared/user-detail-view/user-detail-view.module';
import { LocationComponent } from './location/location.component';
import { SpecialitesComponent } from './specialites/specialites.component';

const routes: Routes = [{ path: '', component: AssignContactsComponent }];

@NgModule({
  declarations: [AssignContactsComponent, LocationComponent, SpecialitesComponent],
  exports: [LocationComponent, SpecialitesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    SharedModule,
    UserDetailViewModule
  ]
})
export class AssignContactsModule {}
