<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/surveys-and-tests"></ion-back-button>
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span>{{ survey?.title }}</span></ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-title></ion-title>
    <ion-buttons slot="start">
      <ion-button fill="clear" *ngIf="!survey?.isDeleteAccountForm === true" (click)="deleteSurvey()"><ion-icon
          name="trash"></ion-icon></ion-button>
      <ion-button fill="clear" [routerDirection]="'forward'" [routerLink]="'/surveys-and-tests/set/' + id"><ion-icon
          name="create"></ion-icon></ion-button>
      <ion-button fill="clear" (click)="detailSurvey()"><ion-icon name="eye"></ion-icon></ion-button>
      <ion-button fill="clear" *ngIf="!survey?.isDeleteAccountForm === true" (click)="getLink()"><ion-icon
          name="link-outline"></ion-icon></ion-button>
      <ion-button *ngIf="!survey?.isDeleteAccountForm === true" (click)="getSharedLink()" shape="round">
        <ion-icon slot="icon-only" name="share-social-outline"></ion-icon>
      </ion-button>
      <ion-button fill="clear" (click)="download()" [disabled]="isLoading === true"><ion-icon
          name="document-text-outline"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col size="12">
        <ion-text color="primary" class="ion-text-center">
          <h1>{{ survey?.title }}</h1>
        </ion-text>
        <ion-accordion-group>
          <ion-accordion value="first">
            <ion-item slot="header" color="light">
              <ion-label>Descripción</ion-label>
            </ion-item>
            <div class="ion-no-padding" slot="content">
              <div [innerHTML]="survey?.html"></div>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!(isLoading === true)">
      <ion-col size-xs="12" size-sm="12" size-lg="6" size-xl="6">
        <ag-charts-angular style="height: 100%;" [options]="specialties"></ag-charts-angular>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-lg="6" size-xl="6">
        <ag-charts-angular style="height: 100%;" [options]="locations"></ag-charts-angular>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col *ngFor="let question of questions; let i = index" size="12">
        <ion-card>
          <ion-card-header color="secondary">
            <ion-card-title>{{ (i + 1) + '.- ' + question?.text }}</ion-card-title>
          </ion-card-header>
          <div [ngSwitch]="question?.type">
            <ng-template [ngSwitchCase]="'multiple'">
              <ion-card-content>
                <ion-item lines="none">
                  <ion-label color="medium">Respondidas: {{question?.responsed }} Omitidas: {{question?.notResponsed
                    }}</ion-label>
                </ion-item>
              </ion-card-content>
              <ion-card-content>
                <div class="ov-y">
                  <div>
                    <ag-charts-angular [options]="question?.options" *ngIf="question?.options">
                    </ag-charts-angular>
                  </div>
                  <div *ngFor="let opQ of question?.openQuestions">
                    <h3>
                      <strong>
                        {{ opQ?.text }}
                      </strong>
                    </h3>
                    <div>
                      <ion-list>
                        <ion-item *ngFor="let answer of opQ?.opAnws">
                          <ion-label class="ion-text-wrap">
                            <div *ngIf="answer?.type === 'uid'">
                              <h2><strong>{{answer?.answer }}</strong></h2>
                              <ion-text color="medium">
                                <p>{{ answer?.info?.name }}<br><small>{{ answer?.info?.uid }}</small></p>
                              </ion-text>
                            </div>
                            <div *ngIf="!(answer?.type === 'uid')">
                              <h2><strong>{{ answer?.answer }}</strong></h2>
                              <ion-text color="medium">
                                <p *ngIf="answer?.info?.email">{{ answer?.info?.name }}<br><small>{{ answer?.info?.email
                                    }}</small></p>
                                <div *ngIf="!(answer?.info?.email)">
                                  <p *ngIf="answer?.info?.name">{{ answer?.info?.name }}</p>
                                  <p *ngIf="!(answer?.info?.name)">Anónimo</p>
                                </div>
                              </ion-text>
                            </div>
                          </ion-label>
                        </ion-item>
                      </ion-list>
                    </div>
                  </div>
                </div>
              </ion-card-content>
            </ng-template>
            <ng-template [ngSwitchCase]="'rate'">
              <ion-card-content>
                <ion-item lines="none">
                  <ion-label color="medium">Respondidas: {{question?.responsed }} Omitidas: {{question?.notResponsed
                    }}</ion-label>
                </ion-item>
              </ion-card-content>
              <!-- <ion-card-content>
                <ion-item lines="none">
                  <ion-label color="medium">Promedio: {{ question?.anwers?.average | number : '1.2-2'}}</ion-label>
                </ion-item>
              </ion-card-content> -->
              <ion-card-content>
                <div class="ov-y">
                  <ag-charts-angular [options]="question?.options" *ngIf="question?.options">
                  </ag-charts-angular>
                </div>
              </ion-card-content>
            </ng-template>
            <ng-template [ngSwitchCase]="'open'">
              <ion-card-content>
                <ion-item lines="none">
                  <ion-label color="medium">Respondidas: {{question?.responsed }} Omitidas: {{question?.notResponsed
                    }}</ion-label>
                </ion-item>
              </ion-card-content>
              <ion-card-content>
                <div class="ov-y">
                  <ion-list>
                    <div *ngFor="let answer of question?.anwers">
                      <ion-item *ngIf="answer?.answer && answer?.answer !== ''">
                        <ion-label class="ion-text-wrap">
                          <div *ngIf="answer?.type === 'uid'">
                            <h2><strong>{{answer?.answer }}</strong></h2>
                            <ion-text color="medium">
                              <p>{{ answer?.info?.name }}<br><small>{{ answer?.info?.uid }}</small></p>
                            </ion-text>
                          </div>
                          <div *ngIf="!(answer?.type === 'uid')">
                            <h2><strong>{{answer?.answer }}</strong></h2>
                            <ion-text color="medium">
                              <p *ngIf="answer?.info?.email">{{ answer?.info?.name }}<br><small>{{ answer?.info?.email
                                  }}</small></p>
                              <div *ngIf="!(answer?.info?.email)">
                                <p *ngIf="answer?.info?.name">{{ answer?.info?.name }}</p>
                                <p *ngIf="!(answer?.info?.name)">Anónimo</p>
                              </div>
                            </ion-text>
                          </div>
                        </ion-label>
                      </ion-item>
                    </div>
                  </ion-list>
                </div>
              </ion-card-content>
            </ng-template>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-text color="primary" class="ion-text-center">
          <h1>Encuestados</h1>
          <ion-text color="medium">
            <p>Totales : {{ users?.length }}</p>
          </ion-text>
        </ion-text>
        <ion-list>
          <ion-item *ngFor="let user of users; let i = index">
            <ion-label class="ion-text-wrap">
              <div *ngIf="user?.type === 'uid'">
                <ion-text color="dark">
                  <p> {{ i + 1 + '.- '}}{{ user?.info?.name }}<br><small>{{ user?.info?.uid }}</small></p>
                </ion-text>
              </div>
              <div *ngIf="!(user?.type === 'uid')">
                <ion-text color="dark">
                  <p *ngIf="user?.info?.email">{{ i + 1 + '.- '}} {{ user?.info?.name && user?.info?.name !== '' ?
                    user.info.name : '(Sin nombre)' }}<br><small>{{ user?.info?.email
                      }}</small></p>
                  <div *ngIf="!(user?.info?.email)">
                    <p *ngIf="user?.info?.name">{{ i + 1 + '.- '}} {{ user?.info?.name && user?.info?.name !== '' ?
                      user.info.name : '(Sin nombre)' }}</p>
                    <p *ngIf="!(user?.info?.name)">{{ i + 1 + '.- '}} Anónimo</p>
                  </div>
                </ion-text>
              </div>
              <ion-text color="medium">
                <small><i>{{ user?.dateStr }}</i> </small>
              </ion-text>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="details(user)" color="primary">
                Detalles
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>