<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Asignar contactos</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="step === 1">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <h2>1.- Selecciona a los representantes</h2>
        <ion-button (click)="selectUsersFunction('representante-medico')" expand="block">
          <ion-icon slot="start" name="person"></ion-icon>
          Selccionar represetatantes
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item *ngFor="let item of mainArray; let i = index">
            <ion-label>
              <app-user-card [index]="i + 1" [item]="item" [doNotRouting]="true"> </app-user-card>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="confirmDelete(i)" color="danger">
                <ion-icon slot="icon-only" name="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer *ngIf="step === 1">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <ion-button (click)="step1()" expand="block" [disabled]="!(mainArray && mainArray[0])">
          Continuar
          <ion-icon slot="end" name="arrow-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<ion-content *ngIf="step === 2">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <h2>2.- Selecciona el tipo de asignación</h2>
        <ion-list>
          <ion-radio-group [(ngModel)]="asignacionType">
            <ion-item>
              <ion-label>Especialidad/Estado</ion-label>
              <ion-radio slot="start" value="especialidad-estado"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>Email</ion-label>
              <ion-radio slot="start" value="email"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <ion-button (click)="step2()" expand="block">
          Continuar
          <ion-icon slot="end" name="arrow-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content *ngIf="step === 3 && asignacionType === 'especialidad-estado'">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <h2>3.- Selecciona a los médicos</h2>
        <ion-button (click)="selectUsersFunction('medico')" expand="block">
          <ion-icon slot="start" name="person"></ion-icon>
          Selccionar médicos
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item *ngFor="let item of mainArray; let i = index">
            <ion-label>
              <app-user-card [index]="i + 1" [item]="item" [doNotRouting]="true"> </app-user-card>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="confirmDelete(i)" color="danger">
                <ion-icon slot="icon-only" name="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer *ngIf="step === 3 && asignacionType === 'especialidad-estado'">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <ion-button (click)="step3()" expand="block" [disabled]="!(mainArray && mainArray[0])">
          Continuar
          <ion-icon slot="end" name="arrow-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<ion-content *ngIf="step === 3 && asignacionType === 'email'">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <h2>3.- Ingresa los emails</h2>
        <ion-item>
          <ion-label position="stacked">Emails</ion-label>
          <ion-textarea rows="12" [(ngModel)]="stringEmails"></ion-textarea>
        </ion-item>
        <ion-button expand="block" (click)="step3()" [disabled]="!stringEmails">
          Continuar
          <ion-icon slot="end" name="arrow-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content *ngIf="step === 4">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <h2>4.- Selecciona el tipo de operación</h2>
        <ion-list>
          <ion-radio-group [(ngModel)]="operationType">
            <ion-item>
              <ion-label>Crear relaciones</ion-label>
              <ion-radio slot="start" value="create"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>Eliminar relaciones</ion-label>
              <ion-radio slot="start" value="delete"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <ion-button (click)="step4()" expand="block">
          Continuar
          <ion-icon slot="end" name="arrow-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content *ngIf="step === 5">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <h2>Terminado</h2>
        Se {{ results?.type === 'create' ? 'crearon' : 'eliminaron' }} {{ results?.successful }} conexiónes de
        {{ results?.operations }} posibles.
        <ul>
          <li *ngFor="let reps of operations">
            <h3>{{ reps?.data?.name }} {{ reps?.data?.lastName1 }} {{ reps?.data?.lastName2 }}</h3>
            <ul>
              <li *ngFor="let meds of reps?.medicos">
                <p>
                  {{ meds?.data?.name }} {{ meds?.data?.lastName1 }} {{ meds?.data?.lastName2 }}
                  <ion-chip
                    [color]="meds?.success === true ? 'success' : results?.type === 'create' ? 'warning' : 'danger'"
                  >
                    <ion-icon [name]="results?.type === 'create' ? 'person-add' : 'trash'"></ion-icon>
                    <ion-icon [name]="meds?.success === true ? 'checkmark' : 'close'"></ion-icon>
                  </ion-chip>
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <br />
        <ion-button (click)="reset()" shape="round" expand="block">
          <ion-icon slot="start" name="refresh"></ion-icon>
          Reiniciar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
