<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Asignar contactos</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="step == 1">
  <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  <ion-grid fixed>
    <ion-row fixed>
      <ion-col size="12">
        <h3>1.- Selecciona a los representantes</h3>
      </ion-col>
    </ion-row>
    <ion-row fixed>
      <ion-col size="12">
        <ion-button fill="solid" expand="block" color="secondary" (click)="filter3()" [disabled]="isLoading">
          <ion-icon name="briefcase" slot="start"></ion-icon>
          Empresa / Laboratorio
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-searchbar
          placeholder="Búsqueda general"
          [debounce]="750"
          (ionChange)="getStringSearch($event)"
          (ionCancel)="clearSearch()"
          (ionClear)="clearSearch()"
        ></ion-searchbar>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData2; let i = index"
          (click)="removeFilter2(i)"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close"></ion-icon>
        </ion-button>
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData1; let i = index"
          (click)="removeFilter1(i)"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close"></ion-icon>
        </ion-button>
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData3; let i = index"
          (click)="removeFilter3(i)"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="posts">
      <ion-col size="12" size-md="6" size-lg="4" *ngFor="let item of members; let i = index">
        <ion-card>
          <app-user-card
            [color]="item.color"
            [index]="i + 1"
            [item]="item"
            (click)="selectUser(item.id); item.color = item.color == 'primary' ? '' : 'primary'"
          >
          </app-user-card>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Cargando..."> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<ion-footer *ngIf="step == 1">
  <ion-toolbar>
    <ion-row>
      <ion-col>
        <ion-button expand="full" color="tertiary" [disabled]="!representantes[0] || isLoading" (click)="step1()">
          Continuar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>

<ion-content *ngIf="step == 2">
  <ion-grid fixed>
    <ion-row fixed>
      <ion-col size="12">
        <h3>2.- Asignar por</h3>
        <ion-list>
          <ion-radio-group value="specialty" [(ngModel)]="allocationType">
            <ion-item>
              <ion-label>Especialidad</ion-label>
              <ion-radio slot="start" value="specialty"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>Zona geográfica</ion-label>
              <ion-radio slot="start" value="geographical-area"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>Email</ion-label>
              <ion-radio slot="start" value="email"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </ion-col>
      <ion-col>
        <ion-button
          expand="full"
          color="tertiary"
          [disabled]="!representantes[0] || !allocationType || isLoading"
          (click)="step2()"
        >
          Continuar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content *ngIf="step == 3">
  <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  <ion-grid fixed>
    <ion-row fixed>
      <ion-col size="12">
        <div *ngIf="!(allocationType === 'email')">
          <h3>3.- Seleciona a los médicos para continuar.</h3>
        </div>
        <ion-button
          fill="solid"
          *ngIf="allocationType === 'geographical-area'"
          expand="block"
          color="secondary"
          (click)="filter2()"
          [disabled]="isLoading"
        >
          <ion-icon name="pin" slot="start"></ion-icon>
          Selecionar zona geográfica
        </ion-button>
        <ion-button
          fill="solid"
          *ngIf="allocationType === 'specialty'"
          expand="block"
          color="secondary"
          (click)="filter1()"
          [disabled]="isLoading"
        >
          <ion-icon name="school" slot="start"></ion-icon>
          Selecionar especialidad
        </ion-button>
        <div *ngIf="allocationType === 'email'">
          <h3>3.- Ingresa los emails de los usuarios para comenzar a generar las conexiónes</h3>
          <form (ngSubmit)="onSubmit()" [formGroup]="myForm" novalidate>
            <ion-list>
              <ion-item lines="inset">
                <ion-label color="medium" position="stacked" translate>Emails de los usuarios</ion-label>
                <ion-textarea
                  formControlName="ids"
                  autocomplete="ids"
                  rows="15"
                  placeholder="Los Emails deben estar separados comas o por saltos de línea."
                ></ion-textarea>
              </ion-item>
              <ion-text color="danger" [hidden]="myForm.controls.ids.valid || myForm.controls.ids.untouched">
                <span class="ion-padding" translate>Campo emails de los usuarios requerido</span>
              </ion-text>
            </ion-list>
            <div class="ion-padding-horizontal">
              <ion-button
                type="submit"
                [disabled]="myForm.invalid || isLoading"
                color="tertiary"
                expand="block"
                translate
              >
                Continuar
              </ion-button>
            </div>
          </form>
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!(allocationType === 'email')">
      <ion-col size="12">
        <ion-searchbar
          placeholder="Búsqueda general"
          [debounce]="750"
          (ionChange)="getStringSearch2($event)"
          (ionCancel)="clearSearch2()"
          (ionClear)="clearSearch2()"
        ></ion-searchbar>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="newMembers !== true">
      <ion-col size="12">
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData2; let i = index"
          (click)="removeFilter2(i)"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close"></ion-icon>
        </ion-button>
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData1; let i = index"
          (click)="removeFilter1(i)"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!(allocationType === 'email')">
      <ion-col size="12" size-md="6" size-lg="4" *ngFor="let item of members2; let i = index">
        <ion-card>
          <app-user-card
            [color]="item.color"
            [index]="i + 1"
            [item]="item"
            (click)="selectUser2(item.id); item.color = item.color == 'primary' ? '' : 'primary'"
          >
          </app-user-card>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData2($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Cargando..."> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<ion-footer *ngIf="step == 3 && !(allocationType === 'email')">
  <ion-toolbar>
    <ion-row>
      <ion-col>
        <ion-button expand="full" color="tertiary" [disabled]="!medicos[0] || isLoading" (click)="step3()">
          Continuar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>

<ion-content *ngIf="step == 4">
  <ion-grid fixed>
    <ion-row fixed>
      <ion-col size="12">
        <h3>4.- Confirmar para continuar</h3>
        <p>Se generarán {{ medicos.length * representantes.length }} conexiónes, ¿Desea continuar?</p>
        <ion-button expand="full" color="tertiary" [disabled]="!medicos[0] || isLoading" (click)="initConn()">
          Continuar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content *ngIf="step == 5">
  <ion-grid fixed>
    <ion-row fixed>
      <ion-col size="12">
        <h3>Resumen</h3>
        <p>Conexiónes creadas: {{ connCreated }}</p>
        <p>Conexiónes no creadas: {{ connNoCreated }}</p>

        <div *ngIf="notAcceptedUsers?.length > 0">
          <ion-text color="primary">
            <h4>No se pudo establecer conexión con éstos médicos:</h4>
          </ion-text>
          <ion-text color="primary" *ngFor="let doctor of notAcceptedUsers">
            <p>
              Médico: {{ doctor.name }} Email: {{ doctor.email }} ID: {{ doctor.id }} Especialidad(s):
              {{ doctor.especialidad1 }} {{ doctor.especialidad2 }} Estado(s): {{ doctor.estado1 }} {{ doctor.estado2 }}
            </p>
          </ion-text>
        </div>
        <ion-button expand="full" color="tertiary" [disabled]="isLoading" [routerDirection]="'root'" routerLink="/home">
          Terminar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
