import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertisingRoutingModule } from './advertising-routing.module';
import { AdvertisingComponent } from './advertising.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NewAdvertisingComponent } from './new-advertising/new-advertising.component';
import { SearchTagsComponent } from './search-tags/search-tags.component';
import { SearchProductComponent } from './search-product/search-product.component';

@NgModule({
  declarations: [AdvertisingComponent, NewAdvertisingComponent, SearchTagsComponent, SearchProductComponent],
  imports: [CommonModule, AdvertisingRoutingModule, IonicModule, FormsModule]
})
export class AdvertisingModule {}
