import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Logger } from '@app/core';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { LoadingController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { CreateChatComponent } from './create-chat/create-chat.component';
import { UtilitiesService } from '@app/shared/services/utilities.service';
const log = new Logger('ChatComponent');

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  public list_in_progress: any[] = [];
  public list_complete: any[] = [];

  constructor(
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private firebase: FirebaseService,
    private storage: AngularFireStorage,
    private utilities: UtilitiesService,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.afs
      .collection('chats-batch', q => q.where('status', '==', 'pending').orderBy('date', 'desc'))
      .snapshotChanges()
      .subscribe(value => {
        this.list_in_progress = value.map(item => {
          let data: any = item.payload.doc.data();
          data.date = moment(data.date.toDate()).format('YYYY-MM-DD HH:mm');
          data.rep = this.firebase.getUserData(data.repid);
          data.message = Array.from(data.messages).map((item: any) => {
            return { trustHtml: this.utilities.trustHtml(item.message), label: item.label };
          });
          data.percent = this.afs
            .collection(`chats-batch/${item.payload.doc.id}/sections`, q => q.where('status', '==', 'complete'))
            .valueChanges()
            .pipe(
              map(value => {
                const data: any = value[0] || {};
                return value.length / data.total_pages;
              })
            );
          return data;
        });
      });

    this.afs
      .collection('chats-batch', q =>
        q
          .where('status', '==', 'complete')
          .orderBy('date', 'desc')
          .limit(50)
      )
      .snapshotChanges()
      .subscribe(snap => {
        this.list_complete = snap.map(item => {
          let data: any = item.payload.doc.data();
          data.id = item.payload.doc.id;
          data.date = moment(data.date.toDate()).format('YYYY-MM-DD HH:mm');
          data.rep = this.firebase.getUserData(data.repid);
          data.message = Array.from(data.messages).map((item: any) => {
            return { trustHtml: this.utilities.trustHtml(item.message), label: item.label };
          });
          return data;
        });
      });
  }

  async createChat() {
    try {
      const modal = await this.modalCtrl.create({
        component: CreateChatComponent
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  async downloadFormat() {
    try {
      const downloadURL = await this.storage
        .ref('chats-batch/format/formato-chats.xlsx')
        .getDownloadURL()
        .toPromise();
      await this.openInNewTab(downloadURL);

      console.log('=== downloadURL ===', downloadURL);
    } catch (error) {
      log.error(error);
    }
  }

  private openInNewTab(href: string) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: href
    }).click();
  }

  async getChats(id: string) {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const respDoc = await this.afs
        .collection('chats-batch')
        .doc(id)
        .ref.get();
      const sender: string = respDoc.get('repid');
      const messages: any = respDoc.get('messages');
      const message: string = messages[0]['message'];
      const metric_label: string = messages[0]['label'];
      let counter: number = 0;
      console.log('*** message ***', message);
      console.log('*** label ***', metric_label);
      console.log('*** sender ***', sender);

      const resp = await this.afs
        .collection('chats-batch')
        .doc(id)
        .collection('sections')
        .ref.get();
      const data: any[] = resp.docs.map(e => e.data());
      let allData: any[] = [];
      for (let item of data) {
        allData = allData.concat(item.data);
      }
      let identifiers: any[] = [];
      for (let item of allData) {
        identifiers.push(`${item}${sender}`);
        identifiers.push(`${sender}${item}`);
      }

      for (let id of identifiers) {
        const resp = await this.afs
          .collection('chats')
          .ref.where('identifier', '==', id)
          .get();
        if (!resp.empty === true) {
          const _message = await resp.docs[0].ref
            .collection('messages')
            .where('sender', '==', sender)
            .where('metric_label', '==', metric_label)
            .get();
          for (let _doc of _message.docs) {
            counter++;
            const _delete = await _doc.ref.delete();
            console.log(`*** DELETED: ${_doc.id}`);
          }
        }
      }
      console.log('*** Total ***', counter);
    } catch (error) {
      console.error(error);
    }
    loadingOverlay.dismiss();
  }

  parserany(obj: any): any {
    return obj;
  }
}
