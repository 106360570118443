import { Component, OnInit } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { Logger } from '@app/core';
const log = new Logger('ActivateMemberComponent');

@Component({
  selector: 'app-activate-member',
  templateUrl: './activate-member.component.html',
  styleUrls: ['./activate-member.component.scss']
})
export class ActivateMemberComponent implements OnInit {
  public isLoading = false;
  public users: any[] = [];
  public perPage = 500;
  public mainCollection = 'users';
  public orderBy = 'nameStr';
  public orderByDirection: any = 'asc';
  public forward: boolean = false;
  public back: boolean = false;
  private startAfter: any;
  private endBefore: any;
  private startAt: any;
  private startAtCurrent: any;
  public arrayDocs: any[] = [];

  constructor(private afs: AngularFirestore) {}

  ngOnInit() {
    this.initData();
  }

  async loadData() {
    try {
      await this.initData();
    } catch (error) {
      log.error(error);
    }
  }

  async initData(direction?: 'forward' | 'back' | 'current') {
    this.isLoading = true;
    try {
      let collRef: CollectionReference = this.afs.collection(this.mainCollection).ref;
      let query: Query;
      query = collRef.where('type', 'in', ['medico', 'representante-medico']).orderBy('nameStr', 'asc');
      if (this.startAfter && direction === 'forward') {
        query = query.startAfter(this.startAfter);
      } else if (this.startAt && this.endBefore && direction === 'back') {
        query = query.startAt(this.startAt).endBefore(this.endBefore);
      } else if (direction === 'current') {
        if (this.endBefore) {
          query = query.startAt(this.endBefore);
        }
      }
      const snap = await query.limit(this.perPage).get();
      this.startAfter = snap.empty == false ? snap.docs[snap.docs.length - 1] : undefined;
      this.endBefore = snap.docs.length > 0 ? snap.docs[0] : undefined;
      if (this.startAfter) {
        query = collRef.where('type', 'in', ['medico', 'representante-medico']).orderBy('nameStr', 'asc');
        if (this.startAfter) {
          query = query.startAfter(this.startAfter);
        }
        const snap = await query.limit(this.perPage).get();
        this.forward = !snap.empty;
      } else {
        this.forward = false;
      }
      let back: any = {};
      if (this.endBefore) {
        back = await this.afs
          .collection(this.mainCollection)
          .ref.orderBy(this.orderBy, this.orderByDirection === 'asc' ? 'desc' : 'asc')
          .startAfter(this.endBefore)
          .limit(this.perPage)
          .get();
      } else {
        back.empty = true;
      }
      this.back = !back.empty;
      if (this.back == true) {
        this.startAt = back.docs[back.docs.length - 1];
      }
      this.arrayDocs = snap.docs.map(element => {
        const data: any = element.data();
        const id: string = element.id;
        return { id, ...data };
      });
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async activate() {
    this.isLoading = true;
    let inActive = [];
    try {
      inActive = this.arrayDocs
        .filter(element => {
          if (!(element && element.status && element.status === 'active')) {
            return element;
          }
        })
        .map(element => {
          return element.uid;
        });
      let batch = this.afs.firestore.batch();
      for (let i = 0; i < inActive.length; i++) {
        var ref = this.afs.collection('users').doc(inActive[i]).ref;
        batch.update(ref, { status: 'active' });
      }
      await batch.commit();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
    this.initData('current');
  }

  async inactivate() {
    this.isLoading = true;
    let inActive = [];
    try {
      inActive = this.arrayDocs
        .filter(element => {
          if (element && element.status && element.status === 'active') {
            return element;
          }
        })
        .map(element => {
          return element.uid;
        });
      let batch = this.afs.firestore.batch();
      for (let i = 0; i < inActive.length; i++) {
        var ref = this.afs.collection('users').doc(inActive[i]).ref;
        batch.update(ref, { status: 'inactive' });
      }
      await batch.commit();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
    this.initData('current');
  }
}
