import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Logger } from '@app/core';
const log = new Logger('App');

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {
  constructor(private aStorage: AngularFireStorage) { }

  async uploadFile(
    collection: string,
    document: string,
    reference: string,
    fileName: string,
    image: string | Blob | File
  ): Promise<{ downloadURL: string; name: string; contentType: string } | undefined> {
    try {
      const storageRef = this.aStorage.storage.ref();
      const imageRef = storageRef.child(`${collection}/${document}/${reference}/${fileName}`);
      const file: any = {};
      let response;
      if (typeof image === 'string') {
        response = await imageRef.putString(image, 'data_url');
      } else {
        response = await imageRef.put(image);
      }
      file['downloadURL'] = await imageRef.getDownloadURL();
      return {
        downloadURL: file['downloadURL'],
        name: response.metadata.name,
        contentType: response.metadata.contentType
      };
    } catch (err) {
      log.error(err);
      return undefined;
    }
  }

  async deleteFile(collection: string, document: string, reference: string, fileName: string) {
    const ref = `${collection}/${document}/${reference}/${fileName}`;
    const storageRef = this.aStorage.storage.ref();
    const desertRef = storageRef.child(ref);
    return desertRef.delete();
  }
}
