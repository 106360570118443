<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Exportar productos de WordPress a Cloud Firebase</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-title slot="end">
      <ion-text>
        <p><strong>Total de productos en WP: </strong> {{ xWpTotal }}</p>
      </ion-text>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="imporProducts()" [disabled]="isLoading === true">
        <ion-icon slot="start" name="add"> </ion-icon>
        Importar
      </ion-button>
      <ion-button (click)="delete()" [disabled]="isLoading === true">
        <ion-icon slot="start" name="add"> </ion-icon>
        Borrar
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-progress-bar *ngIf="isLoading === true" type="indeterminate"></ion-progress-bar>
</ion-header>

<ion-content color="light">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <ion-button
          [disabled]="isLoading"
          [color]="item === page ? 'primary' : 'light'"
          *ngFor="let item of pages"
          (click)="goToPage(item)"
          size="small"
        >
          {{ item }}
        </ion-button>
        <ion-list>
          <ion-item *ngFor="let item of products; let i = index" [disabled]="isLoading">
            <ion-thumbnail slot="start">
              <img [src]="item?.image ? item?.image : 'assets/ngx-rocket-logo.png'" />
            </ion-thumbnail>
            <ion-label>
              <p>{{ i + 1 }}.- <strong>Título: </strong>{{ item?.title }}</p>
              <p><strong>wp_id: </strong>{{ item?.wp_id }}</p>
              <p><strong>uid: </strong>{{ item?.uid }}</p>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
