import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PreregistroRoutingModule } from './preregistro-routing.module';
import { PreregistroComponent } from './preregistro.component';

@NgModule({
  imports: [CommonModule, IonicModule, PreregistroRoutingModule],
  declarations: [PreregistroComponent]
})
export class PreregistroModule { }
