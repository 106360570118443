import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { LoadingController } from '@ionic/angular';
import { WP_JSON_URL } from '../../environments/environment';
import * as moment from 'moment';

const PER_PAGE: number = 100;
interface Post {
  type: 'post' | 'video' | 'curso_o_congreso';
  title: string;
  is_outstanding: boolean;
  content: string;
  excerpt: string;
  image: string;
  publication_date: Date;
  status: string;
  category: DocumentReference;
  specialties: DocumentReference[];
  tags: DocumentReference[];
  fecha_de_inicio: Date;
  fecha_fin: Date;
  video_url: string;
  search: string[];
  slug: string;
  nameStr: string;
  date: Date;
  modified: Date;
  author_uid: string;
}

interface Action {
  id: string;
  data: Post | any;
  path: string;
}
@Component({
  selector: 'app-import-posts',
  templateUrl: './import-posts.component.html',
  styleUrls: ['./import-posts.component.scss']
})
export class ImportPostsComponent implements OnInit {
  private currentPage: number;
  private totalPages: number;
  private total: number;
  public posts: Action[] = [];
  public _attachments: any[] = [];
  public attachments: any[] = [];
  private currentDate: any;
  public isLoading: boolean = false;
  public count: number = 0;
  public video_categories: Action[] = [];
  private raw: any[] = [];

  constructor(private http: HttpClient, private afs: AngularFirestore, private loadingController: LoadingController) { }

  ngOnInit() {
    //this.currentDate = firebase..FieldValue.serverTimestamp();
    this.currentDate = moment().toDate();
  }

  // Get Data from WordPress
  public async getBanners(page?: number) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.currentPage = page ? page : 1;
      const URL: string = `${WP_JSON_URL}wp-json/wp/v2/banner?_embed&order_by=date&per_page=${PER_PAGE}&page=${this.currentPage}`;

      const resp = await this.http.get(URL, { headers: headers, observe: 'response' }).toPromise();
      this.totalPages = Number(resp.headers.get('x-wp-totalpages'));
      this.total = Number(resp.headers.get('x-wp-total'));
      const body: any = resp.body;
      const array: any[] = Array.from(body).map((e: any) => {
        let data: any = {
          date: e && e.date ? moment(e.date).toDate() : moment().toDate(),
          image:
            e &&
              e._embedded &&
              e._embedded['wp:featuredmedia'] &&
              e._embedded['wp:featuredmedia'][0] &&
              e._embedded['wp:featuredmedia'][0].source_url
              ? e._embedded['wp:featuredmedia'][0].source_url
              : '',
          modified: e && e.modified ? moment(e.modified).toDate() : moment().toDate(),
          order: this.count++,
          status: e && e.status && e.status === 'publish' ? 'active' : 'inactive',
          title: e && e.title && e.title.rendered ? String(e.title.rendered) : '',
          typeS:
            e && e.busqueda_multimedia && e.busqueda_multimedia !== '' ? 'search' : e && e.artculo ? 'modal' : 'iab',
          valueS:
            e && e.busqueda_multimedia && e.busqueda_multimedia !== ''
              ? e.busqueda_multimedia
              : e && e.artculo
                ? e.artculo
                : e.url
        };
        data.nameStr = '';
        data.publication_date = data.date;
        return { id: e.id, data: data, path: 'banners' };
      });
      this.posts = this.posts.concat(array);
      console.log('====== //Banners// ======', `====== page ${this.currentPage} of ${this.totalPages} ======`);
    } catch (error) {
      console.error(error);
    }
  }

  public async getVideos(page?: number) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.currentPage = page ? page : 1;
      const URL: string = `${WP_JSON_URL}wp-json/wp/v2/video?_embed&order_by=date&per_page=${PER_PAGE}&page=${this.currentPage}`;

      const resp = await this.http.get(URL, { headers: headers, observe: 'response' }).toPromise();
      this.totalPages = Number(resp.headers.get('x-wp-totalpages'));
      this.total = Number(resp.headers.get('x-wp-total'));
      const body: any = resp.body;
      this.raw = this.raw.concat(body);
      const array: Action[] = Array.from(body).map((e: any) => {
        let categories: DocumentReference[] = [];
        let specialties: DocumentReference[] = [];
        let tags: DocumentReference[] = [];

        if (e && e.categories) {
          for (let id of Array.from(e.categories)) {
            const ref: DocumentReference = this.afs.doc(`categories_video/${id}`).ref;
            categories.push(ref);
          }
        } else {
          categories = [];
        }

        if (e && e.speciality) {
          for (let id of Array.from(e.speciality)) {
            const ref: DocumentReference = this.afs.doc(`specialties/${id}`).ref;
            specialties.push(ref);
          }
        } else {
          specialties = [];
        }

        if (e && e.tags) {
          for (let id of Array.from(e.tags)) {
            const ref: DocumentReference = this.afs.doc(`tags/${id}`).ref;
            tags.push(ref);
          }
        } else {
          tags = [];
        }

        const data: Post = {
          category: null,
          excerpt: '',
          fecha_de_inicio: e && e.fecha_de_inicio ? moment(e.fecha_de_inicio).toDate() : moment().toDate(),
          fecha_fin: e && e.fecha_fin ? moment(e.fecha_fin).toDate() : moment().toDate(),
          is_outstanding: e && e.sticky && e.sticky ? e.sticky : false,

          publication_date: null,
          nameStr: '',
          search: [],
          specialties: [],
          tags: [],
          type: 'video',

          content: e && e.content && e.content.rendered ? String(e.content.rendered) : '',
          date: e && e.date ? moment(e.date).toDate() : moment().toDate(),
          image:
            e &&
              e._embedded &&
              e._embedded['wp:featuredmedia'] &&
              e._embedded['wp:featuredmedia'][0] &&
              e._embedded['wp:featuredmedia'][0].source_url
              ? e._embedded['wp:featuredmedia'][0].source_url
              : '',
          modified: e && e.modified ? moment(e.modified).toDate() : moment().toDate(),
          slug: '',
          status: e && e.status && e.status === 'publish' ? 'active' : 'inactive',
          title: e && e.title && e.title.rendered ? String(e.title.rendered) : '',
          video_url: e && e.video_url ? String(e.video_url) : '',
          author_uid: e && e.author_uid ? e.author_uid : ''
        };

        data.nameStr = '';
        data.publication_date = data.date;
        data.search = [];
        data.category = categories && categories[0] ? categories[0] : null;
        data.specialties = specialties;
        data.tags = tags;
        return { id: e.id, data: data, path: 'posts' };
      });
      this.posts = this.posts.concat(array);
      console.log('====== //Videos// ======', `====== page ${this.currentPage} of ${this.totalPages} ======`);
    } catch (error) {
      console.error(error);
    }
  }

  public async getPosts(page?: number) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.currentPage = page ? page : 1;
      const URL: string = `${WP_JSON_URL}wp-json/wp/v2/posts?_embed&order_by=date&per_page=${PER_PAGE}&page=${this.currentPage}`;

      const resp = await this.http.get(URL, { headers: headers, observe: 'response' }).toPromise();
      this.totalPages = Number(resp.headers.get('x-wp-totalpages'));
      this.total = Number(resp.headers.get('x-wp-total'));
      const body: any = resp.body;
      const array: Action[] = Array.from(body).map((e: any) => {
        let categories: DocumentReference[] = [];
        let specialties: DocumentReference[] = [];
        let tags: DocumentReference[] = [];
        let type: any;

        if (e && e.categories) {
          for (let id of Array.from(e.categories)) {
            let ref: DocumentReference;

            if (Number(id) === 50) {
              this.raw.push(e);
              ref = this.afs.doc(`categories_video/${id}`).ref;
              type = 'video';
            } else {
              ref = this.afs.doc(`categories/${id}`).ref;
              type = 'post';
            }

            categories.push(ref);
          }
        } else {
          categories = [];
        }

        if (e && e.speciality) {
          for (let id of Array.from(e.speciality)) {
            const ref: DocumentReference = this.afs.doc(`specialties/${id}`).ref;
            specialties.push(ref);
          }
        } else {
          specialties = [];
        }

        if (e && e.tags) {
          for (let id of Array.from(e.tags)) {
            const ref: DocumentReference = this.afs.doc(`tags/${id}`).ref;
            tags.push(ref);
          }
        } else {
          tags = [];
        }

        const data: Post = {
          video_url: '',

          category: null,
          excerpt: '',
          fecha_de_inicio: null,
          fecha_fin: null,
          is_outstanding: e && e.sticky && e.sticky ? e.sticky : false,

          publication_date: null,
          nameStr: '',
          search: [],
          specialties: [],
          tags: [],
          type: type,

          content: e && e.content && e.content.rendered ? String(e.content.rendered) : '',
          date: e && e.date ? moment(e.date).toDate() : moment().toDate(),
          image:
            e &&
              e._embedded &&
              e._embedded['wp:featuredmedia'] &&
              e._embedded['wp:featuredmedia'][0] &&
              e._embedded['wp:featuredmedia'][0].source_url
              ? e._embedded['wp:featuredmedia'][0].source_url
              : '',
          modified: e && e.modified ? moment(e.modified).toDate() : moment().toDate(),
          slug: '',
          status: e && e.status && e.status === 'publish' ? 'active' : 'inactive',
          title: e && e.title && e.title.rendered ? String(e.title.rendered) : '',
          author_uid: e && e.author_uid ? e.author_uid : ''
        };

        data.nameStr = '';
        data.publication_date = data.date;
        data.search = [];
        data.category = categories && categories[0] ? categories[0] : null;
        data.specialties = specialties;
        data.tags = tags;

        const index: number = Array.from(e.categories)
          .map(e => Number(e))
          .indexOf(1);

        if (index > -1) {
          this._attachments.push({ id: e.id, wp_attachment: e._links['wp:attachment'], path: 'posts' });
        }
        return { id: e.id, data: data, path: 'posts' };
      });
      this.posts = this.posts.concat(array);
      console.log('====== //Posts// ======', `====== page ${this.currentPage} of ${this.totalPages} ======`);
    } catch (error) {
      console.error(error);
    }
  }

  public async getTags(page?: number) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.currentPage = page ? page : 1;
      const URL: string = `${WP_JSON_URL}wp-json/wp/v2/tags?order_by=date&per_page=${PER_PAGE}&page=${this.currentPage}`;
      const resp = await this.http.get(URL, { headers: headers, observe: 'response' }).toPromise();
      this.totalPages = Number(resp.headers.get('x-wp-totalpages'));
      this.total = Number(resp.headers.get('x-wp-total'));
      const body: any = resp.body;
      const array: any[] = Array.from(body).map((e: any) => {
        let data: any = {
          date: this.currentDate,
          modified: this.currentDate,
          slug: '',
          status: 'active',
          title: e && e.name ? String(e.name) : ''
        };
        data.nameStr = '';
        data.search = [];
        return { id: e.id, data: data, path: 'tags' };
      });
      this.posts = this.posts.concat(array);
      console.log('====== //Tags// ======', `====== page ${this.currentPage} of ${this.totalPages} ======`);
    } catch (error) {
      console.error(error);
    }
  }

  public async getCategories(page?: number) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.currentPage = page ? page : 1;
      const URL: string = `${WP_JSON_URL}wp-json/wp/v2/categories?order_by=date&per_page=${PER_PAGE}&page=${this.currentPage}`;
      const resp = await this.http.get(URL, { headers: headers, observe: 'response' }).toPromise();
      this.totalPages = Number(resp.headers.get('x-wp-totalpages'));
      this.total = Number(resp.headers.get('x-wp-total'));
      const body: any = resp.body;
      const array: any[] = Array.from(body).map((e: any) => {
        let data: any = {
          date: this.currentDate,
          modified: this.currentDate,
          slug: '',
          status: 'active',
          name: e && e.name ? String(e.name) : ''
        };
        data.nameStr = '';
        data.search = [];
        return { id: e.id, data: data, path: 'categories' };
      });
      this.posts = this.posts.concat(array);
      console.log('====== //Categories// ======', `====== page ${this.currentPage} of ${this.totalPages} ======`);
    } catch (error) {
      console.error(error);
    }
  }

  public async getSpecialties(page?: number) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.currentPage = page ? page : 1;
      const URL: string = `${WP_JSON_URL}wp-json/wp/v2/speciality?order_by=date&per_page=${PER_PAGE}&page=${this.currentPage}`;
      const resp = await this.http.get(URL, { headers: headers, observe: 'response' }).toPromise();
      this.totalPages = Number(resp.headers.get('x-wp-totalpages'));
      this.total = Number(resp.headers.get('x-wp-total'));
      const body: any = resp.body;
      const array: any[] = Array.from(body).map((e: any) => {
        let data: any = {
          date: this.currentDate,
          modified: this.currentDate,
          slug: '',
          status: 'active',
          name: e && e.name ? String(e.name) : ''
        };
        data.nameStr = '';
        data.search = [];
        return { id: e.id, data: data, path: 'specialties' };
      });
      this.posts = this.posts.concat(array);
      console.log('====== //Specialties// ======', `====== page ${this.currentPage} of ${this.totalPages} ======`);
    } catch (error) {
      console.error(error);
    }
  }

  public async getCourseOrCongress(page?: number) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.currentPage = page ? page : 1;
      const URL: string = `${WP_JSON_URL}wp-json/wp/v2/curso_o_congreso?_embed&order_by=date&per_page=${PER_PAGE}&page=${this.currentPage}`;

      const resp = await this.http.get(URL, { headers: headers, observe: 'response' }).toPromise();
      this.totalPages = Number(resp.headers.get('x-wp-totalpages'));
      this.total = Number(resp.headers.get('x-wp-total'));
      const body: any = resp.body;
      const array: Action[] = Array.from(body).map((e: any) => {
        let tags: DocumentReference[] = [];

        if (e && e.tags) {
          for (let id of Array.from(e.tags)) {
            const ref: DocumentReference = this.afs.doc(`tags/${id}`).ref;
            tags.push(ref);
          }
        } else {
          tags = [];
        }

        const data: Post = {
          category: null,
          excerpt: '',
          fecha_de_inicio: e && e.fecha_de_inicio ? moment(e.fecha_de_inicio).toDate() : moment().toDate(),
          fecha_fin: e && e.fecha_fin ? moment(e.fecha_fin).toDate() : moment().toDate(),
          is_outstanding: e && e.sticky && e.sticky ? e.sticky : false,

          publication_date: null,
          nameStr: '',
          search: [],
          specialties: [],
          tags: [],
          type: 'curso_o_congreso',

          author_uid: '',
          video_url: '',

          content: e && e.content && e.content.rendered ? String(e.content.rendered) : '',
          date: e && e.date ? moment(e.date).toDate() : moment().toDate(),
          image:
            e &&
              e._embedded &&
              e._embedded['wp:featuredmedia'] &&
              e._embedded['wp:featuredmedia'][0] &&
              e._embedded['wp:featuredmedia'][0].source_url
              ? e._embedded['wp:featuredmedia'][0].source_url
              : '',
          modified: e && e.modified ? moment(e.modified).toDate() : moment().toDate(),
          slug: '',
          status: e && e.status && e.status === 'publish' ? 'active' : 'inactive',
          title: e && e.title && e.title.rendered ? String(e.title.rendered) : ''
        };

        data.nameStr = '';
        data.publication_date = data.date;
        data.search = [];
        data.tags = tags;

        return { id: e.id, data: data, path: 'posts' };
      });
      this.posts = this.posts.concat(array);
      console.log(
        '====== //Cursos y congresos// ======',
        `====== page ${this.currentPage} of ${this.totalPages} ======`
      );
    } catch (error) {
      console.error(error);
    }
  }

  // Get Data from Firebase
  public async getBannersFire() {
    try {
      const resp = await this.afs.collection('banners').ref.get();
      this.posts = resp.docs.map(e => {
        return { id: e.id, data: {}, path: 'banners' };
      });
      console.log('====== //Banners// ======');
    } catch (error) {
      console.error(error);
    }
  }

  public async getPostsFire() {
    try {
      const resp = await this.afs.collection('posts').ref.get();
      this.posts = resp.docs.map(e => {
        return { id: e.id, data: {}, path: 'posts' };
      });
      console.log('====== //Posts// ======');
    } catch (error) {
      console.error(error);
    }
  }

  public async getTagsFire() {
    try {
      const resp = await this.afs.collection('tags').ref.get();
      this.posts = resp.docs.map(e => {
        return { id: e.id, data: {}, path: 'tags' };
      });
      console.log('====== //Tags// ======');
    } catch (error) {
      console.error(error);
    }
  }

  public async getCategoriesFire() {
    try {
      const resp = await this.afs.collection('categories').ref.get();
      this.posts = resp.docs.map(e => {
        return { id: e.id, data: {}, path: 'categories' };
      });
      console.log('====== //Categories// ======');
    } catch (error) {
      console.error(error);
    }
  }

  public async getCategoriesVideosFire() {
    try {
      const resp = await this.afs.collection('categories_video').ref.get();
      this.posts = resp.docs.map(e => {
        return { id: e.id, data: {}, path: 'categories_video' };
      });
      console.log('====== //Categories Video// ======');
    } catch (error) {
      console.error(error);
    }
  }

  public async getSpecialtiesFire() {
    try {
      const resp = await this.afs.collection('specialties').ref.get();
      this.posts = resp.docs.map(e => {
        return { id: e.id, data: {}, path: 'specialties' };
      });
      console.log('====== //Specialties// ======');
    } catch (error) {
      console.error(error);
    }
  }

  // Set Data to Firebase
  async initPosts() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getPosts();
    if (this.total > PER_PAGE) {
      do {
        await this.getPosts(this.currentPage + 1);
      } while (this.currentPage < this.totalPages);
    }
    await this.getVideoCategories();
    await this.setDoc(this.video_categories, 'set', 'Categorías de Video');
    await this.setDoc(this.posts, 'set', 'Posts');
    await this.map_attachments();
    await this.setDoc(this.attachments, 'update', 'Posts attachments');
    this.currentPage = undefined;
    this.totalPages = undefined;
    this.posts = [];
    this.video_categories = [];
    this.raw = [];
    this._attachments = [];
    this.count = 0;
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initCategories() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getCategories();
    if (this.total > PER_PAGE) {
      do {
        await this.getCategories(this.currentPage + 1);
      } while (this.currentPage < this.totalPages);
    }
    await this.setDoc(this.posts, 'set', 'Categories');
    this.currentPage = undefined;
    this.totalPages = undefined;
    this.posts = [];
    this.video_categories = [];
    this.raw = [];
    this.count = 0;
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initEspecialties() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getSpecialties();
    if (this.total > PER_PAGE) {
      do {
        await this.getSpecialties(this.currentPage + 1);
      } while (this.currentPage < this.totalPages);
    }
    await this.setDoc(this.posts, 'set', 'Specialties');
    this.currentPage = undefined;
    this.totalPages = undefined;
    this.posts = [];
    this.video_categories = [];
    this.raw = [];
    this.count = 0;
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initTags() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getTags();
    if (this.total > PER_PAGE) {
      do {
        await this.getTags(this.currentPage + 1);
      } while (this.currentPage < this.totalPages);
    }
    await this.setDoc(this.posts, 'set', 'Tags');
    this.currentPage = undefined;
    this.totalPages = undefined;
    this.posts = [];
    this.video_categories = [];
    this.raw = [];
    this.count = 0;
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initBanners() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getBanners();
    if (this.total > PER_PAGE) {
      do {
        await this.getBanners(this.currentPage + 1);
      } while (this.currentPage < this.totalPages);
    }
    await this.setDoc(this.posts, 'set', 'Banners');
    this.currentPage = undefined;
    this.totalPages = undefined;
    this.posts = [];
    this.video_categories = [];
    this.raw = [];
    this.count = 0;
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initVideos() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getVideos();
    if (this.total > PER_PAGE) {
      do {
        await this.getVideos(this.currentPage + 1);
      } while (this.currentPage < this.totalPages);
    }
    await this.getVideoCategories();
    await this.setDoc(this.video_categories, 'set', 'Categorías de Video');
    await this.setDoc(this.posts, 'set', 'Videos');
    console.log(this.posts);
    this.currentPage = undefined;
    this.totalPages = undefined;
    this.posts = [];
    this.video_categories = [];
    this.raw = [];
    this.count = 0;
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initCourseOrCongress() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getCourseOrCongress();
    if (this.total > PER_PAGE) {
      do {
        await this.getCourseOrCongress(this.currentPage + 1);
      } while (this.currentPage < this.totalPages);
    }
    await this.setDoc(this.posts, 'set', 'Cursos y Congresos');
    this.currentPage = undefined;
    this.totalPages = undefined;
    this.posts = [];
    this.video_categories = [];
    this.raw = [];
    this.count = 0;
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  // Delete Data to Firebase

  async initBannersFire() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getBannersFire();
    await this.setDoc(this.posts, 'delete', 'Banners');
    this.posts = [];
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initPostsFire() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getPostsFire();
    await this.setDoc(this.posts, 'delete', 'Posts');
    this.posts = [];
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initTagsFire() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getTagsFire();
    await this.setDoc(this.posts, 'delete', 'Tags');
    this.posts = [];
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initCategoriesFire() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getCategoriesFire();
    await this.setDoc(this.posts, 'delete', 'Categories');
    this.posts = [];
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initCategoriesVideosFire() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getCategoriesVideosFire();
    await this.setDoc(this.posts, 'delete', 'Categories Videos');
    this.posts = [];
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async initEspecialtiesFire() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    await this.getSpecialtiesFire();
    await this.setDoc(this.posts, 'delete', 'Specialties');
    this.posts = [];
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  // Generic Set Data Handler
  async setDoc(array: any[], action: 'update' | 'set' | 'delete', title: string) {
    try {
      console.log(`'set posts from WordPress (${title})'`);
      let newArray: any[] = [];
      const originalArray = array.map(e => {
        let data: any = e;
        const ref: DocumentReference = this.afs.doc(`${e.path}/${e.id}`).ref;
        data.ref = ref;
        return data;
      });
      const LONG_ARRAY = 250;
      for (let i = 0; i < originalArray.length; i += LONG_ARRAY) {
        let piece = originalArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }
      let count1: number = 1;
      for (const array of newArray) {
        let PromiseArray: Promise<any>[] = [];
        for (const element of array) {
          let _doAction;
          if (action === 'update') {
            _doAction = element.ref
              .update(element.data)
              .then(() => true)
              .catch(() => false);
          } else if (action === 'delete') {
            _doAction = element.ref
              .delete()
              .then(() => true)
              .catch(() => false);
          } else {
            _doAction = element.ref
              .set(element.data)
              .then(() => true)
              .catch((e: any) => {
                console.error(e);
                return false;
              });
          }
          PromiseArray.push(_doAction);
          console.log(`${count1}.- ${element}`);
        }
        const resp = await Promise.all(PromiseArray);
        console.log(resp);
        await this.later(500);
        console.log(`${count1++}.- batch commit`);
      }
      console.log(`********* batch commit end *********`);
    } catch (error) {
      console.error(error);
    }
  }

  //attachments
  async map_attachments() {
    let attachmentsArray: { id: string; href: string; path: string }[] = [];

    for (const attachments of this._attachments) {
      for (const attachment of attachments.wp_attachment) {
        attachmentsArray.push({
          id: attachments && attachments.id ? attachments.id : undefined,
          href: attachment && attachment.href ? attachment.href : undefined,
          path: attachments && attachments.path ? attachments.path : undefined
        });
      }
    }

    attachmentsArray = attachmentsArray.filter(e => {
      if (e.href && e.id && e.path) {
        return true;
      }
      return undefined;
    });

    for (const attachment of attachmentsArray) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });

      let resp: any = [];

      try {
        resp = await this.http.get(attachment.href, { headers: headers, observe: 'body' }).toPromise();
      } catch (e) {
        console.error(e);
      }
      const array: any[] = Array.from(resp).map((e: any) => e.source_url);
      this.attachments.push({ id: attachment.id, data: { attachments: array }, path: 'posts' });
    }
  }

  //Other
  later(delay: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, delay);
    });
  }

  async getVideoCategories() {
    let cats: any = {};
    for (let post of this.raw) {
      if (post && post.categories && post.categories[0]) {
        cats[post.categories[0]] = post.categories[0];
      }
    }
    let video_categories = Object.keys(cats);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let categories: any[] = [];

    for (const cat of video_categories) {
      const URL: string = `${WP_JSON_URL}wp-json/wp/v2/categories/${cat}`;
      const resp = await this.http.get(URL, { headers: headers, observe: 'body' }).toPromise();
      categories.push(resp);
    }

    const array: any[] = categories.map((e: any) => {
      let data: any = {
        date: this.currentDate,
        modified: this.currentDate,
        slug: '',
        status: 'active',
        name: e && e.name ? String(e.name) : ''
      };
      data.nameStr = '';
      data.search = [];
      return { id: e.id, data: data, path: 'categories_video' };
    });
    video_categories = [];
    this.video_categories = array;
  }
}
