import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { FirebaseService, Message } from '@app/shared/services/firebase.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { SearchComponent } from '../../search/search.component';
import * as moment from 'moment';
import { Router } from '@angular/router';
const log = new Logger('CreateChatComponent');

@Component({
  selector: 'app-create-chat',
  templateUrl: './create-chat.component.html',
  styleUrls: ['./create-chat.component.scss']
})
export class CreateChatComponent implements OnInit {
  public isLoading: boolean = false;
  public members: any[] = [];
  messages: Message[];
  public stringIDs: string;
  private maxMedSize: number = 500;
  rep: any;
  doctorsID: any[] = [];
  public file: File;
  public percent: number = 0;
  public visible: boolean = false;

  constructor(
    private loadingController: LoadingController,
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private utilities: UtilitiesService,
    private firebase: FirebaseService,
    private storage: AngularFireStorage,
    private alertC: AlertController,
    private router: Router
  ) { }

  ngOnInit() { }

  public detail() { }

  public async chat(userid: string, sendHere?: boolean): Promise<void> {
    await this.firebase.createChat(userid, CreateChatComponent, sendHere, this.messages, this.rep.id);
  }

  async mapMedics() {
    this.isLoading === true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      let ids: string[] = await this.utilities.getIDS(this.stringIDs);
      if (ids.length > this.maxMedSize) {
        alert(
          `Has superado el límite máximo de operaciones, unicamente se actualizarán los primeros ${this.maxMedSize} registros.`
        );
        ids = ids.splice(0, this.maxMedSize);
      }
      let mainArray: any[] = [];
      for (let id of ids) {
        try {
          const response = await this.afs
            .collection('users')
            .doc(id)
            .ref.get();
          if (response.exists === true) {
            const data: any = response.data();
            const id = response.id;
            mainArray.push({ id, ...data });
          }
        } catch (error) {
          log.error(error);
        }
      }
      if (mainArray && mainArray.length > 0) {
        this.doctorsID = Array.from(mainArray);
        this.chatGroup();
      } else {
        this.utilities.toast("No se econtraron coincidencias, revisa los ID'S.", 'Alerta');
      }
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading === false;
  }

  async messageText() {
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    this.isLoading = true;
    try {
      let pormises: Promise<any>[] = [];
      for (const id of this.doctorsID.map(e => e.id)) {
        pormises.push(this.chat(id, true));
      }
      await Promise.all(pormises);
      this.utilities.toast('Mensaje enviado correctamente.', 'Correcto');
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async selectUsersFunction() {
    try {
      const modal = await this.modalCtrl.create({
        component: SearchComponent,
        componentProps: {
          viewType: 'modal',
          searchMemberType: 'representante-medico',
          multiselection: false
        }
      });
      modal.onDidDismiss().then(element => {
        if (element && element.data && element.data.users && element.data.users[0]) {
          this.getRepInfo(element.data.users[0]);
        }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  async getRepInfo(user: any) {
    this.isLoading = true;
    try {
      this.rep = user;
      if (user && user['filter-meta-data'] && user['filter-meta-data'][0]) {
        const idCompany = user['filter-meta-data'][0];
        const resp = await this.afs.doc(`companies/${idCompany}`).ref.get();
        if (resp.exists === true) {
          this.rep.company = resp.data();
        }
      }
    } catch (e) {
      log.error(e);
    }
    this.isLoading = false;
  }

  selectFile(event: any) {
    this.isLoading = true;
    try {
      this.file = event.target.files[0];
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }

  async doUpload() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      if (this.file) {
        const ref = this.storage.ref(`chats-batch/${moment().valueOf()}-${this.file.name}`);
        const updload = await ref.put(this.file);
        const fullPath = updload.ref.fullPath;
        const resp = await this.afs.collection('chats-batch').add({
          date: moment().toDate(),
          fullPath,
          messages: this.messages,
          repid: this.rep.id,
          status: 'pending'
        });
        this.successAlert();
        this.closeModal();
      }
    } catch (error) {
      console.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  closeModal() {
    this.router.navigate(['/chat']);
  }

  async successAlert(): Promise<void> {
    const alert = await this.alertC.create({
      header: '¡Correcto!',
      message: `Se a iniciado el proceso de envío de chats.`,
      buttons: [
        {
          text: 'Ok'
        }
      ]
    });
    return await alert.present();
  }

  chatGroup(message?: any) {
    message = { data: message };
    this.visible = false;
    if (message.data && message.data.messages) {
      this.messages = Array.from(message.data.messages);
      if (
        message.data &&
        message.data.analytics &&
        message.data.analytics.label &&
        message.data.analytics.send === true
      ) {
        this.messages.map(element => {
          const data: any = element;
          data.label = String(message.data.analytics.label);
          return data;
        });
      }
    }
  }
}
