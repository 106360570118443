import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostsInfoRoutingModule } from './posts-info-routing.module';
import { PostsInfoComponent } from './posts-info.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PostsInfoComponent],
  imports: [CommonModule, PostsInfoRoutingModule, IonicModule, FormsModule]
})
export class PostsInfoModule {}
