import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core/logger.service';
import { ModalController } from '@ionic/angular';
const log = new Logger('UsersAnswersComponent');

@Component({
  selector: 'app-users-answers',
  templateUrl: './users-answers.component.html',
  styleUrls: ['./users-answers.component.scss'],
})
export class UsersAnswersComponent implements OnInit {
  user: any;
  survey: any;
  answers: any[] = [];

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.answers = Array.from(this.survey.questions).map((question: any, index: number) => {
      let response: any;
      switch (question.type) {
        case 'multiple':
          response = Array.from(question.options).map((item: any) => {
            return { text: item.text, selected: false, isOpen: item.isOpen }
          });
          if (question && question.singleAnswer === false) {
            for (let [_index, answer,] of Array.from(this.user.results[index].answers).entries()) {
              if ((answer as any).answer === true) {
                response[_index].selected = true;
                response[_index].openAnswer = this.user.results[index].answers[_index].openAnswer;
              }
            }
          } else {
            response[this.user.results[index].answer].selected = true;
            response[this.user.results[index].answer].openAnswer = this.user.results[index].openAnswer;
          }
          break;
        case 'rate':
          response = { max: question.max, value: this.user.results[index] };
          break;
        case 'open':
          response = this.user.results[index];
          break;
      }
      return { text: question.text, response: response, type: question.type }
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
