import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ZipcodesSuggestionComponent } from './zipcodes-suggestion.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: '', component: ZipcodesSuggestionComponent, data: { title: 'Registrar código postal' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ZipcodesSuggestionRoutingModule { }
