import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Logger } from '@app/core';
import { LoadingController, ModalController } from '@ionic/angular';
const log = new Logger('Zipcodes Suggestion');
import { AddZipcodeComponent } from './add-zipcode/add-zipcode.component';
@Component({
  selector: 'app-zipcodes-suggestion',
  templateUrl: './zipcodes-suggestion.component.html',
  styleUrls: ['./zipcodes-suggestion.component.scss']
})
export class ZipcodesSuggestionComponent implements OnInit {
  public mainArray: any[] = [];
  public isLoading: boolean = false;

  constructor(
    private afs: AngularFirestore,
    private modalCtrl: ModalController,
    private loadingController: LoadingController
  ) {
    this.initLocactions();
  }

  ngOnInit() {}

  async initLocactions(): Promise<void> {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const resp = await this.afs.collection('zipcodes-suggestion').ref.get();
      this.mainArray = resp.docs.map(item => {
        const data: any = item.data();
        const id: string = item.id;
        return { id, ...data };
      });
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
    return;
  }

  async zipcodes(zipcode?: string) {
    try {
      const modal = await this.modalCtrl.create({
        component: AddZipcodeComponent,
        componentProps: {
          zipcode: zipcode
        }
      });
      modal.onDidDismiss().then(() => {
        this.initLocactions();
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }
}
