<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center">
      Agregar código postal
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-card *ngFor="let item of colonies" color="light">
          <ion-card-header>
            <ion-card-subtitle>{{ item?.state }}</ion-card-subtitle>
            <ion-card-title>{{ item?.city }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p>{{ item?.postal_code }}</p>
            <ion-item>
              <ion-label fixed><ion-icon name="create"></ion-icon> Colonia/asentamiento:</ion-label>
              <ion-input type="text" placeholder="Ingresa colonia o asentamiento" [(ngModel)]="item.colony"></ion-input>
            </ion-item>
            <ion-button (click)="addPostalcodeAlert(item)" expand="block">
              <ion-icon slot="end" name="add"></ion-icon>
              Agregar
            </ion-button>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
