import { Component, OnInit, Input } from '@angular/core';
import { Logger } from '@app/core';
const log = new Logger('Select User Card');

@Component({
  selector: 'app-select-user-card',
  templateUrl: './select-user-card.component.html',
  styleUrls: ['./select-user-card.component.scss']
})
export class SelectUserCardComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Input() color: string;
  @Input() buttons: string;
  @Input() showInfo: boolean;
  realIndex: number = 1;
  isLoading: boolean = false;

  constructor() { }

  ngOnInit() {
    this.realIndex = Number(this.index);
    var str: string = String(this.item.avatar);
    var n = str.search('www.gravatar.com');
    if (n > -1) {
      this.item.avatar = undefined;
    }
  }

  parserany(obj: any): any {
    return obj;
  }
}
