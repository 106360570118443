import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { WpService } from '@app/shared/services/wp.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { Router } from '@angular/router';
import { CallOptsComponent } from './call-opts/call-opts.component';
import { ScheduleEvtComponent } from './schedule-evt/schedule-evt.component';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { Logger } from '@app/core';
import { DEFAULT_USERS_CONNECTIONS } from '../../../environments/environment';
const log = new Logger('Login');

interface InviteInterface {
  date_created: string;
  id: number;
  initiator_user_id: number;
  is_confirmed: boolean;
  is_limited: boolean;
  user_id: number;
}

interface InviteFilteredInterface {
  id: number;
  user_id: number;
}
@Component({
  selector: 'app-user-detail-view',
  templateUrl: './user-detail-view.component.html',
  styleUrls: ['./user-detail-view.component.scss']
})
export class UserDetailViewComponent implements OnInit {
  isLoading: boolean;
  userid: number;
  user: any;
  userRaw: any;
  isMyFriend: boolean;
  relation: InviteFilteredInterface;
  requestFalse: boolean;
  sentFalse: boolean;
  requestTrue: boolean;
  sentTrue: boolean;
  r_per_page: number = 100;
  r_page: number = 1;

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private wp: WpService,
    private route: Router,
    private credential: CredentialsService,
    private utilities: UtilitiesService
  ) {}

  async doRefresh(event: any) {
    await this.initializeApp();
    try {
      event.target.complete();
    } catch (error) {
      log.error(error);
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async initializeApp() {
    this.isLoading = true;
    this.resetButtons();
    this.isMyFriend = await this.wp.checkIsMyFriend(this.userid);
    await this.getUserDetails();
    await this.getInvites(this.userid);
    this.isLoading = false;
  }

  resetButtons() {
    this.requestFalse = false;
    this.sentFalse = false;
    this.requestTrue = false;
    this.sentTrue = false;
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: CallOptsComponent
    });
    return await modal.present();
  }

  async call(ev: any) {
    const popover = await this.popoverController.create({
      component: CallOptsComponent,
      event: ev,
      translucent: false,
      componentProps: {
        idUser: this.user.id,
        phone: this.userRaw.xprofile.groups[1].fields[36].value.raw,
        type: 'call'
      }
    });
    return await popover.present();
  }

  async scheduleCall(ev: any) {
    const popover = await this.popoverController.create({
      component: ScheduleEvtComponent,
      event: ev,
      translucent: false,
      componentProps: {
        idUser: this.user.id,
        phone: this.userRaw.xprofile.groups[1].fields[36].value.raw,
        type: 'call'
      }
    });
    return await popover.present();
  }

  async goChat(id: number): Promise<void> {
    const modal = await this.modalController.create({
      component: ChatComponent,
      componentProps: { id: id }
    });
    return await modal.present();
  }

  public async chat(): Promise<void> {
    this.isLoading = true;
    const messages1 = await this.wp.messagesByUser(undefined, 'inbox');
    const arreglo1 = Array.from(messages1.body).map((element: any) => {
      const index: number = Object.keys(element.recipients).indexOf(String(this.user.id));
      if (index > -1) {
        return { exist: true, id: element.id };
      } else {
        return { exist: false };
      }
    });
    const index1: number = arreglo1.map(e => e.exist).indexOf(true);
    const messages2 = await this.wp.messagesByUser(undefined, 'sentbox');
    const arreglo2 = Array.from(messages2.body).map((element: any) => {
      const index: number = Object.keys(element.recipients).indexOf(String(this.user.id));
      if (index > -1) {
        return { exist: true, id: element.id };
      } else {
        return { exist: false };
      }
    });
    const index2: number = arreglo2.map(e => e.exist).indexOf(true);
    const messages3 = await this.wp.messagesByUser(undefined, 'starred');
    const arreglo3 = Array.from(messages3.body).map((element: any) => {
      const index: number = Object.keys(element.recipients).indexOf(String(this.user.id));
      if (index > -1) {
        return { exist: true, id: element.id };
      } else {
        return { exist: false };
      }
    });
    const index3: number = arreglo3.map(e => e.exist).indexOf(true);
    if (index1 > -1) {
      this.goChat(arreglo1[index1].id);
    } else if (index2 > -1) {
      this.goChat(arreglo2[index2].id);
    } else if (index3 > -1) {
      this.goChat(arreglo3[index3].id);
    } else {
      const recipients: number[] = [];
      recipients.push(Number(this.user.id));
      recipients.push(Number(this.credential.credentials.uid));
      const response = await this.wp.startNewChat(recipients, Number(this.credential.credentials.uid));
      const chatId = response.body[0].id;
      this.goChat(chatId);
    }
    this.isLoading = false;
  }

  async ngOnInit() {
    await this.initializeApp();
  }

  async getUserDetails() {
    try {
      const data = await this.wp.member(this.userid);
      this.userRaw = data.body;
      this.user = this.utilities.mapMemberData(this.userRaw);
      var str: string = this.user.avatar;
      var n = str.search('www.gravatar.com');
      if (n > -1) {
        this.user.avatar = undefined;
      }
    } catch (error) {
      log.error(error);
    }
  }

  // <get requests sent / Received>
  async getInvites(uid: number): Promise<any> {
    try {
      const requestFalse = await this.recibidasNoAceptadas();
      const sentFalse = await this.enviadasNoAceptadas();
      const requestTrue = await this.recibidasAceptadas();
      const sentTrue = await this.enviadasAceptadas();
      const indexRequestFalse: number = requestFalse.map(element => element.user_id).indexOf(Number(uid));
      const indexSentFalse: number = sentFalse.map(element => element.user_id).indexOf(Number(uid));
      const indexRequestTrue: number = requestTrue.map(element => element.user_id).indexOf(Number(uid));
      const indexsentTrue: number = sentTrue.map(element => element.user_id).indexOf(Number(uid));
      if (indexRequestFalse > -1) {
        this.requestFalse = true;
        this.relation = requestFalse[indexRequestFalse];
      } else if (indexSentFalse > -1) {
        this.sentFalse = true;
        this.relation = sentFalse[indexSentFalse];
      } else if (indexRequestTrue > -1) {
        this.requestTrue = true;
        this.relation = requestTrue[indexRequestTrue];
      } else if (indexsentTrue > -1) {
        this.sentTrue = true;
        this.relation = sentTrue[indexsentTrue];
      }
    } catch (error) {
      log.error(error);
    }
  }

  async recibidasAceptadas(): Promise<InviteFilteredInterface[]> {
    try {
      const invites = await this.wp.getInvites(
        this.credential.credentials.uid,
        this.r_page,
        this.r_per_page,
        'true',
        'request'
      );
      const invitesArray: InviteFilteredInterface[] = Array.from(invites.response).map((element: InviteInterface) => {
        return {
          id: element.id,
          user_id: element.initiator_user_id
        };
      });
      return invitesArray;
    } catch (error) {
      log.error(error);
      return [];
    }
  }

  async recibidasNoAceptadas(): Promise<InviteFilteredInterface[]> {
    try {
      const invites = await this.wp.getInvites(
        this.credential.credentials.uid,
        this.r_page,
        this.r_per_page,
        'false',
        'request'
      );
      const invitesArray: InviteFilteredInterface[] = Array.from(invites.response).map((element: InviteInterface) => {
        return {
          id: element.id,
          user_id: element.initiator_user_id
        };
      });
      return invitesArray;
    } catch (error) {
      log.error(error);
      return [];
    }
  }

  async enviadasAceptadas(): Promise<InviteFilteredInterface[]> {
    try {
      const invites = await this.wp.getInvites(
        this.credential.credentials.uid,
        this.r_page,
        this.r_per_page,
        'true',
        'sent'
      );
      const invitesArray: InviteFilteredInterface[] = Array.from(invites.response).map((element: InviteInterface) => {
        return {
          id: element.id,
          user_id: element.user_id
        };
      });
      return invitesArray;
    } catch (error) {
      log.error(error);
      return [];
    }
  }

  async enviadasNoAceptadas(): Promise<any[]> {
    try {
      const invites = await this.wp.getInvites(
        this.credential.credentials.uid,
        this.r_page,
        this.r_per_page,
        'false',
        'sent'
      );
      const invitesArray: InviteFilteredInterface[] = Array.from(invites.response).map((element: InviteInterface) => {
        return {
          id: element.id,
          user_id: element.user_id
        };
      });
      return invitesArray;
    } catch (error) {
      log.error(error);
      return [];
    }
  }
  // <//get requests sent / Received>

  // <confirmation alerts>
  async confirmCancel() {
    const alert = await this.alertController.create({
      header: 'Cancelar solicitud',
      message: '¿Estás seguro de concelar esta solicitud?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.cancelRequest(this.relation.id);
          }
        }
      ]
    });
    await alert.present();
  }

  async confirmReject() {
    const alert = await this.alertController.create({
      header: 'Rechazar solicitud',
      message: '¿Estás seguro de rechazar esta solicitud?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.rejectRequest(this.relation);
          }
        }
      ]
    });
    await alert.present();
  }

  async confirmGoPayments() {
    const alert = await this.alertController.create({
      header: 'Saldo insuficiente',
      message: '¿Ir a agregar saldo?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Ir',
          handler: () => {
            this.route.navigateByUrl('/invoice/cards');
          }
        }
      ]
    });
    await alert.present();
  }

  async confirmSend() {
    const alert = await this.alertController.create({
      header: 'Enviar solicitud',
      message: '¿Estás seguro de enviar solicitud de contacto?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.checkUserPoints();
          }
        }
      ]
    });
    await alert.present();
  }

  async confirmDelete() {
    let message: string = '';
    if (this.credential.credentials.type == 'medico') {
      message = 'El contacto se podrá eliminar después de 30 días de la fecha en que fué aceptado.';
    } else {
      message = 'El contacto se eliminará.';
    }
    const alert = await this.alertController.create({
      header: '¿Acepta eliminar a ' + this.user.name + ' de sus contactos activos?',
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.validateBeforeDelete(this.relation.id);
          }
        }
      ]
    });
    await alert.present();
  }

  async confirmAccept() {
    const alert = await this.alertController.create({
      header: 'Aceptar solicitud',
      message: '¿Estás seguro de aceptar esta solicitud?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.acceptRequest(this.relation.id);
          }
        }
      ]
    });
    await alert.present();
  }
  // <//confirmation alerts>

  async sendRequest(userid: number) {
    this.isLoading = true;
    const response = await this.wp.sendFriendShip(userid);
    if (response.success == true) {
      const id = String(String(response.response).substr(String(response.response).lastIndexOf(' ') + 1)).toLowerCase();
      this.wp.createNotification(Number(userid), Number(id), 'contacts_' + id, 'contacts');
      this.initializeApp();
      this.utilities.toast('Solicitud enviada correctamente.', 'Correcto');
    } else {
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
    await this.initializeApp();
    this.isLoading = false;
  }

  async deleteFirendShip(id: number) {
    this.isLoading = true;
    try {
      let response1: any;
      let response2: any;
      response1 = await this.wp.deleteFriendShip(id, 'cancel');
      response2 = await this.wp.deleteFriendShip(id);
      if (response1.success == true || response2.success == true) {
        this.initializeApp();
        this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
      } else {
        if (typeof response2.msg) {
          this.utilities.toast(response2.msg, 'Error');
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      }
      await this.initializeApp();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async validateBeforeDelete(id: number) {
    let response: any;
    try {
      if (this.credential.credentials.type == 'medico') {
        response = await this.wp.validateAntiquityFriendship(id);
        if (response && response.response && response.response == true) {
          this.deleteFirendShip(id);
        } else {
          this.utilities.toast(
            'Para eliminar este contacto debe esperar 30 días después de que acepto la solicitud de contacto.',
            '¡No se ha podido eliminar contacto!'
          );
        }
      } else {
        this.deleteFirendShip(id);
      }
    } catch (error) {
      log.error(error);
    }
  }

  async checkUserPoints() {
    this.isLoading = true;
    const defaultUser: boolean =
      DEFAULT_USERS_CONNECTIONS.map(item => item.id).indexOf(this.credential.credentials.uid) > -1 ? true : false;
    try {
      if (this.credential.credentials.type == 'representante-medico' && defaultUser == true) {
        await this.sendRequest(this.userid);
      } else if (this.credential.credentials.type == 'representante-medico' && defaultUser == false) {
        const points: number = await this.wp.getMemberPoints();
        if (points < 232) {
          this.confirmGoPayments();
        } else {
          const pointsObject = {
            points: -232,
            note: 'Cargo por conexión',
            user_id: this.credential.credentials.uid
          };
        }
      } else {
        const pointsObject = {
          points: 50,
          note: 'Abono por conexión',
          user_id: this.credential.credentials.uid
        };
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async cancelRequest(id: number) {
    this.isLoading = true;
    const defaultUser: boolean =
      DEFAULT_USERS_CONNECTIONS.map(item => item.id).indexOf(this.credential.credentials.uid) > -1 ? true : false;
    try {
      if (this.credential.credentials.type == 'representante-medico' && defaultUser == true) {
        const response = await this.wp.deleteFriendShip(id, 'cancel');
        if (response && response.success && response.success == true) {
          this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      } else if (this.credential.credentials.type == 'representante-medico' && defaultUser == false) {
        const pointsObject = {
          points: 232,
          note: 'Reembolso por cancelar solitud conexión',
          user_id: this.credential.credentials.uid
        };
      } else {
        const pointsObject = {
          points: -50,
          note: 'Descuento por cancelar solitud conexión',
          user_id: this.credential.credentials.uid
        };
      }
      await this.initializeApp();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async rejectRequest(item: any) {
    const id = item.id;
    const userId = item.user_id;
    this.isLoading = true;
    const defaultUser: boolean =
      DEFAULT_USERS_CONNECTIONS.map(item => item.id).indexOf(this.credential.credentials.uid) > -1 ? true : false;
    try {
      if (this.credential.credentials.type == 'representante-medico' && defaultUser == true) {
        const response = await this.wp.deleteFriendShip(id);
        if (response && response.success && response.success == true) {
          this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      }
      await this.initializeApp();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async acceptRequest(id: number) {
    this.isLoading = true;
    const defaultUser: boolean =
      DEFAULT_USERS_CONNECTIONS.map(item => item.id).indexOf(this.credential.credentials.uid) > -1 ? true : false;
    try {
      if (this.credential.credentials.type == 'representante-medico' && defaultUser == true) {
        const response = await this.wp.acceptFriendships(5, id);
        if (response.success == true) {
          this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      } else if (this.credential.credentials.type == 'representante-medico' && defaultUser == false) {
        const points: number = await this.wp.getMemberPoints();
        if (points < 232) {
          this.confirmGoPayments();
        }
      }
      await this.initializeApp();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }
}
