<ion-app>
  <ion-split-pane contentId="main-content" when="(min-width: 3000px)">
    <ion-menu contentId="main-content" type="overlay" *ngIf="credentialsService?.credentials?.uid">
      <ion-content>
        <div class="profile ion-text-center ion-padding">
          <ion-icon class="profile-icon" name="person-circle"></ion-icon>
          <div>
            <ion-menu-toggle auto-hide="false">
              <ion-button class="profile-button" color="light" (click)="showProfileActions()" shape="round"
                fill="outline" size="small" expand="block">
                <ion-icon name="person"></ion-icon> {{ username }}
              </ion-button>
            </ion-menu-toggle>
          </div>
        </div>

        <ion-list id="labels-list">
          <ion-menu-toggle *ngFor="let item of routes">
            <ion-item [routerDirection]="'root'" [routerLink]="item?.route" routerLinkActive="active"
              *ngIf="item?.active === true">
              <ion-label>{{ item?.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-item (click)="databaseModal()" [button]="true" *ngIf="showButtons === true">
            <ion-label>Base de datos</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>