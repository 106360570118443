import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { PlnasRoutingModule } from './plans-routing.module';
import { PlansComponent } from './plans.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AddPlanComponent } from './add-plan/add-plan.component';
import { OptionsPlanComponent } from './options-plan/options-plan.component';
import { MembersPlanComponent } from './members-plan/members-plan.component';
import { CardsModule } from '../shared/cards/cards.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PlnasRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CardsModule
  ],
  declarations: [PlansComponent, AddPlanComponent, OptionsPlanComponent, MembersPlanComponent]
})
export class PlansModule {}
