import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, map } from 'rxjs';
import { Credentials, CredentialsService } from './credentials.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Logger } from '../../core/logger.service';
const log = new Logger('Authentication');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public authState = new BehaviorSubject(false);

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private credentialsService: CredentialsService
  ) { }

  async login(email: string, password: string, remember: boolean): Promise<Credentials> {
    try {
      const login = await this.loginUser(email, password);
      if (login.user.uid) {
        const dataUsr: any = await this.afs
          .collection('users')
          .doc(login.user.uid)
          .ref.get();
        if (dataUsr.exists === true) {
          const data: Credentials = {
            email: dataUsr.data().email,
            type: dataUsr.data().type,
            active: dataUsr.data().status === 'active' ? true : false,
            uid: dataUsr.id
          };
          this.credentialsService.setCredentials(data, remember);
          return data;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async logout(): Promise<void> {
    await this.afAuth.signOut();
    await this.credentialsService.setCredentials();
  }

  loginUser(newEmail: string, newPassword: string): Promise<firebase.default.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(newEmail, newPassword);
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<void> {
    return this.afAuth.signOut();
  }

  isAuthenticated(): Observable<boolean> {
    return this.afAuth.authState.pipe(map(e => e && e.uid ? true : false));
  }
}
