<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Foro Médico</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-margin ion-no-padding" color="light">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <!------- F O R O    M É D I C O ------>
        <ion-grid fixed>
          <ion-row>
            <ion-col
              *ngFor="let item of arrayDocs; let i = index"
              size="12"
              class="ion-no-margin ion-no-padding animate__animated animate__fadeIn"
            >
              <ion-card
                [color]="item?.status === 'active' ? '' : 'medium'"
                mode="md"
                class="ion-no-margin ion-margin-bottom only-movil-margin card-post"
                [button]="true"
              >
                <!-- card Header -->
                <ion-card-header [color]="item?.status === 'active' ? 'primary' : 'medium'">
                  <ion-card-title>
                    <h3>
                      {{ item?.title }} - {{ item?.createdAt?.toDate() | date: 'dd/MM/yyyy HH:mm' }}
                      <small
                        ><strong>({{ item?.status === 'active' ? 'Activo' : 'Oculto' }})</strong></small
                      >
                    </h3>
                  </ion-card-title>
                </ion-card-header>
                <!-- Contenido de la tarjeta -->
                <ion-row style="padding: 0px;">
                  <!-- Imagen de la tarjeta -->
                  <ion-col sizeXs="4" sizeMd="6" style="padding: 0px;" *ngIf="item?.imageBG">
                    <img [src]="item?.imageBG" class="img-post" />
                  </ion-col>
                  <!-- Inforamción del post -->
                  <ion-col sizeXs="8" sizeMd="6" style="padding: 0px !important; padding-right: 0px !important;">
                    <div class="read-content">
                      <p>
                        {{ item?.content | slice: 0:400 }}
                        <span *ngIf="item.content.length > 400" style="cursor: pointer;">...</span>
                      </p>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-toolbar>
                  <ion-title></ion-title>
                  <ion-buttons slot="end">
                    <ion-button (click)="delete(item?.id)" color="danger">
                      <ion-icon slot="start" name="trash"></ion-icon>
                      Eliminar
                    </ion-button>
                    <ion-button (click)="editPost(item?.id)" color="primary">
                      <ion-icon slot="start" name="create"></ion-icon>
                      Editar
                    </ion-button>
                    <ion-button (click)="hidePost(item?.id)" color="medium" *ngIf="item?.status === 'active'">
                      <ion-icon slot="start" name="eye-off"></ion-icon>
                      Ocultar
                    </ion-button>
                    <ion-button (click)="showPost(item?.id)" color="success" *ngIf="item?.status !== 'active'">
                      <ion-icon slot="start" name="eye"></ion-icon>
                      Mostrar
                    </ion-button>
                  </ion-buttons>
                </ion-toolbar>
              </ion-card>
            </ion-col>
            <!-- Skeletons for loading -->
            <ion-col
              *ngFor="let o of [0, 0, 0]; let i = index"
              size="12"
              class="ion-no-margin ion-no-padding animate__animated animate__fadeIn"
            >
              <ion-card
                color="light"
                mode="md"
                class="ion-no-margin ion-margin-bottom only-movil-margin card-post"
                [button]="true"
                *ngIf="isLoading"
              >
                <!-- card Header -->
                <ion-toolbar color="primary" style="height: auto;">
                  <ion-row class="ion-align-items-center">
                    <ion-col sizeXs="12" sizeSm="">
                      <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                    </ion-col>
                  </ion-row>
                </ion-toolbar>
                <!-- Contenido de la tarjeta -->
                <ion-row style="padding: 0px;">
                  <!-- Imagen de la tarjeta -->
                  <ion-col sizeXs="12" sizeLg="3" sizeMd="6" style="padding: 0px;">
                    <ion-skeleton-text animated class="img-wrapper"></ion-skeleton-text>
                  </ion-col>
                  <!-- Inforamción del post -->
                  <ion-col sizeXs="" style="padding: 0px !important; padding-right: 0px !important;">
                    <div class="read-content">
                      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="paginate('forward')">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Cargando..."> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
