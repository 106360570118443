<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Detalle de click en Banners</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-button (click)="initializeApp()" expand="block" fill="clear" shape="round">
          Recargar
          <ion-icon slot="end" name="refresh"></ion-icon>
        </ion-button>
        <h2 style="text-align: center;">Fecha: {{ dateStr }}</h2>
        <ion-card>
          <ion-card-header color="primary">
            <ion-card-title>{{ item?.content }}</ion-card-title>
            <ion-card-subtitle>N° Clicks: {{ item?.count }}</ion-card-subtitle>
            <ion-card-subtitle>ID: {{ item?.id }}</ion-card-subtitle>
          </ion-card-header>

          <ion-card-content *ngIf="item?.info?.link as data">
            <h4><strong>Link</strong></h4>
            URL: {{ data?.url }}
          </ion-card-content>

          <ion-card-content *ngIf="item?.info?.product as data">
            <h4><strong>Producto</strong></h4>
            Nombre: {{ data?.name }} <br />
            Compañía: {{ data?.company }} <br />
            Tipo : {{ data?.type }}
          </ion-card-content>

          <ion-card-content *ngIf="item?.info?.rep as data">
            <h4><strong>Representante</strong></h4>
            Nombre: {{ data?.name }} <br />
            Compañía: {{ data?.company }} <br />
            Email: {{ data?.email }}<br />
            Teléfono 1: {{ data?.phone1 }}<br />
            Teléfono 2: {{ data?.phone2 }}
          </ion-card-content>
        </ion-card>

        <h3 style="text-align: center;">Médicos:</h3>

        <ion-list>
          <ion-item *ngFor="let item of users">
            <ion-label>
              <span>
                <strong>{{ item?.name }}</strong> </span
              ><br />
              <span> <strong>ID:</strong> {{ item?.id }} </span> <br />
              <span> <strong>Email:</strong> {{ item?.email }} </span> <br />
              <span> <strong>Teléfono 1:</strong> {{ item?.phone1 }} </span> <br />
              <span> <strong>Teléfono 2:</strong> {{ item?.phone2 }} </span> <br />
              <span> <strong>Fecha:</strong> {{ item?.date }} </span>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
