import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WpService } from '../../shared/services/wp.service';

@Component({
  selector: 'app-specialites',
  templateUrl: './specialites.component.html',
  styleUrls: ['./specialites.component.scss']
})
export class SpecialitesComponent implements OnInit {
  public myArray: any[] = [];
  arrayData: any[] = [];
  isLoading: boolean;
  values: any;

  constructor(private modalCtrl: ModalController, private wp: WpService) {}

  ngOnInit() {
    this.init();
  }

  private async init(): Promise<void> {
    this.isLoading = true;
    const raw = await this.wp.getSpecialties();
    this.myArray = Object.entries(raw).map((element: any) => {
      return {
        id: element[1].id,
        label: element[1].name,
        isChecked: false
      };
    });
    this.refillData();
    this.isLoading = false;
  }

  selectItem(item: any) {
    item.isChecked = true;
    const element = [item];
    this.modalCtrl.dismiss({ arrayData: element });
  }

  refillData() {
    if (this.arrayData && this.arrayData[0]) {
      this.arrayData.forEach(element => {
        const index: number = this.myArray.map(e => e.id).indexOf(element.id);
        if (index > -1) {
          this.myArray[index].isChecked = element.isChecked;
        }
      });
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({ arrayData: this.arrayData });
  }

  clearFilter() {
    this.modalCtrl.dismiss({ arrayData: [] });
  }
}
