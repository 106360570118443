<ion-app>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="end">
        <ion-button (click)="modalC.dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ accion }} Publicidad</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-card class="read-content" style="background-color: white;"
      ><br />

      <!-- Type -->
      <h5>Tipo de publicidad</h5>
      <ion-segment [(ngModel)]="advertising.type" mode="ios">
        <ion-segment-button value="banner" mode="ios">
          <ion-label>Banner</ion-label>
        </ion-segment-button>
        <ion-segment-button value="popup" mode="ios">
          <ion-label>PopUp</ion-label>
        </ion-segment-button>
      </ion-segment>

      <!-- Contenido | only 90 chars -->
      <h5>
        Contenido
        <span *ngIf="!addCheck.content.ok && addCheck.content.message" class="span-alert"
          >*{{ addCheck.content.message }}*</span
        >
      </h5>
      <ion-item>
        <ion-input
          [maxlength]="90"
          [(ngModel)]="advertising.content"
          (ionChange)="fullCheck()"
          [debounce]="250"
        ></ion-input>
      </ion-item>

      <!-- Analytics -->
      <div>
        <h5>
          Etiqueta de analytics
          <span *ngIf="!addCheck.analyticsLabel.ok && addCheck.analyticsLabel.message" class="span-alert"
            >*{{ addCheck.analyticsLabel.message }}*</span
          >
        </h5>
        <ion-item>
          <ion-input [debounce]="250" [(ngModel)]="advertising.analyticsLabel" (ionChange)="fullCheck()"></ion-input>
        </ion-item>
      </div>

      <!-- Img -->
      <h5>
        Imagen
        <span *ngIf="!addCheck.img.ok && addCheck.img.message" class="span-alert">*{{ addCheck.img.message }}*</span>
      </h5>
      <div>
        <input
          type="file"
          accept="image/png, image/jpeg"
          class="ion-hide"
          #inputImg
          (change)="loadImg(inputImg.files)"
        />
        <img [src]="img" class="put-img to-click" (click)="inputImg.click()" />
      </div>

      <!-- Url or Repre -->
      <h5>Tipo de Referencia</h5>
      <ion-segment [(ngModel)]="advertising.relType" mode="ios" (ionChange)="selectionChange()">
        <ion-segment-button value="link" mode="ios">
          <ion-label>Link</ion-label>
        </ion-segment-button>
        <ion-segment-button value="repre" mode="ios">
          <ion-label>Representante</ion-label>
        </ion-segment-button>
        <ion-segment-button value="product" mode="ios">
          <ion-label>Producto</ion-label>
        </ion-segment-button>
      </ion-segment>

      <!-- Product -->
      <div *ngIf="advertising.relType === 'product'">
        <h5>
          Seleccionar Producto
          <span *ngIf="!addCheck.repreId.ok && addCheck.repreId.message" class="span-alert"
            >*{{ addCheck.repreId.message }}*</span
          >
        </h5>
        <ion-button (click)="getProducts()">
          Seleccionar Producto
        </ion-button>
        <ion-item *ngIf="product">
          <ion-avatar slot="start" *ngIf="product.image">
            <img [src]="product.image" />
          </ion-avatar>
          <ion-avatar slot="start" *ngIf="!product.image">
            <img src="/assets/img/no-product.PNG" />
          </ion-avatar>
          <ion-label>
            <h5>{{ product.marca }}</h5>
            <p>
              <span>linea: {{ product.linea_terapeutica_1 }}</span>
              <span *ngIf="product.linea_terapeutica_2">, {{ product.linea_terapeutica_2 }}</span>
            </p>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="repre">
          <ion-avatar slot="start" *ngIf="repre.avatar && repre.avatar?.thumbnail">
            <img [src]="repre.avatar.thumbnail.url" />
          </ion-avatar>
          <ion-avatar slot="start" *ngIf="(!repre.avatar && !repre.avatar?.thumbnail) || repre.avatar === {}">
            <img src="/assets/img/default-representante-medico.jpg" />
          </ion-avatar>
          <ion-label>
            <h5>{{ repre?.name + ' ' + repre?.lastName1 + ' ' + repre?.lastName2 }}</h5>
            <p>{{ repre?.email }}<br />{{ repre?.mobile }}</p>
          </ion-label>
        </ion-item>
      </div>

      <!-- Link -->
      <div *ngIf="advertising.relType === 'link'">
        <h5>
          Link
          <span *ngIf="!addCheck.link.ok && addCheck.link.message" class="span-alert"
            >*{{ addCheck.link.message }}*</span
          >
        </h5>
        <ion-item>
          <ion-input (ionChange)="fullCheck()" [debounce]="250" [(ngModel)]="advertising.link"></ion-input>
        </ion-item>
      </div>

      <!-- Representante -->
      <div *ngIf="advertising.relType === 'repre'">
        <h5>
          Seleccionar Representante
          <span *ngIf="!addCheck.repreId.ok && addCheck.repreId.message" class="span-alert"
            >*{{ addCheck.repreId.message }}*</span
          >
        </h5>
        <ion-button (click)="selectUsersFunction()">
          Seleccionar Representante
        </ion-button>
        <ion-item *ngIf="repre">
          <ion-avatar slot="start" *ngIf="repre.avatar && repre.avatar?.thumbnail">
            <img [src]="repre.avatar.thumbnail.url" />
          </ion-avatar>
          <ion-avatar slot="start" *ngIf="(!repre.avatar && !repre.avatar?.thumbnail) || repre.avatar === {}">
            <img src="/assets/img/default-representante-medico.jpg" />
          </ion-avatar>
          <ion-label>
            <h5>{{ repre?.name + ' ' + repre?.lastName1 + ' ' + repre?.lastName2 }}</h5>
            <p>{{ repre?.email }}<br />{{ repre?.mobile }}</p>
          </ion-label>
        </ion-item>
      </div>

      <!-- Tags -->
      <h5>
        Seleccionar Tags
        <span *ngIf="!addCheck.tags.ok && addCheck.tags.message" class="span-alert">*{{ addCheck.tags.message }}*</span>
      </h5>
      <ion-button (click)="getTags()"> Seleccionar Tags </ion-button><br />
      <ion-chip color="primary" *ngFor="let tag of advertising.theTags" mode="md">
        <ion-label>{{ tag.name }}</ion-label>
        <ion-icon
          name="close-circle"
          (click)="
            advertising.tags.splice(advertising.tags.indexOf(tag.id), 1);
            advertising.theTags.splice(advertising.theTags.indexOf(tag), 1);
            fullCheck()
          "
        ></ion-icon>
      </ion-chip>

      <!-- Preview -->
      <br /><br />
      <h6 class="ion-text-center">Vista Pequeña</h6>
      <div class="advertising" [title]="advertising.link || advertising.repreId">
        <ion-row>
          <ion-col size="auto" style="max-height: 100px;">
            <img [src]="img" class="put-img" style="opacity: 0.7;" />
          </ion-col>
          <ion-col size="" style="max-height: 100px;">
            <ion-app>
              <ion-content [scrollY]="false">
                <button class="close-button">
                  <ion-icon name="close"></ion-icon>
                </button>
                <div style="padding-right: 20px; height: 100%;">
                  <ion-row style="height: 100%;" class="ion-align-items-center">
                    <ion-col size="12" style="padding: 0px;">
                      <p style="font-size: 12px;">{{ advertising.content }}</p>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-content>
            </ion-app>
          </ion-col>
        </ion-row>
      </div>
      <h6 class="ion-text-center">Vista Grande</h6>
      <div class="big-advertising" [title]="advertising.link || advertising.repreId">
        <ion-row>
          <ion-col size="auto" style="max-height: 150px;">
            <img [src]="img" class="put-img-big" style="opacity: 0.7;" />
          </ion-col>
          <ion-col size="" style="max-height: 150px;">
            <ion-app>
              <ion-content [scrollY]="false">
                <button class="close-button">
                  <ion-icon name="close"></ion-icon>
                </button>
                <div style="padding-right: 20px; height: 100%;">
                  <ion-row style="height: 100%;" class="ion-align-items-center">
                    <ion-col size="12" style="padding: 0px;">
                      <p>{{ advertising.content }}</p>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-content>
            </ion-app>
          </ion-col>
        </ion-row>
      </div>

      <br
    /></ion-card>
  </ion-content>

  <ion-footer>
    <ion-button (click)="submitAdvertising()" [disabled]="!addCheck.valid" expand="full" fill="clear">
      <ion-icon slot="start" name="add" *ngIf="accion === 'Crear'"></ion-icon>
      <ion-icon slot="start" name="create" *ngIf="accion === 'Editar'"></ion-icon>
      {{ accion }} Publicidad
    </ion-button>
  </ion-footer>
</ion-app>
