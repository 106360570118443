import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { SearchComponent } from '@app/search/search.component';
import { UtilitiesService } from '@app/shared/services/utilities.service';
const log = new Logger('ActivateMemberV2Component');

interface Results {
  id: string;
  success: boolean;
  data?: any;
}

@Component({
  selector: 'app-activate-member-v2',
  templateUrl: './activate-member-v2.component.html',
  styleUrls: ['./activate-member-v2.component.scss']
})
export class ActivateMemberV2Component implements OnInit {
  public isLoading: boolean;
  public mainArray: any[] = [];
  public step: number = 1;
  public usersRaw: any[] = [];
  public asignacionType: 'especialidad-estado' | 'email' = 'especialidad-estado';
  public operationType: 'activate' | 'deactivate' = 'activate';
  public results = {
    successful: 0,
    operations: 0,
    type: 'activate'
  };
  public operations: Results[] = [];
  private users: any[] = [];
  public stringEmails: any;

  constructor(
    private afs: AngularFirestore,
    private firebase: FirebaseService,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private utilities: UtilitiesService,
    private alertController: AlertController
  ) { }

  reset() {
    this.results = {
      operations: 0,
      successful: 0,
      type: 'create'
    };
    this.stringEmails = '';
    this.mainArray = [];
    this.step = 1;
    this.operations = [];
    this.asignacionType = 'especialidad-estado';
    this.operationType = 'activate';
  }

  ngOnInit() { }

  async selectUsersFunction(type?: 'medico' | 'representante-medico') {
    try {
      const data: any[] = this.mainArray.map(e => e);
      const modal = await this.modalCtrl.create({
        component: SearchComponent,
        componentProps: {
          viewType: 'modal',
          selectedUsers: data,
          memberType: 'medico',
          searchMemberType: type,
          notUseStatusCondition: true
        }
      });
      modal.onDidDismiss().then(users => {
        if (users.data && users.data.users) {
          this.mainArray = users.data.users;
          this.users = this.mainArray;
          this.stringEmails = [];
          for (const user of this.mainArray) {
            this.stringEmails.push(user.email);
          }
        }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  step1() {
    this.step = 2;
  }

  async step2() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const emails: string[] = await this.utilities.getEmails(this.stringEmails);
      const mainArray: any[] = [];
      for (const email of emails) {
        try {
          const response = await this.afs
            .collection('users')
            .ref.where('email', '==', email)
            .get();
          if (response.empty === false) {
            const data: any = response.docs[0].data();
            const id = response.docs[0].id;
            mainArray.push({ id, ...data });
          }
        } catch (error) {
          log.error(error);
        }
      }
      if (mainArray && mainArray.length > 0) {
        this.users = mainArray.map(e => e);
        this.usersRaw = mainArray;
        this.step = 3;
      } else {
        this.mainArray = [];
        this.utilities.toast('No se encontraron resultados de los emails ingresados.');
      }
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  step3() {
    console.log(this.users);
    if (this.operationType === 'activate') {
      this.activateConfirm();
    } else if (this.operationType === 'deactivate') {
      this.deactivateConfirm();
    } else {
      this.utilities.toast('Ha ocurrido un error!');
    }
  }

  async activateConfirm() {
    const alert = await this.alertController.create({
      header: `Confirmar actualización`,
      message: `Se activarán ${this.users.length} usuarios.`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Activar',
          handler: async () => {
            await this.activate();
            for (const user of this.users) {
              this.firebase.desactiveProductsFromRepresentant(user.uid, 'active');
            }
            this.firebase.checkInactiveCompanyFromUsers(this.users, true);
          }
        }
      ]
    });
    await alert.present();
  }

  async deactivateConfirm() {
    const alert = await this.alertController.create({
      header: `Confirmar actualización`,
      message: `Se desactivarán  ${this.users.length} usuarios.`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Desactivar',
          handler: async () => {
            await this.activate(true);
            for (const user of this.users) {
              this.firebase.desactiveProductsFromRepresentant(user.uid, 'inactive');
            }
            this.firebase.checkInactiveCompanyFromUsers(this.users, false);
          }
        }
      ]
    });
    await alert.present();
  }

  async createFriendShip(initiator: string, requested: string) {
    let response: DocumentReference | undefined;
    try {
      response = await this.firebase.createFriendShip(initiator, requested);
    } catch (error) {
      log.error(error);
    }
    if (response && response.id) {
      const id = String(response.id);
      return { success: true, id: id, initiator: initiator, requested: requested };
    }
    return { success: false, id: undefined, initiator: initiator, requested: requested };
  }

  async activate(deactivate?: boolean) {
    this.isLoading === true;
    const loadingOverlay = await this.loadingController.create({ message: 'Por favor espere...' });
    loadingOverlay.present();
    try {
      let promises: Promise<{ id: string; success: boolean }>[] = [];
      for (let user of this.users) {
        promises.push(this.firebase.activateUser(user.id, deactivate));
      }
      const response = await Promise.all(promises);
      this.operations = response.map(element => {
        const index = this.usersRaw.map(e => e.id).indexOf(element.id);
        let data: any;
        if (index > -1) {
          data = this.usersRaw[index];
        }
        return {
          id: element.id,
          success: element.success,
          data: data
        };
      });
      this.results.type = deactivate === true ? 'deactivate' : 'activate';
      this.results.operations = promises.length;
      this.results.successful = response.filter(e => {
        if (e && e.success === true) {
          return e;
        }
        return undefined;
      }).length;
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.step = 4;
  }

  confirmDelete(index: number) {
    this.mainArray.splice(index, 1);
    this.users = this.mainArray.map(e => e.id);
    this.usersRaw = this.mainArray;
  }
}
