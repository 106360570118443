import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  CollectionReference,
  DocumentData,
  DocumentReference,
  Query,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { toInteger } from 'lodash';
import { DEFAULT_USERS_CONNECTIONS_IDS } from '@env/environment';
import { FirebaseService } from './firebase.service';

export interface WhereInterface {
  opStr: firebase.default.firestore.WhereFilterOp;
  fieldPath: string | firebase.default.firestore.FieldPath;
  value: any;
}
export interface DocsCollection {
  path: string;
  where?: WhereInterface[];
  orderBy?: { fieldPath: string | firebase.default.firestore.FieldPath; directionStr?: firebase.default.firestore.OrderByDirection };
  limit?: number;
  startAfter?: any;
  endBefore?: any;
  startAt?: any;
  endAt?: any;
}
/*
Change data to final user
*/
@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  constructor(
    private afs: AngularFirestore,
    private utilies: UtilitiesService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private firebaseService: FirebaseService
  ) { }

  async asignFakeLikesToForoPosts(max: number) {
    max = toInteger(max);
    console.log('Asign Fake Likes to Foro Posts', max);
    const loading = await this.loadingController.create({
      message: 'Obteniendo Información'
    });
    await loading.present();
    const snaps = await this.afs
      .collection('foro')
      .ref.limit(100)
      .get();
    loading.message = 'Revisando Maxima Cantidad de Likes Genericos';
    let defaultLikes: number;
    await new Promise(resolve => {
      const fun = this.afs
        .doc('constants/constants')
        .valueChanges()
        .subscribe((data: any) => {
          defaultLikes = data.defaultLikes;
          fun.unsubscribe();
          resolve(null);
        });
    });
    if (!defaultLikes) {
      loading.message = 'No Existe un Maximo de likes en la base de datos';
      await setTimeout(async () => {
        loading.message = 'Asigne un valor a "defaultLikes" en "constants"';
        await setTimeout(() => {
          loading.dismiss();
        }, 2000);
      }, 2000);
      return;
    }
    if (max > defaultLikes) {
      max = defaultLikes;
    }
    let i = 0;
    for (const post of snaps.docs) {
      i = i + 1;
      loading.message = `Asignando likes a post ${i} de ${snaps.size}`;
      const data: any = post.data();
      const random = Math.round(Math.random() * max);
      if (data.defaultLikes) {
        if (data.defaultLikes + random > defaultLikes) {
          await this.afs.doc(`foro/${post.id}`).update({ defaultLikes });
        } else {
          await this.afs.doc(`foro/${post.id}`).update({ defaultLikes: data.defaultLikes + random });
        }
      } else {
        await this.afs.doc(`foro/${post.id}`).update({ defaultLikes: random });
      }
    }
    loading.message = 'Likes asignados';
    setTimeout(() => {
      loading.dismiss();
    }, 1000);
  }

  async updateBroadcastListUserOrder() {
    try {
      console.log('update BroadcastList User Order');
      const resp = await this.afs.collection('broadcast-list').ref.get();
      const array: string[] = resp.docs.map(e => {
        return e.id;
      });
      let newArray: any[] = [];
      let rawArray: any[] = [];
      for (const element of array) {
        const ref: CollectionReference = this.afs
          .collection('broadcast-list')
          .doc(element)
          .collection('contacts').ref;
        const resp = await ref.get();
        const arrayContacts: any[] = resp.docs.map(e => {
          const data: any = e.data();
          const id: string = e.id;
          return { id, parent: element, ...data };
        });
        arrayContacts.forEach(e => {
          rawArray.push(e);
        });
      }
      const originalArray: any[] = rawArray.map(element => {
        const newName: string = this.utilies.stringSearch(
          `${element['lastName1'] || ''} ${element['lastName2'] || ''} ${element['name'] || ''}`,
          true
        );
        //const newName: string = "fullName";
        const stringRef: string = `broadcast-list/${element.parent}/contacts/${element.id}`;
        const ref: DocumentReference = this.afs.doc(stringRef).ref;
        return { newName: newName, ref: ref };
      });
      const LONG_ARRAY = 500;
      for (let i = 0; i < originalArray.length; i += LONG_ARRAY) {
        let piece = originalArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }
      console.log(newArray);
      let count1: number = 1;
      for (const array of newArray) {
        const batch = this.afs.firestore.batch();
        for (const element of array) {
          batch.update(element.ref, { nameStr: element.newName });
          console.log(`${count1}.- ${element}`);
        }
        await batch.commit();
        console.log(`${count1++}.- batch commit`);
      }
      console.log(`********* batch commit end *********`);
      const newResp = await this.afs.collection('broadcast-list').ref.get();
      const newRespArray: string[] = newResp.docs.map(e => {
        return e.id;
      });
      let newRawArray: any[] = [];
      for (const element of newRespArray) {
        const ref: CollectionReference = this.afs
          .collection('broadcast-list')
          .doc(element)
          .collection('contacts').ref;
        const resp = await ref.get();
        const arrayContacts: any[] = resp.docs.map(e => {
          const data: any = e.data();
          const id: string = e.id;
          return { id, parent: element, ...data };
        });
        arrayContacts.forEach(e => {
          newRawArray.push(e);
        });
      }
      newRawArray.forEach((e, index) => {
        console.log(`${index + 1}.- ${e.nameStr} (${e.id})`);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async updateFriendsCounters(ids?: string[]) {
    try {
      for (const id of ids) {
        const lettersMed: DocumentReference[] = (
          await this.afs.collection(`friends/${id}/information/counters/alphabet/medico/letters`).ref.get()
        ).docs.map(e => e.ref);
        const lettersRep: DocumentReference[] = (
          await this.afs
            .collection(`friends/${id}/information/counters/alphabet/representante-medico/letters`)
            .ref.get()
        ).docs.map(e => e.ref);
        const filtersMed: DocumentReference[] = (
          await this.afs.collection(`friends/${id}/information/counters/filters/type/medico`).ref.get()
        ).docs.map(e => e.ref);
        const filtersRep: DocumentReference[] = (
          await this.afs.collection(`friends/${id}/information/counters/filters/type/representante-medico`).ref.get()
        ).docs.map(e => e.ref);

        for (const ref of lettersMed) {
          const size: number = (await ref.collection('users').get()).size;
          ref.set({ count: size });
        }

        for (const ref of lettersRep) {
          const size: number = (await ref.collection('users').get()).size;
          ref.set({ count: size });
        }

        for (const ref of filtersMed) {
          const size: number = (await ref.collection('users').get()).size;
          ref.set({ count: size });
        }

        for (const ref of filtersRep) {
          const size: number = (await ref.collection('users').get()).size;
          let comapany: any = {};
          if (size > 0) {
            comapany = (await this.afs.doc(`companies/${ref.id}`).ref.get()).data() || {};
            console.log('===== Company =====', JSON.stringify(comapany));
          }
          ref.set({ count: size, ...comapany });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async updateFriendsCountersAlphabet(ids?: string[]) {
    console.log('Update Friends Counters alphabet');
    let newArray: any[] = [];
    let dataArray: any[] = [];
    //
    try {
      let userArray: string[] = [];

      if (ids) {
        userArray = ids;
      } else {
        const resp = await this.afs.collection('friends').ref.get();
        userArray = resp.docs.map(e => e.id);
      }

      for (const uid of userArray) {
        const resp = await this.afs.collection(`friends/${uid}/friends`).ref.get();
        const friendsArray = resp.docs.map(element => {
          const data: any = element.data();
          const id: string = element.id;
          return { id, ...data };
        });
        let data: any = {};
        for (const element of friendsArray) {
          let count: number = 1;
          if (!data[element.type]) {
            data[element.type] = {};
          }
          let temp = data[element.type][element.firstCharacter];
          if (temp && temp > 0) {
            count = temp + 1;
          }
          data[element.type][element.firstCharacter] = count;
          if (
            uid &&
            uid !== '' &&
            element &&
            element.type &&
            element.type !== '' &&
            element &&
            element.firstCharacter &&
            element.firstCharacter !== '' &&
            element &&
            element.id &&
            element.id !== ''
          ) {
            dataArray.push({
              string: `friends/${uid}/information/counters/alphabet/${element.type}/letters/${element.firstCharacter}/users/${element.id}`,
              data: { uid: element.id }
            });
          }
        }
        for (const type of Object.keys(data)) {
          if (type && type !== '') {
            dataArray.push({ string: `friends/${uid}/information/counters/alphabet/${type}`, data: { uid: type } });
          }
          for (const letter of Object.keys(data[type])) {
            if (uid && uid !== '' && type && type !== '' && letter && letter !== '') {
              dataArray.push({
                string: `friends/${uid}/information/counters/alphabet/${type}/letters/${letter}`,
                data: { count: Number(data[type][letter]) }
              });
            }
          }
        }
      }
      const LONG_ARRAY = 500;
      for (let i = 0; i < dataArray.length; i += LONG_ARRAY) {
        let piece = dataArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }
      let count1: number = 1;
      for (const array of newArray) {
        let PromiseArray: Promise<any>[] = [];
        for (const element of array) {
          PromiseArray.push(
            this.afs
              .doc(element.string)
              .set(element.data)
              .then(() => true)
              .catch(() => false)
          );
          console.log(`${count1}.- ${element}`);
        }
        const resp = await Promise.all(PromiseArray);
        console.log(resp);
        console.log(`${count1++}.- batch commit`);
      }
      console.log(`********* batch commit end *********`);
    } catch (error) {
      console.error(error);
    }
  }

  async updateFriendsCountersFilters(ids?: string[]) {
    console.log('Update Friends Counters Filters');
    let newArray: any[] = [];
    let dataArray: any[] = [];
    //
    try {
      let userArray: string[] = [];

      if (ids) {
        userArray = ids;
      } else {
        const resp = await this.afs.collection('friends').ref.get();
        userArray = resp.docs.map(e => e.id);
      }

      for (const uid of userArray) {
        const resp = await this.afs.collection(`friends/${uid}/friends`).ref.get();
        let filterMetaData = resp.docs.map(element => {
          const data: any = element.data();
          const id: string = element.id;
          return { id, ...data };
        });
        let data: any = {};
        for (const user of filterMetaData) {
          try {
            if (user && user['filter-meta-data'] && Array.isArray(user['filter-meta-data']) == true) {
              for (const filter of Array.from(user['filter-meta-data'])) {
                if (filter && filter !== '' && user && user.id) {
                  let count: number = 1;
                  if (!data[user.type]) {
                    data[user.type] = {};
                  }
                  let temp = data[user.type][String(filter)];
                  if (temp && temp > 0) {
                    count = temp + 1;
                  }
                  data[user.type][String(filter)] = count;
                  dataArray.push({
                    string: `friends/${uid}/information/counters/filters/type/${user.type}/${String(filter)}/users/${user.id
                      }`,
                    data: { uid: user.id }
                  });
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
        for (const type of Object.keys(data)) {
          for (const filter of Object.keys(data[type])) {
            if (filter && filter !== '') {
              let elementData: any = { count: Number(data[type][filter]) };
              if (type === 'representante-medico') {
                try {
                  const resp = await this.afs.doc(`companies/${String(filter)}`).ref.get();
                  const dataElement: any = resp.data();
                  const count: number = Number(data[type][filter]);
                  elementData = { count, ...dataElement };
                  console.log(elementData);
                } catch (error) {
                  console.error(error);
                }
              }
              dataArray.push({
                string: `friends/${uid}/information/counters/filters/type/${type}/${String(filter)}`,
                data: elementData
              });
            }
          }
        }
      }
      const LONG_ARRAY = 500;
      for (let i = 0; i < dataArray.length; i += LONG_ARRAY) {
        let piece = dataArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }
      let count1: number = 1;
      for (const array of newArray) {
        let PromiseArray: Promise<any>[] = [];
        for (const element of array) {
          PromiseArray.push(
            this.afs
              .doc(element.string)
              .set(element.data)
              .then(() => true)
              .catch(() => false)
          );
          console.log(`${count1}.- ${element}`);
        }
        const resp = await Promise.all(PromiseArray);
        console.log(resp);
        console.log(`${count1++}.- batch commit`);
      }
      console.log(`********* batch commit end *********`);
    } catch (error) {
      console.error(error);
    }
  }

  async updateFilterCounters() {
    console.log('Update filter Counters :)');
    let newArray: any[] = [];
    let dataArray: any[] = [];
    try {
      const resp = await this.afs.collection(`users`).ref.get();
      let filterMetaData = resp.docs.map(element => {
        const data: any = element.data();
        const id: string = element.id;
        return { id, ...data };
      });
      let data: any = {};
      for (const user of filterMetaData) {
        try {
          if (user && user['filter-meta-data'] && Array.isArray(user['filter-meta-data']) == true) {
            for (const filter of Array.from(user['filter-meta-data'])) {
              if (filter && filter !== '' && user && user.id) {
                let count: number = 1;
                if (!data[user.type]) {
                  data[user.type] = {};
                }
                let temp = data[user.type][String(filter)];
                if (temp && temp > 0) {
                  count = temp + 1;
                }
                data[user.type][String(filter)] = count;
                dataArray.push({
                  string: `information/counters/filters/type/${user.type}/${String(filter)}/users/${user.id}`,
                  data: { uid: user.id }
                });
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      for (const type of Object.keys(data)) {
        for (const filter of Object.keys(data[type])) {
          if (filter && filter !== '') {
            dataArray.push({
              string: `information/counters/filters/type/${type}/${filter}`,
              data: { count: Number(data[type][filter]) }
            });
          }
        }
      }
      const LONG_ARRAY = 500;
      for (let i = 0; i < dataArray.length; i += LONG_ARRAY) {
        let piece = dataArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }
      let count1: number = 1;
      for (const array of newArray) {
        let PromiseArray: Promise<any>[] = [];
        for (const element of array) {
          PromiseArray.push(
            this.afs
              .doc(element.string)
              .set(element.data)
              .then(() => true)
              .catch(() => false)
          );
          console.log(`${count1}.- ${element}`);
        }
        const resp = await Promise.all(PromiseArray);
        console.log(resp);
        console.log(`${count1++}.- batch commit`);
      }
      console.log(`********* batch commit end *********`);
    } catch (error) {
      console.error(error);
    }
  }

  async updateUUIDUsers() {
    try {
      const resp = await this.afs.collection(`users`).ref.get();
      let index: number = 1;
      for (const DOC of resp.docs) {
        if (DOC.exists === true) {
          await DOC.ref.update({ uuid: DOC.id, uid: DOC.id });
          console.log(`=== ${DOC.id} (${index++} of ${resp.size}) ===`);
        }
      }
      console.log('***** users collection updated *****');
    } catch (error) {
      console.error(error);
    }
  }

  async updateFriendsObject(info?: { relations: any[]; ids: string[] }) {
    try {
      console.log('Update Friends Object');

      let newArray: any[] = [];
      let rootArray: any[] = [];
      const resp = await this.afs
        .collection('contacts')
        .ref.where('status', '==', 'accepted')
        .get();

      let originalArray: any[] = [];

      if (info) {
        originalArray = info.relations;
      } else {
        originalArray = resp.docs.map(e => {
          const initiator = e.get('initiator');
          const requested = e.get('requested');
          return { initiator, requested };
        });
      }

      let dataArray: any[] = [];

      for (const element of originalArray) {
        let resp1;
        let person1: any;
        try {
          resp1 = await this.afs.doc(`users/${element.initiator}`).ref.get();
          person1 = resp1.data();
        } catch (error) {
          console.error(error);
        }
        let resp2;
        let person2: any;
        try {
          resp2 = await this.afs.doc(`users/${element.requested}`).ref.get();
          person2 = resp2.data();
        } catch (error) {
          console.error(error);
        }
        try {
          if (resp1 && resp1.id && resp2 && resp2.id && person2) {
            dataArray.push({ route: `friends/${resp1.id}/friends/${resp2.id}`, data: person2 });
          }
          if (resp2 && resp2.id && resp1 && resp1.id && person1) {
            dataArray.push({ route: `friends/${resp2.id}/friends/${resp1.id}`, data: person1 });
          }
          const index1: number = rootArray.map(e => e.route).indexOf(`friends/${resp1.id}`);
          const index2: number = rootArray.map(e => e.route).indexOf(`friends/${resp2.id}`);
          if (!(index1 > -1)) {
            if (resp1 && resp1.id) {
              rootArray.push({ route: `friends/${resp1.id}`, data: { uid: resp1.id } });
            }
          }
          if (!(index2 > -1)) {
            if (resp2 && resp2.id) {
              rootArray.push({ route: `friends/${resp2.id}`, data: { uid: resp2.id } });
            }
          }
        } catch (error) {
          console.error(error);
        }
      }

      dataArray = dataArray.concat(rootArray);
      console.log('lectura de datos completa...');

      const LONG_ARRAY = 500;
      for (let i = 0; i < dataArray.length; i += LONG_ARRAY) {
        let piece = dataArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }
      let count1: number = 1;
      for (const array of newArray) {
        let PromiseArray: Promise<any>[] = [];
        for (const element of array) {
          PromiseArray.push(
            this.afs
              .doc(element.route)
              .set(element.data, { merge: true })
              .then(() => true)
              .catch(() => false)
          );
          console.log(`${count1}.- ${element}`);
        }
        const resp = await Promise.all(PromiseArray);
        console.log(resp);
        console.log(`${count1++}.- batch commit`);
      }
      console.log(`********* batch commit end *********`);
    } catch (error) {
      console.error(error);
    }
  }

  async updateMyFriendsCounter(ids?: string[]): Promise<void> {
    console.log('Update my friends counter');
    try {
      let friendsArray: string[] = [];
      if (!ids) {
        const respF = await this.afs.collection('friends').ref.get();
        friendsArray = respF.docs.map(e => e.id);
      } else {
        friendsArray = ids;
      }
      for (const user of friendsArray) {
        const ref = this.afs.collection('friends').doc(user);
        const resp = await ref
          .collection('friends')
          .ref.where('status', '==', 'active')
          .get();
        await ref.set({ count: resp.size });
        console.log(`friends count (${user}) (${resp.size})`);
      }
      console.log(`********* batch commit end *********`);
    } catch (e) {
      console.error(e);
    }
  }

  async updateAlphabeticalCounters() {
    try {
      console.log('*** Update Alphabetical Counters ***');
      const resp = await this.afs.collection('users').ref.get();
      let newArray: any[] = [];
      let lettersArray: any[] = [];
      let letters: any = {};
      let originalArray: any[] = resp.docs
        .map(element => {
          const data: any = element.data();
          let newData: any;
          if (data && data.type && data.firstCharacter && data.type && element.id) {
            const ref: DocumentReference = this.afs
              .collection(`information/counters/alphabet/${data.type}/letters/${data.firstCharacter}/users`)
              .doc(element.id).ref;
            newData = {
              data: {
                nameStr: data && data.nameStr ? data.nameStr : '',
                status: data && data.status ? data.status : '',
                type: data.type,
                'filter-meta-data': data && data['filter-meta-data'] ? data['filter-meta-data'] : []
              },
              ref: ref,
              type: data.type,
              firstCharacter: data.firstCharacter
            };
          }
          return newData;
        })
        .filter(e => {
          if (e) {
            return e;
          }
        });

      for (const element of originalArray) {
        const index1: number = lettersArray.map(e => e.type).indexOf(element.type);
        const index2: number = lettersArray.map(e => e.firstCharacter).indexOf(element.firstCharacter);
        if (!(index1 > -1) && !(index2 > -1)) {
          letters[element.type] = {};
          lettersArray.push({ type: element.type, firstCharacter: element.firstCharacter });
        }
      }

      const batchLetter = this.afs.firestore.batch();

      for (const element of lettersArray) {
        const ref: DocumentReference = this.afs.collection(`information/counters/alphabet`).doc(element.type).ref;
        batchLetter.set(ref, { uid: element.type });
      }

      await batchLetter.commit();
      console.log(`********* batch(1) commit end *********`);

      for (const element of originalArray) {
        letters[element.type][element.firstCharacter] = { count: 0 };
      }

      let newLetters: any[] = [];
      Object.keys(letters).forEach(e => {
        Object.keys(letters[e]).forEach(ee => {
          newLetters.push({ type: e, firstCharacter: ee, count: 0 });
        });
      });

      newLetters = newLetters.map(element => {
        let data: any = element;
        const count: number = originalArray.filter(element2 => {
          if (element2.type === data.type && element2.firstCharacter === data.firstCharacter) {
            return element2;
          }
        }).length;
        data.count = count;
        return data;
      });
      let promises1: Promise<any>[] = [];

      for (const element of newLetters) {
        const ref: DocumentReference = this.afs
          .collection(`information/counters/alphabet/${element.type}/letters`)
          .doc(element.firstCharacter).ref;
        promises1.push(
          ref
            .set({ count: element.count })
            .then(() => {
              return true;
            })
            .catch(e => {
              return e;
            })
        );
      }

      await Promise.all(promises1);
      console.log(`********* batch(2) commit end *********`);

      const LONG_ARRAY = 500;
      for (let i = 0; i < originalArray.length; i += LONG_ARRAY) {
        let piece = originalArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }

      let count1: number = 1;

      for (const array of newArray) {
        let promises: Promise<any>[] = [];
        for (const element of array) {
          promises.push(
            element.ref
              .set(element.data)
              .then(() => {
                return true;
              })
              .catch((e: any) => {
                return e;
              })
          );
          console.log(`${count1}.- ${element}`);
        }
        await Promise.all(promises);
        console.log(`${count1++}.- batch commit`);
      }
      console.log(`********* All batchs commits end *********`);
    } catch (error) {
      console.error(error);
    }
  }

  async productsAssignTypeAttribute() {
    console.log('Product Assign Type Attribute');
    const loading = await this.loadingController.create({
      message: 'Obteniendo Información'
    });
    await loading.present();
    this.afs
      .collection(`products`)
      .get()
      .subscribe(async snap => {
        console.log(snap);
        const products: any = [];
        for (const product of snap.docs) {
          products.push({ id: product.id, ...product.data() as any });
        }

        let i = 0;
        for (const product of products) {
          i = i + 1;
          loading.message = `Revisando: ${i} de ${products.length}`;
          if (!product.type) {
            loading.message = `Actualizando: ${i} de ${products.length}`;
            await this.afs
              .doc(`products/${product.id}`)
              .update({ type: 'Medicamento' })
              .then(() => {
                console.log('Producto' + product.id + ' Actualizado');
              })
              .catch(error => {
                console.log(error);
              });
          }
        }
        loading.message = `${products.length} productos revisados`;
        setTimeout(() => {
          loading.dismiss();
        }, 1000);
      });
  }

  async productMuestraToNumber() {
    const loading = await this.loadingController.create({
      message: 'Obteniendo Información'
    });
    await loading.present();

    const muestra1 = await this.afs
      .collection('products')
      .ref.where('se_cuenta_con_muestra', '==', '1')
      .get();
    const muestra0 = await this.afs
      .collection('products')
      .ref.where('se_cuenta_con_muestra', '==', '0')
      .get();

    const total = muestra1.docs.length + muestra0.docs.length;
    let actual = 1;

    for (const product of muestra1.docs) {
      loading.message = `Actualizando producto ${actual} / ${total}`;
      actual = actual + 1;
      await this.afs.doc(`products/${product.id}`).update({ se_cuenta_con_muestra: 1 });
    }
    for (const product of muestra0.docs) {
      loading.message = `Actualizando producto ${actual} / ${total}`;
      actual = actual + 1;
      await this.afs.doc(`products/${product.id}`).update({ se_cuenta_con_muestra: 0 });
    }

    loading.message = `Se actualizaron un total de ${total} productos`;
    setTimeout(() => {
      loading.dismiss();
    }, 1500);
  }

  async delteContacts() {
    this.presentAlertPrompt();
  }

  async allEmails() {
    const emails: string[] = (
      await this.afs
        .collection('users')
        .ref.where('status', '==', 'active')
        .where('type', '==', 'medico')
        .get()
    ).docs.map(e => String(e.get('email')));
    console.log(JSON.stringify(emails));
  }

  async delteMyContacts(email: string) {
    let newArray: any[] = [];
    const snap = await this.afs
      .collection('users')
      .ref.where('email', '==', email)
      .get();
    if (snap.empty === false) {
      const id: string = String(snap.docs[0].id);
      const snap2 = await this.afs
        .collection('contacts')
        .ref.where('participants', 'array-contains', id)
        .get();
      const contacts: any[] = snap2.docs.map(e => e.ref);
      console.log(`*** Size: ${contacts.length} ***`);
      const LONG_ARRAY = 500;
      for (let i = 0; i < contacts.length; i += LONG_ARRAY) {
        let piece = contacts.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }
      let count1: number = 1;
      for (const array of newArray) {
        const batch = this.afs.firestore.batch();
        for (const element of array) {
          batch.delete(element);
          console.log(`${count1}.- ${element}`);
        }
        await batch.commit();
        console.log(`${count1++}.- batch commit`);
      }
      await this.afs
        .collection('friends')
        .doc(id)
        .delete();
      console.log(`********* All batchs commits end *********`);
    } else {
      alert('No se encontraron resultados.');
    }
  }

  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Borrar contactos',
      inputs: [
        {
          name: 'email',
          type: 'text',
          placeholder: 'Email de usuario'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Borrar contactos',
          handler: data => {
            this.delteMyContacts(data.email);
          }
        }
      ]
    });

    await alert.present();
  }

  async updateLiveChatsDoc() {
    try {
      const RESP = await this.afs.collection('live-chats').ref.get();

      let baseArray: any[] = RESP.docs.map(item => {
        let data: any = item.data();
        const ID: string = item.id;
        data.identifier = String(data.identifier);
        return { id: ID, ...data };
      });

      let originalArray: any[] = [];
      let allDeletes: any[] = [];
      let allCreates: any[] = [];
      let uniqueArray: any[] = [];

      for (const DOC1 of baseArray) {
        const RESP1 = await this.afs
          .collection('live-chats')
          .doc(DOC1.id)
          .collection('messages')
          .ref.get();
        const MESSAGE1 = RESP1.docs.map(item1 => {
          const DATA1: any = { identifier: DOC1.identifier, ...item1.data() };
          const ID1: string = item1.id;
          return { id: ID1, ...DATA1 };
        });
        DOC1.messages = MESSAGE1;
      }

      for (const DOC1 of baseArray) {
        const ID = String(DOC1.id);
        const REF = this.afs.doc(`live-chats/${ID}`).ref;
        allDeletes.push({ operation: 'delete', ref: REF });
        for (const DOC2 of DOC1.messages) {
          const REF = this.afs.doc(`live-chats/${ID}/messages/${String(DOC2.id)}`).ref;
          allDeletes.push({ operation: 'delete', ref: REF });
        }
      }

      for (const DOC1 of baseArray) {
        const INDEX: number = uniqueArray.map(e => e.identifier).indexOf(DOC1.identifier);
        if (INDEX > -1) {
          //concat
          const MESSAGES = Array.from(DOC1.messages);
          uniqueArray[INDEX].messages = Array.from(uniqueArray[INDEX].messages).concat(MESSAGES);
          console.log('=== Identifier ===', DOC1.identifier);
        } else {
          //push
          const MESSAGES = Array.from(DOC1.messages);
          uniqueArray.push({ date: DOC1.date, identifier: DOC1.identifier, messages: MESSAGES });
        }
      }

      for (const DOC1 of uniqueArray) {
        const ID = String(DOC1.identifier);
        const REF = this.afs.doc(`live-chats/${ID}`).ref;
        allCreates.push({ operation: 'create', ref: REF, data: { date: DOC1.date } });
        for (const DOC2 of DOC1.messages) {
          const REF = this.afs.doc(`live-chats/${ID}/messages/${String(DOC2.id)}`).ref;
          allCreates.push({
            operation: 'create',
            ref: REF,
            data: {
              date: DOC2.date,
              member: DOC2.member || {},
              message: DOC2.message
            }
          });
        }
      }

      let newArray: any[] = [];
      originalArray = Array.from(allDeletes).concat(Array.from(allCreates));

      const LONG_ARRAY = 500;
      for (let i = 0; i < originalArray.length; i += LONG_ARRAY) {
        let piece = originalArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }

      let count1: number = 1;
      for (const array of newArray) {
        const batch = this.afs.firestore.batch();
        for (const element of array) {
          if (element && element.operation === 'create') {
            batch.set(element.ref, element.data);
          } else {
            batch.delete(element.ref);
          }
          console.log(`${count1}.- ${element}`);
        }
        await batch.commit();
        console.log(`${count1++}.- batch commit`);
      }
      console.log(`********* batch commit end *********`, originalArray);
    } catch (error) {
      console.error(error);
    }
  }

  async updateChatsObject() {
    try {
      console.log('***** Update chats object *****');
      const resp = await this.afs.collection('chats').ref.get();
      let newArray: any[] = [];
      const originalArray: any[] = resp.docs.map(element => {
        const last_message: any = element.get('last_message');
        const USER = last_message.user;
        const PARTICIPANTS = element.get('members');
        let participants: string[] = Array.from(PARTICIPANTS).map((e: any) => e.uid);
        const INDEX: number = participants.indexOf(USER);
        if (INDEX > -1) {
          participants.splice(INDEX, 1);
        }
        const ref: DocumentReference = this.afs.collection('chats').doc(element.id).ref;
        return { receiver: participants[0], ref: ref };
      });
      const LONG_ARRAY = 500;
      for (let i = 0; i < originalArray.length; i += LONG_ARRAY) {
        let piece = originalArray.slice(i, i + LONG_ARRAY);
        newArray.push(piece);
      }
      let count1: number = 1;
      for (const array of newArray) {
        const batch = this.afs.firestore.batch();
        for (const element of array) {
          batch.update(element.ref, { 'last_message.receiver': element.receiver });
          console.log(`${count1}.- ${element}`);
        }
        await batch.commit();
        console.log(`${count1++}.- batch commit`);
      }
      console.log(`********* batch commit end *********`);
    } catch (error) {
      console.error(error);
    }
  }
  // BATCH PLANS

  private BATCH_LIMIT: number = 200;

  async allPlanIDRepsToDefaultPlan(planID: string) {
    try {
      console.log('***** All reps to default plan from other *****');

      const RESP1 = await this.afs
        .collection('plans')
        .ref.where('default', '==', true)
        .get();

      const ARRAY_PLAN = RESP1.docs.map(element => {
        const DATA: any = element.data();
        const ID: string = element.id;
        return { id: ID, ...DATA };
      });

      const DEFAULT_PLAN = ARRAY_PLAN && ARRAY_PLAN[0] ? ARRAY_PLAN[0].id : undefined;

      if (DEFAULT_PLAN) {
        const RESP = await this.afs
          .collection('users')
          .ref.where('type', '==', 'representante-medico')
          .where('status', '==', 'active')
          .where('uid', 'not-in', DEFAULT_USERS_CONNECTIONS_IDS)
          .where('plan', '==', planID)
          .get();

        const USERS = RESP.docs.map(user => {
          const ID: string = user.id;
          const DATA: any = user.data();
          return { id: ID, ...DATA };
        });
        await this.updatePlanRep(DEFAULT_PLAN, 'array', USERS);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async singleRepToDefaultPlan(repID: string) {
    try {
      console.log('***** Single rep to default plan from other *****');

      const RESP1 = await this.afs
        .collection('plans')
        .ref.where('default', '==', true)
        .get();

      const ARRAY_PLAN = RESP1.docs.map(element => {
        const DATA: any = element.data();
        const ID: string = element.id;
        return { id: ID, ...DATA };
      });

      const DEFAULT_PLAN = ARRAY_PLAN && ARRAY_PLAN[0] ? ARRAY_PLAN[0].id : undefined;

      if (DEFAULT_PLAN) {
        const RESP = await this.afs
          .collection('users')
          .ref.where('type', '==', 'representante-medico')
          .where('status', '==', 'active')
          .where('uid', 'not-in', DEFAULT_USERS_CONNECTIONS_IDS)
          .where('uid', '==', repID)
          .get();

        const USERS = RESP.docs.map(user => {
          const ID: string = user.id;
          const DATA: any = user.data();
          return { id: ID, ...DATA };
        });

        await this.updatePlanRep(DEFAULT_PLAN, 'array', USERS);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async allRepsToDefaultPlan() {
    try {
      console.log('***** All reps to default plan *****');

      const RESP1 = await this.afs
        .collection('plans')
        .ref.where('default', '==', true)
        .get();

      const ARRAY_PLAN = RESP1.docs.map(element => {
        const DATA: any = element.data();
        const ID: string = element.id;
        return { id: ID, ...DATA };
      });

      const DEFAULT_PLAN = ARRAY_PLAN && ARRAY_PLAN[0] ? ARRAY_PLAN[0].id : undefined;

      if (DEFAULT_PLAN) {
        await this.updatePlanRep(DEFAULT_PLAN, 'all-reps');
      }
    } catch (error) {
      console.error(error);
    }
  }

  async updateSearchV2() {
    return await this.firebaseService.callFunctionFB('updateSeachUserObject', {});
  }

  async updatePostsSearch() {
    return await this.firebaseService.callFunctionFB('updatePostsSearch', {});
  }

  async updateProductsSearch() {
    return await this.firebaseService.callFunctionFB('updateProductsSearch', {});
  }

  later(delay: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, delay);
    });
  }

  async updatePlanRep(planID: string, type: 'id' | 'all-reps' | 'array', array_ids?: any[]) {
    console.log('======================================== updatePlanRep ========================================');
    console.log('Plan:', planID);
    console.log('Tipo:', type);
    console.log('Array:', array_ids);
    try {
      let maxContacts: number;
      let max_contacts_limit: boolean = true;
      let maxProducts: number;
      let max_products_limit: boolean = true;

      const PLAN: any = (await this.afs.doc(`plans/${planID}`).ref.get()).data();

      if (PLAN && PLAN.max_contacts_limit === true) {
        maxContacts = PLAN && PLAN.max_contacts ? Number(PLAN.max_contacts) : 100;
      } else {
        max_contacts_limit = false;
      }

      if (PLAN && PLAN.max_products_limit === true) {
        maxProducts = PLAN && PLAN.max_products ? Number(PLAN.max_products) : 3;
      } else {
        max_products_limit = false;
      }

      let resp: QuerySnapshot<DocumentData>;

      if (type === 'all-reps') {
        resp = await this.afs
          .collection('users')
          .ref.where('type', '==', 'representante-medico')
          .where('status', '==', 'active')
          .where('uid', 'not-in', DEFAULT_USERS_CONNECTIONS_IDS)
          .get();
      } else if (type === 'id') {
        resp = await this.afs
          .collection('users')
          .ref.where('type', '==', 'representante-medico')
          .where('status', '==', 'active')
          .where('plan', '==', planID)
          .where('uid', 'not-in', DEFAULT_USERS_CONNECTIONS_IDS)
          .get();
      }

      let users: any[];

      if (array_ids && type === 'array') {
        users = array_ids;
      } else {
        users = resp.docs.map(user => {
          const ID: string = user.id;
          const DATA: any = user.data();
          return { id: ID, ...DATA };
        });
      }

      console.log('============= users =============', users);

      for (const rep of users) {
        await this.plan_contacts(rep.id, maxContacts, max_contacts_limit);
        console.log(`=============== plan_contacts ===============`);
        await this.plan_chats(rep.id, maxContacts, max_contacts_limit);
        console.log(`=============== plan_chats ===============`);
        await this.plan_productos(rep.id, maxProducts, max_products_limit);
        console.log(`=============== plan_productos ===============`);
        await this.afs.doc(`users/${rep.id}`).update({ plan: planID });
        console.log(`
        **************************** REP: ${rep.id} ****************************
        `);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private async plan_contacts(repID: string, maxContacts: number, max_contacts_limit: boolean) {
    try {
      if (max_contacts_limit === true) {
        await this.limited_contacts(repID, true, maxContacts);
      } else {
        await this.limited_contacts(repID, false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private async plan_chats(repID: string, maxContacts: number, max_contacts_limit: boolean) {
    try {
      if (max_contacts_limit === true) {
        await this.limited_chats(repID, true, maxContacts);
      } else {
        await this.limited_chats(repID, false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private async plan_productos(repID: string, maxProducts: number, max_products_limit: boolean) {
    try {
      if (max_products_limit === true) {
        await this.limited_products(repID, true, maxProducts);
      } else {
        await this.limited_products(repID, false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private async limited_contacts(repID: string, limited: boolean, maxContacts?: number) {
    let pointer;
    let counter: number = maxContacts;

    do {
      try {
        let resp: QuerySnapshot<DocumentData>;
        if (pointer) {
          resp = await this.afs
            .collection(`friends/${repID}/friends`)
            .ref.startAfter(pointer)
            .limit(this.BATCH_LIMIT)
            .get();
        } else {
          resp = await this.afs
            .collection(`friends/${repID}/friends`)
            .ref.limit(this.BATCH_LIMIT)
            .get();
        }

        const DOCS = resp.docs;

        try {
          pointer = DOCS[DOCS.length - 1] || undefined;
        } catch (e) {
          pointer = undefined;
          console.error(e);
        }

        const BATCH = this.afs.firestore.batch();

        if (limited === true) {
          for (const DOC of DOCS) {
            if (counter > 0) {
              BATCH.update(DOC.ref, { status: 'active' });
              counter--;
            } else {
              BATCH.update(DOC.ref, { status: 'inactive' });
            }
          }
        } else {
          for (const DOC of DOCS) {
            BATCH.update(DOC.ref, { status: 'active' });
          }
        }

        try {
          await BATCH.commit();
        } catch (e) {
          console.error(e);
        }

        await this.later(50);
      } catch (e) {
        console.error(e);
      }
      await this.later(50);
    } while (pointer);

    try {
    } catch (e) {
      console.error(e);
    }
  }

  private async limited_chats(repID: string, limited: boolean, maxContacts?: number) {
    let pointer;
    let counter: number = maxContacts;

    do {
      try {
        let resp: QuerySnapshot<DocumentData>;
        if (pointer) {
          resp = await this.afs
            .collection('chats')
            .ref.where('participants', 'array-contains', repID)
            .orderBy('last_message.date', 'desc')
            .startAfter(pointer)
            .limit(this.BATCH_LIMIT)
            .get();
        } else {
          resp = await this.afs
            .collection('chats')
            .ref.where('participants', 'array-contains', repID)
            .orderBy('last_message.date', 'desc')
            .limit(this.BATCH_LIMIT)
            .get();
        }

        const DOCS = resp.docs;

        try {
          pointer = DOCS[DOCS.length - 1] || undefined;
        } catch (e) {
          pointer = undefined;
          console.error(e);
        }

        const BATCH = this.afs.firestore.batch();

        if (limited === true) {
          for (const DOC of DOCS) {
            if (counter > 0) {
              BATCH.update(DOC.ref, { initialized: true });
              counter--;
            } else {
              BATCH.update(DOC.ref, { initialized: false });
            }
          }
        } else {
          for (const DOC of DOCS) {
            BATCH.update(DOC.ref, { initialized: true });
          }
        }

        try {
          await BATCH.commit();
        } catch (e) {
          console.error(e);
        }

        await this.later(50);
      } catch (e) {
        console.error(e);
      }
      await this.later(50);
    } while (pointer);

    try {
    } catch (e) {
      console.error(e);
    }
  }

  private async limited_products(repID: string, limited: boolean, maxProducts?: number) {
    let pointer;
    let counter: number = maxProducts;

    do {
      try {
        let resp: QuerySnapshot<DocumentData>;
        if (pointer) {
          resp = await this.afs
            .collection(`products`)
            .ref.where('uid', '==', repID)
            .orderBy('date', 'desc')
            .startAfter(pointer)
            .limit(this.BATCH_LIMIT)
            .get();
        } else {
          resp = await this.afs
            .collection(`products`)
            .ref.where('uid', '==', repID)
            .orderBy('date', 'desc')
            .limit(this.BATCH_LIMIT)
            .get();
        }

        const DOCS = resp.docs;

        try {
          pointer = DOCS[DOCS.length - 1] || undefined;
        } catch (e) {
          pointer = undefined;
          console.error(e);
        }

        const BATCH = this.afs.firestore.batch();

        if (limited === true) {
          for (const DOC of DOCS) {
            if (counter > 0) {
              BATCH.update(DOC.ref, { status: 'active' });
              counter--;
            } else {
              BATCH.update(DOC.ref, { status: 'inactive' });
            }
          }
        } else {
          for (const DOC of DOCS) {
            BATCH.update(DOC.ref, { status: 'active' });
          }
        }

        try {
          await BATCH.commit();
        } catch (e) {
          console.error(e);
        }

        await this.later(50);
      } catch (e) {
        console.error(e);
      }
      await this.later(50);
    } while (pointer);

    try {
    } catch (e) {
      console.error(e);
    }
  }

  async getDocsCollection(params: DocsCollection): Promise<QuerySnapshot<DocumentData>> {
    try {
      let query: Query = this.afs.collection(params.path).ref;

      if (params.where) {
        for (let item of params.where) {
          if (item.value) {
            query = query.where(item.fieldPath, item.opStr, item.value);
          }
        }
      }

      if (params.orderBy) {
        query = query.orderBy(params.orderBy.fieldPath, params.orderBy.directionStr);
      }

      if (params.limit) {
        query = query.limit(params.limit);
      }

      if (params.startAt) {
        query = query.startAt(params.startAt);
      }

      if (params.endBefore) {
        query = query.endBefore(params.endBefore);
      }

      if (params.startAfter) {
        query = query.startAfter(params.startAfter);
      }

      if (params.endAt) {
        query = query.endAt(params.endAt);
      }

      return await query.get();
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
