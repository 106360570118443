<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span >Home</span></ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card class="ion-text-center">
    <img class="logo" src="assets/logo-512.jpg" alt="Conectimed" />
    <ion-card-content>
      <ion-card-title>
        <span >Conectimed Admin</span>
      </ion-card-title>
    </ion-card-content>
  </ion-card>
</ion-content>
