import { Injectable } from '@angular/core';
import { WP_JSON_URL } from '../../../environments/environment';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { CredentialsService, Credentials } from '../../core/authentication/credentials.service';
import { Observable } from 'rxjs';
import { Logger } from '../../core/logger.service';
import { UtilitiesService } from './utilities.service';
const log = new Logger('Login');

export interface FilterInterface {
  value: any[];
  key: string;
}

interface WpTokenInterface {
  token: string;
  user_email: string;
  user_nicename: string;
  user_display_name: string;
}

@Injectable({
  providedIn: 'root'
})
export class WpService {
  private currentPage: number = 1;
  private totalPages: number = 0;
  private isWeb: boolean = true;

  constructor(private utilities: UtilitiesService, private http: HttpClient, private credentials: CredentialsService) { }

  public getTags(page: number, search: string = null) {
    let url = `${WP_JSON_URL}wp-json/wp/v2/tags?per_page=${100}&page=${page}&order=asc`;
    if (search) {
      url = url + `&search=${search}`;
    }

    return this.http.get(url, { observe: 'response' }).toPromise();
  }

  public getSession(): Credentials {
    return this.credentials.credentials;
  }

  public activateMember(id: number): Promise<HttpResponse<any>> {
    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    const url = `${WP_JSON_URL}activate_member`;
    return this.http.post<any>(url, { uid: id, isAdmin: true }, { observe: 'response', headers: headers }).toPromise();
  }

  public async token(email: string, password: string): Promise<HttpResponse<WpTokenInterface>> {
    const url: string = `${WP_JSON_URL}wp-json/jwt-auth/v1/token`;
    const body = {
      username: email,
      password: password
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .post<any>(url, body, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async memberMe(token: string, context?: string): Promise<HttpResponse<any>> {
    let url: string = `${WP_JSON_URL}wp-json/buddypress/v1/members/me`;
    url = this.utilities.setUrlParameter(url, 'context', context);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async userMe(token: string, context?: string): Promise<HttpResponse<any>> {
    let url: string = `${WP_JSON_URL}wp-json/wp/v2/users/me`;
    url = this.utilities.setUrlParameter(url, 'context', context);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async member(id: number, context?: string): Promise<HttpResponse<any>> {
    let url: string = `${WP_JSON_URL}wp-json/buddypress/v1/members/${id}`;
    url = this.utilities.setUrlParameter(url, 'context', context);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async createNotification(
    user_id: number,
    item_id: number,
    component: string,
    action: string
  ): Promise<HttpResponse<any>> {
    const url = `${WP_JSON_URL}create_notification/`;
    const body: any = {
      user_id: user_id,
      item_id: item_id,
      secondary_item_id: 0,
      component: component,
      action: action
    };
    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      Authorization: `Bearer ${this.getSession().token}`
    });
    return this.http.post(url, body, { observe: 'response', headers: headers }).toPromise();
  }

  async sendFriendShip(default_user: number, friend_id?: number): Promise<any> {
    let url: string = `${WP_JSON_URL}create_friendships`;
    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    let body = {
      isAdmin: true,
      friend_id: default_user,
      request_user_id: friend_id
    };
    return this.http.post(url, body, { observe: 'body', headers: headers }).toPromise();
  }

  public async createActivity(userId: number, content: string): Promise<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    let url: string = `${WP_JSON_URL}wp-json/buddypress/v1/activity`;
    const body = {
      user_id: userId,
      title: content,
      content: content,
      hidden: false
    };
    return this.http
      .post<any>(url, body, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async members(
    per_page?: number,
    page?: number,
    member_type?: string,
    type?: string,
    user_id?: string,
    search?: string,
    exclude?: number[]
  ): Promise<HttpResponse<any>> {
    let url: string = `${WP_JSON_URL}wp-json/buddypress/v1/members/`;
    url = this.utilities.setUrlParameter(url, 'member_type', member_type);
    url = this.utilities.setUrlParameter(url, 'per_page', per_page);
    url = this.utilities.setUrlParameter(url, 'page', page);
    url = this.utilities.setUrlParameter(url, 'type', type);
    url = this.utilities.setUrlParameter(url, 'user_id', user_id);
    url = this.utilities.setUrlParameter(url, 'search', search);
    url = this.utilities.setUrlParameter(url, 'exclude', exclude);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async members2(
    type: string,
    page: number,
    perPage: number,
    search?: string,
    state?: string,
    speciality?: number,
    company?: string
  ): Promise<any> {
    perPage;
    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    let url: string = `${WP_JSON_URL}get-select_custom_taxonomy?type=${type}`;
    url = this.utilities.setUrlParameter(url, 'pag', page);
    url = this.utilities.setUrlParameter(url, 'state', state);
    url = this.utilities.setUrlParameter(url, 'speciality', speciality);
    url = this.utilities.setUrlParameter(url, 'company', company);
    url = this.utilities.setUrlParameter(url, 'search', search);
    return this.http
      .get<any>(url, { observe: 'body', headers: headers })
      .toPromise();
  }

  public async content(
    route: string,
    perPage?: number,
    page?: number,
    orderBy?: string,
    order?: string,
    filter?: FilterInterface[],
    search?: string
  ): Promise<HttpResponse<any>> {
    if (page) {
      this.currentPage = page;
    } else {
      this.currentPage = 1;
    }
    let url: string = route ? `${WP_JSON_URL}wp-json/wp/v2/${route}` : `${WP_JSON_URL}wp-json/wp/v2/posts`;
    url = this.utilities.setUrlParameter(url, 'per_page', perPage);
    url = this.utilities.setUrlParameter(url, 'page', page);
    url = this.utilities.setUrlParameter(url, 'order', order);
    if (filter) {
      let filterValue: string = '';
      filter.forEach(element => {
        element.value.forEach((filter, index) => {
          if (index == 0) {
            filterValue = filter;
          } else {
            filterValue += ',' + filter;
          }
        });
        url = this.utilities.setUrlParameter(url, element.key, filterValue);
      });
    }
    url = this.utilities.setUrlParameter(url, 'search', search);
    url = this.utilities.setUrlParameter(url, 'orderby', orderBy);

    return this.http
      .get<any>(url, { observe: 'response' })
      .toPromise();
  }

  async getSpecialties(): Promise<any> {
    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    const url: string = `${WP_JSON_URL}get_specialties_in_use`;
    return await this.http.get(url, { observe: 'body', headers: headers }).toPromise();
  }

  public getCurrentPage(): number {
    return this.currentPage;
  }

  async checkIsMyFriend(userid?: number): Promise<boolean> {
    const myFriends = await this.members(100, 1, '', '', String(this.credentials.credentials.uid));
    return Array.from(myFriends.body)
      .map((e: any) => e.id)
      .indexOf(Number(userid)) > -1
      ? true
      : false;
  }

  public async messagesByUser(user_id?: number, box?: string): Promise<HttpResponse<any>> {
    let url: string = `${WP_JSON_URL}wp-json/buddypress/v1/messages`;
    url = this.utilities.setUrlParameter(url, 'user_id', user_id);
    url = this.utilities.setUrlParameter(url, 'box', box);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async startNewChat(recipients: number[], sender_id: number, message?: string): Promise<HttpResponse<any>> {
    const url: string = `${WP_JSON_URL}wp-json/buddypress/v1/messages`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    const body = {
      message: message ? message : 'Inicio de chat',
      recipients: recipients,
      sender_id: sender_id
    };
    return this.http
      .post<any>(url, body, { observe: 'response', headers: headers })
      .toPromise();
  }

  async getInvites(uid: number, page: number, per_page: number, confirmed?: string, type?: string): Promise<any> {
    let url: string = `${WP_JSON_URL}get_invites/?uid=${uid}`;

    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    url = this.utilities.setUrlParameter(url, 'pag', page);
    url = this.utilities.setUrlParameter(url, 'per_page', per_page);
    url = this.utilities.setUrlParameter(url, 'confirmed', confirmed);
    url = this.utilities.setUrlParameter(url, 'type', type);
    return this.http.get(url, { observe: 'body', headers: headers }).toPromise();
  }

  async deleteFriendShip(friendship_id: number, type?: string): Promise<any> {
    let url: string = `${WP_JSON_URL}reject_friendships`;

    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    let body: any = {
      friendship_id: friendship_id,
      isAdmin: true
    };
    if (type) {
      body['type'] = type;
    }
    return this.http.post(url, body, { observe: 'body', headers: headers }).toPromise();
  }

  async deleteFriendShipV2(initiator_user_id: number, friendship_id: number, type?: string): Promise<any> {
    let url: string = `${WP_JSON_URL}reject_friendships`;
    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    let body: any = {
      friendship_id: Number(friendship_id),
      initiator_user_id: Number(initiator_user_id),
      isAdmin: true
    };
    if (type) {
      body.type = type;
    }
    return this.http.post(url, body, { observe: 'body', headers: headers }).toPromise();
  }

  async validateAntiquityFriendship(friendshipId: number): Promise<any> {
    let url: string = `${WP_JSON_URL}validate_antiquity_friendship`;

    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    let body = {
      friendship_id: friendshipId
    };
    return this.http.post(url, body, { observe: 'body', headers: headers }).toPromise();
  }
  public async getMemberPoints(): Promise<number> {
    let points: number = 0;

    const data = await this.custumer(Number(this.getSession().uid));
    const array: any = Array.from(data.body.meta_data);
    const index = array.map((e: any) => e.key).indexOf('hp_woo_rewards_points');
    if (index > -1) {
      points = parseFloat(array[index].value);
    }
    return points;
  }

  public async custumer(userId: number): Promise<HttpResponse<any>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getSession().token}`
    });
    const url = `${WP_JSON_URL}wp-json/wc/v3/customers/${userId}`;
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  async acceptFriendships(initiator_id: number, friendship_id: number): Promise<any> {
    let url: string = `${WP_JSON_URL}accept_friendships`;
    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    const body = {
      friendship_id: friendship_id,
      isAdmin: true,
      initiator_id: initiator_id
    };
    return this.http.post(url, body, { observe: 'body', headers: headers }).toPromise();
  }

  public async single(route: string, id: number): Promise<HttpResponse<any>> {
    const url: string = `${WP_JSON_URL}wp-json/wp/v2/${route}/${id}`;
    let headers = {};
    if (this.getSession().token) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getSession().token}`
      });
    }
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async email(to: string, subject: string, htmlBody: string, token?: string): Promise<HttpResponse<any>> {
    const url = `${WP_JSON_URL}send_email`;
    let headers: any;
    if (token) {
      headers = new HttpHeaders({
        AuthSafari: `Bearer ${token}`,
        Authorization: `Bearer ${token}`
      });
    } else {
      headers = new HttpHeaders({
        AuthSafari: `Bearer ${this.getSession().token}`,
        Authorization: `Bearer ${this.getSession().token}`
      });
    }
    const body = {
      to: to,
      subject: subject,
      body: htmlBody
    };
    return this.http
      .post<any>(url, body, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async getBpXprofileField(fieldId: number, userId: number, token: string): Promise<HttpResponse<any>> {
    const url: string = `${WP_JSON_URL}wp-json/buddypress/v1/xprofile/${fieldId}/data/${userId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  async getStates(): Promise<any> {
    const headers = new HttpHeaders({
      AuthSafari: `Bearer ${this.getSession().token}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    const url: string = `${WP_JSON_URL}get_states_in_use`;
    return this.http.get(url, { observe: 'body', headers: headers }).toPromise();
  }

  async getCompanies(): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    const url: string = `${WP_JSON_URL}get_companies_in_use`;
    return this.http.get(url, { observe: 'body', headers: headers }).toPromise();
  }

  public async uploadFile(file: File): Promise<HttpResponse<any>> {
    let formData = new FormData();
    const url = `${WP_JSON_URL}wp-json/wp/v2/media`;
    formData.append('file', file, file.name);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getSession().token}`
    });
    return this.http
      .post<any>(url, formData, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async message(id: number): Promise<HttpResponse<any>> {
    const url: string = `${WP_JSON_URL}wp-json/buddypress/v1/messages/${id}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async getMedia(id: number): Promise<HttpResponse<any>> {
    const url: string = `${WP_JSON_URL}wp-json/wp/v2/media/${id}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async sendMessage(message: {
    id?: number;
    message?: string;
    recipients?: number[];
    sender_id?: number;
  }): Promise<HttpResponse<any>> {
    const url: string = `${WP_JSON_URL}wp-json/buddypress/v1/messages`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    return this.http
      .post<any>(url, message, { observe: 'response', headers: headers })
      .toPromise();
  }

  public async deleteSingle(id: number, route: string): Promise<HttpResponse<any>> {
    let url: string = `${WP_JSON_URL}wp-json/wp/v2/${route}/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getSession().token}`
    });
    return this.http
      .delete<any>(url, { observe: 'body', headers: headers, params: { force: 'true' } })
      .toPromise();
  }

  async isMyFriend(uid: number, id_friend: number): Promise<any> {
    let url: string = `${WP_JSON_URL}is_friend/?uid=${uid}&friend_id=${id_friend}`;
    const session = this.getSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session.token}`
    });
    return this.http
      .get<any>(url, { observe: 'response', headers: headers })
      .toPromise();
  }

  async checkIsMyFriend2(uid: number, id_friend: number): Promise<boolean> {
    const myFriends = await this.isMyFriend(uid, id_friend);
    const isFriend: boolean =
      myFriends &&
        myFriends.body &&
        myFriends.body.response &&
        myFriends.body.response.friend_status &&
        myFriends.body.response.friend_status === 'is_friend'
        ? true
        : false;
    return isFriend;
  }

  public saveJsonCsv(jsonCsv: any): Promise<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = { token: this.getSession().token, rows: jsonCsv };
    const url = `https://us-central1-conectimed-9d22c.cloudfunctions.net/createPreregistro`;
    return this.http
      .post<any>(url, body, { observe: 'response', headers: headers })
      .toPromise();
  }
}
