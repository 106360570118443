import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ImportPostsRoutingModule } from './import-posts-routing.module';
import { ImportPostsComponent } from './import-posts.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, IonicModule, ImportPostsRoutingModule, HttpClientModule],
  declarations: [ImportPostsComponent]
})
export class ImportPostsModule { }
