import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UsersActionsRoutingModule } from './users-actions-routing.module';
import { UsersActionsComponent } from './users-actions.component';
import { HttpClientModule } from '@angular/common/http';
import { UserOptionsComponent } from './user-options/user-options.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserInformationComponent } from './user-information/user-information.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    UsersActionsRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    UsersActionsComponent,
    UserOptionsComponent,
    UserDetailComponent,
    UserInformationComponent
  ]
})
export class UsersActionsModule { }
