import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { Logger } from '@app/core';
import { LoadingController, ModalController } from '@ionic/angular';
import {UtilitiesService} from '../../shared/services/utilities.service';
const log = new Logger('DetailSurveyComponent');

@Component({
  selector: 'app-detail-survey',
  templateUrl: './detail-survey.component.html',
  styleUrls: ['./detail-survey.component.scss'],
})
export class DetailSurveyComponent implements OnInit {

  private id: string;
  public isLoading: boolean = false;
  survey: any = {};
  public myForm!: FormGroup | any;
  public questions: any[] = [];

  constructor(
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private afs: AngularFirestore,
    private utilities: UtilitiesService
  ) { }

  ngOnInit() {
    if (this.id) {
      this.initData(this.id);
    }
  }

  async initData(id: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const data = await this.afs.doc(`surveys-and-tests/${id}`).ref.get();
      this.survey = data.data();
      this.survey.html = this.utilities.trustHtml(this.survey.description);
      this.questions = Array.from(this.survey.questions);
      for (let [index, item] of this.questions.entries()) {
        log.debug(item, index);
      }
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  send() {
    log.debug(this.myForm.value)
  }

  toArray(length: number): any[] {
    let array: any[] = [];
    for (let i = 1; i <= length; i++) {
      array.push({ value: i });
    }
    return array;
  }

  
  rate(i: number, rate: number) {
    const control: FormControl = this.myForm.controls[i] as FormControl;
    control.patchValue(rate);
  }
}
