import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { ActivateRepRoutingModule } from './activate-rep-routing.module';
import { ActivateRepComponent } from './activate-rep.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CardsModule } from '../shared/cards/cards.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ActivateRepRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CardsModule
  ],
  declarations: [ActivateRepComponent]
})
export class ActivateRepModule {}
