import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ImportChatsRoutingModule } from './import-chats-routing.module';
import { ImportChatsComponent } from './import-chats.component';

@NgModule({
  imports: [CommonModule, IonicModule, ImportChatsRoutingModule],
  declarations: [ImportChatsComponent]
})
export class ImportChatsModule { }
