<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Modificar cuenta de usuarios</span></ion-title>
  </ion-toolbar>
  <ion-toolbar color="primary">
    <form [formGroup]="searchForm" (ngSubmit)="onSearchChange()">
      <ion-grid fixed>
        <ion-row>
          <ion-col size="10">
            <ion-item>
              <ion-input formControlName="search" type="text" placeholder="UID/Email"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="2">
            <ion-button type="submit" [disabled]="searchForm.invalid" color="light" expand="block">
              <ion-icon slot="icon-only" name="search"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-button (click)="initializeApp()" expand="block" fill="clear">
          <ion-icon slot="end" name="refresh"></ion-icon>
          Recargar
        </ion-button>
        <ion-list>
          <ion-item *ngFor="let item of users" lines="full" [ngSwitch]="item?.status">
            <ion-icon slot="start" name="eye" color="success" *ngSwitchCase="'active'"></ion-icon>
            <ion-icon slot="start" name="eye-off" color="medium" *ngSwitchCase="'inactive'"></ion-icon>
            <ion-icon slot="start" name="hourglass" color="secondary" *ngSwitchCase="'activation-pending'"></ion-icon>
            <ion-icon slot="start" src="assets/icons/sparkles.svg" color="primary" *ngSwitchCase="'new'"></ion-icon>
            <ion-icon slot="start" src="assets/icons/ellipsis-horizontal-outline.svg" color="medium" *ngSwitchDefault>
            </ion-icon>
            <ion-label>
              <p style="text-transform: capitalize;">
                <strong>{{ item?.lastName1 }} {{ item?.lastName2 }} {{ item?.name }}</strong>
              </p>
              <p>{{ item?.uid }}</p>
              <p>{{ item?.email }}</p>
              <p>{{ item?.date }}</p>
              <p>
                {{
                  item?.type === 'representante-medico'
                    ? 'Representante médico'
                    : item?.type === 'medico'
                    ? 'Médico'
                    : item?.type === 'administrador'
                    ? 'Administrador'
                    : ''
                }}
              </p>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="detail(item?.uid, $event)">
                Detalle
              </ion-button>
              <ion-button (click)="options(item?.uid, $event)">
                <ion-icon slot="icon-only" src="assets/icons/ellipsis-vertical-outline.svg"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
          <ion-item *ngIf="!users[0]" lines="none">
            <ion-label class="ion-text-center">Sin resultados</ion-label>
          </ion-item>
        </ion-list>

        <ion-grid fixed>
          <ion-row>
            <ion-col size="6" class="ion-no-margin ion-no-padding">
              <ion-button
                (click)="paginate('back')"
                expand="block"
                color="light"
                [disabled]="!cursorBack || isLoading == true"
              >
                <ion-icon slot="icon-only" src="assets/icons/chevron-back-outline.svg"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col size="6  " class="ion-no-margin ion-no-padding">
              <ion-button
                (click)="paginate('next')"
                expand="block"
                color="light"
                [disabled]="!cursorNext || isLoading == true"
              >
                <ion-icon slot="icon-only" src="assets/icons/chevron-forward-outline.svg"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
