<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Activar usuarios</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="activate()" [disabled]="isLoading">
        <ion-icon slot="start" name="checkmark"></ion-icon>
        Activar
      </ion-button>
      <ion-button (click)="inactivate()" [disabled]="isLoading">
        <ion-icon slot="start" name="close"></ion-icon>
        Inactivar
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-progress-bar *ngIf="isLoading === true" type="indeterminate"></ion-progress-bar>
</ion-header>

<ion-content color="light">
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item *ngFor="let item of arrayDocs; let i = index" [disabled]="isLoading">
            <ion-label>
              <p>{{ item?.uid }}</p>
              <p>
                {{ i + 1 }}.- <strong>Nombre: </strong>{{ item?.name + ' ' + item?.lastName1 + ' ' + item?.lastName2 }}
              </p>
              <p><strong>wp_id: </strong>{{ item?.wp_id }}</p>
              <p><strong>Email: </strong>{{ item?.email }}</p>
              <ion-chip *ngIf="item?.type === 'representante-medico'; else medico">
                <ion-label>Representante médico</ion-label>
              </ion-chip>
              <ng-template #medico>
                <ion-chip color="primary">
                  <ion-label>Médico</ion-label>
                </ion-chip>
              </ng-template>
              <ion-badge *ngIf="item?.status === 'active'; else inActive" color="success" mode="ios"> Activo</ion-badge>
              <ng-template #inActive>
                <ion-badge color="warning" mode="ios">No activo</ion-badge>
              </ng-template>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="initData('back')" [disabled]="isLoading || !(back === true)">
        <ion-icon slot="start" name="arrow-back"></ion-icon>
        Anterior
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="initData('forward')" [disabled]="isLoading || !(forward === true)">
        Siguiente
        <ion-icon slot="end" name="arrow-forward"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
