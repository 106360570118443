<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Encuestas y exámenes Conectimed</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-margin ion-no-padding" color="light">
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col size="12">
        <ion-card *ngFor="let item of surveys">
          <ion-card-header [color]="item?.status === 'active' ? 'secondary':'medium'">
            <ion-card-title>
              {{ item?.title }}
            </ion-card-title>
            <ion-card-subtitle> <ion-icon name="calendar"></ion-icon> {{ item?.dateStr }} {{ item?.status === 'active' ?
              '':"(Inactiva)" }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div class="limit-height" [innerHTML]="item?.html && item?.html !== null ? item?.html:''"></div>
          </ion-card-content>
          <ion-button fill="clear" *ngIf="!item?.isDeleteAccountForm === true"
            (click)="deleteSurvey(item?.id)"><ion-icon name="trash"></ion-icon></ion-button>
          <ion-button fill="clear" [routerDirection]="'forward'"
            [routerLink]="'/surveys-and-tests/set/' + item?.id"><ion-icon name="create"></ion-icon></ion-button>
          <ion-button fill="clear" (click)="detailSurvey(item?.id)"><ion-icon name="eye"></ion-icon></ion-button>
          <ion-button fill="clear" *ngIf="!item?.isDeleteAccountForm === true" (click)="getLink(item?.id)"><ion-icon
              name="link-outline"></ion-icon></ion-button>
          <ion-button fill="clear" *ngIf="!item?.isDeleteAccountForm === true" (click)="duplicate(item)"><ion-icon
              name="copy-outline"></ion-icon></ion-button>
          <ion-button fill="clear" [routerDirection]="'root'"
            [routerLink]="'/surveys-and-tests/detail/' + item?.id"><ion-icon
              name="stats-chart-outline"></ion-icon></ion-button>
          <ion-button fill="clear" (click)="setDeleteAccountFormAlert(item)"><ion-icon
              name="person-remove-outline"></ion-icon></ion-button>
          <ion-chip color="danger" *ngIf="item?.isDeleteAccountForm === true">
            <ion-label>Formulario de eliminación de cuenta</ion-label>
          </ion-chip>
          <ion-toggle *ngIf="!item?.isDeleteAccountForm === true" [checked]="item?.status === 'active' ? true : false"
            (ionChange)="inactive($event,item?.id)">{{item?.status === 'active' ?
            'Desactivar':'Activar'}}</ion-toggle>
        </ion-card>
        <ion-text color="medium" class="ion-text-center" *ngIf="!surveys[0]">
          <p>No se encontraron encuestas</p>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button [routerDirection]="'forward'" [routerLink]="'/surveys-and-tests/set/'">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>