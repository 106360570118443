import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClickBannerLogsComponent } from './click-banner-logs.component';
import { ClickBannerDetailComponent } from './click-banner-detail/click-banner-detail.component';
import * as moment from 'moment';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: '',
    redirectTo: `${moment().format('YYYY-MM-DD')}`,
    pathMatch: 'full'
  },
  { path: ':date', component: ClickBannerLogsComponent, data: { title: 'Click en Banners' } },
  { path: ':date/:id', component: ClickBannerDetailComponent, data: { title: 'Detalle click en Banners' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ClickBannerLogsRoutingModule { }
