<ion-app>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>Gestión de Compañias</ion-title>
    </ion-toolbar>
    <ion-toolbar color="secondary">
      <ion-searchbar
        placeholder="Buscar compañia"
        [debounce]="0"
        [(ngModel)]="search"
        (keyup.enter)="doSearch()"
        (ionCancel)="cancelSearch()"
        showCancelButton="always"
      ></ion-searchbar>
      <ion-buttons slot="end">
        <ion-button (click)="doSearch()">
          <ion-icon slot="icon-only" name="search"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="read-content">
      <ion-item *ngFor="let company of companies">
        <ion-label>
          <h4>{{ company.name }}</h4>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button (click)="openCompany(company?.id, true)" color="primary" title="Vista Previa">
            <ion-icon slot="icon-only" name="eye" mode="ios"></ion-icon>
          </ion-button>
          <ion-button (click)="openCompany(company?.id)" color="warning" title="Editar Compañia">
            <ion-icon slot="icon-only" name="create" mode="ios"></ion-icon>
          </ion-button>
          <ion-button (click)="deleteCompany(company)" color="danger" title="Editar Compañia">
            <ion-icon slot="icon-only" name="trash" mode="ios"></ion-icon>
          </ion-button>
          <!-- <ion-button (click)="onClick()" color="medium" *ngIf="company.status !== 'active'" title="Activar Compañia">
                    <ion-icon slot="icon-only" name="radio-button-off" mode="ios"></ion-icon>
                </ion-button>
                <ion-button (click)="onClick()" color="success" *ngIf="company.status === 'active'" title="Desactivar Compañia">
                    <ion-icon slot="icon-only" name="radio-button-on" mode="ios"></ion-icon>
                </ion-button> -->
        </ion-buttons>
      </ion-item>
    </div>

    <ion-infinite-scroll
      threshold="100px"
      [disabled]="onEnd || loading"
      position="bottom"
      (ionInfinite)="getCompanies()"
    >
      <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Cargando compañias ...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-content>

  <ion-footer>
    <ion-button (click)="openCompany()" expand="full" color="secondary">
      <ion-icon slot="end" name="add"></ion-icon>
      Agregar Compañia
    </ion-button>
  </ion-footer>
</ion-app>
