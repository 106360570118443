import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-click-banner-detail',
  templateUrl: './click-banner-detail.component.html',
  styleUrls: ['./click-banner-detail.component.scss']
})
export class ClickBannerDetailComponent implements OnInit {
  public users: any[] = [];
  dateStr: string;
  public item: any = {};

  constructor(
    private firestore: AngularFirestore,
    private firebaseService: FirebaseService,
    private aRoute: ActivatedRoute,
    private loadingCtrl: LoadingController
  ) {}

  ngOnInit() {
    this.initializeApp();
  }

  initializeApp() {
    this.aRoute.params.subscribe(params => {
      if (params && params.date && params.id) {
        this.init(String(params.id), String(params.date));
      }
    });
  }

  async init(id: string, date: string) {
    const loading = await this.loadingCtrl.create();
    loading.present();
    try {
      date = date ? date : moment().format('YYYY-MM-DD');
      this.dateStr = date;
      const resp = await this.firestore
        .collection('advertising')
        .doc(id)
        .ref.get();

      let day: any = {
        id: resp.id,
        ...resp.data() as any
      };

      try {
        const stringRef: string = `advertising/${day.id}/clicks/${date}`;
        const respDoc = await this.firestore.doc(stringRef).ref.get();
        day.count = respDoc.get('count') ? respDoc.get('count') : 0;

        day.info = await this.firebaseService.getType({
          advertising: day,
          link: day.link,
          productId: day.productId,
          repreId: day.repreId
        });
      } catch (error) {
        console.error(error);
      }

      this.item = day;

      this.users = await this.firebaseService.getUsersBanners(id, date);
    } catch (error) {
      console.error(error);
    }
    loading.dismiss();
  }
}
