<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center">
      Detalle de encuesta
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-text color="primary" class="ion-text-center">
          <h1>{{ survey?.title }}</h1>
        </ion-text>
        <div [innerHTML]="survey?.html"></div>
        <hr>
        <div>
          <div *ngFor="let item of questions; let i = index">
            <p><strong>{{ (i+1)+'.- '+ item?.text }}</strong></p>
            <div [ngSwitch]="item?.type">
              <ng-template [ngSwitchCase]="'multiple'">
                <ion-radio-group mode="md" *ngIf="item?.singleAnswer === true; else multipleAnswer">
                  <div *ngFor="let option of item?.options; let ii = index">
                    <ion-radio style="height: 18px;" [value]="ii" labelPlacement="end" justify="start">
                      <p class="ion-text-wrap">
                        {{ option?.text }} <ion-text *ngIf="option?.isOpen === true" color="success">
                          <span>Opción abierta</span>
                        </ion-text>
                      </p>
                    </ion-radio>
                    <br />
                  </div>
                </ion-radio-group>
                <ng-template #multipleAnswer>
                  <div *ngFor="let option of item?.options; let ii = index">
                    <ion-checkbox labelPlacement="end">{{
                      option?.text }}
                      <ion-text *ngIf="option?.isOpen === true" color="success">
                        <span>Opción abierta</span>
                      </ion-text>
                    </ion-checkbox>
                    <br>
                  </div>
                </ng-template>
              </ng-template>
              <ng-template [ngSwitchCase]="'rate'">
                <div class="ion-no-padding ion-margin-vertical">
                  <div class="custom-table">
                    <div class="custom-row">
                      <div class="custom-col" *ngIf="toArray(item?.max)[0] as rateValue">
                        <ion-button class="ion-no-margin ion-no-padding custom-star"
                          (click)="rate(i , rateValue?.value)" fill="clear"
                          [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                          <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                        </ion-button>
                        <br>
                        <span class="small-font"><small>Muy mala</small></span>
                      </div>
                      <div class="custom-col" *ngIf="toArray(item?.max)[1] as rateValue">
                        <ion-button class="ion-no-margin ion-no-padding custom-star"
                          (click)="rate(i , rateValue?.value)" fill="clear"
                          [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                          <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                        </ion-button>
                        <br>
                        <span class="small-font"><small>Mala</small></span>
                      </div>
                      <div class="custom-col" *ngIf="toArray(item?.max)[2] as rateValue">
                        <ion-button class="ion-no-margin ion-no-padding custom-star"
                          (click)="rate(i , rateValue?.value)" fill="clear"
                          [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                          <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                        </ion-button>
                        <br>
                        <span class="small-font"><small>Regular</small></span>
                      </div>
                      <div class="custom-col" *ngIf="toArray(item?.max)[3] as rateValue">
                        <ion-button class="ion-no-margin ion-no-padding custom-star"
                          (click)="rate(i , rateValue?.value)" fill="clear"
                          [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                          <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                        </ion-button>
                        <br>
                        <span class="small-font"><small>Buena</small></span>
                      </div>
                      <div class="custom-col" *ngIf="toArray(item?.max)[4] as rateValue">
                        <ion-button class="ion-no-margin ion-no-padding custom-star"
                          (click)="rate(i , rateValue?.value)" fill="clear"
                          [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                          <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                        </ion-button>
                        <br>
                        <span class="small-font"><small>Muy buena</small></span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template [ngSwitchCase]="'open'">
                <ion-input type="text" fill="outline"></ion-input>
              </ng-template>
            </div>
          </div>
        </div>
        <ion-button expand="block" shape="round">
          Enviar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>