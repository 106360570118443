import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { AngularFirestore, QueryDocumentSnapshot, DocumentData, DocumentReference } from '@angular/fire/compat/firestore';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { DatabaseService } from '@app/shared/services/database.service';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { SearchComponent } from '@app/search/search.component';
import { UtilitiesService } from '@app/shared/services/utilities.service';
const log = new Logger('Assign contacts(V2)');

interface MedicoResult {
  id: string;
  data?: any;
  success: boolean;
}

interface Results {
  representante: string;
  data?: any;
  medicos: MedicoResult[];
}

@Component({
  selector: 'app-assign-contacts-v2',
  templateUrl: './assign-contacts-v2.component.html',
  styleUrls: ['./assign-contacts-v2.component.scss']
})
export class AssignContactsV2Component implements OnInit {
  public isLoading: boolean;
  public mainArray: any[] = [];
  public step: number = 1;
  private reps: string[] = [];
  public repsRaw: any[] = [];
  private meds: string[] = [];
  public medsRaw: any[] = [];
  public asignacionType: 'especialidad-estado' | 'email' = 'especialidad-estado';
  public operationType: 'create' | 'delete' = 'create';
  public stringEmails: string;
  public results = {
    successful: 0,
    operations: 0,
    type: 'create'
  };
  operations: Results[] = [];
  private maxMedSize: number = 500;

  constructor(
    private afs: AngularFirestore,
    private databaseService: DatabaseService,
    private firebase: FirebaseService,
    private modalCtrl: ModalController,
    private loadingController: LoadingController,
    private utilities: UtilitiesService,
    private alertController: AlertController
  ) { }

  reset() {
    this.results = {
      operations: 0,
      successful: 0,
      type: 'create'
    };
    this.stringEmails = undefined;
    this.mainArray = [];
    this.step = 1;
    this.reps = [];
    this.repsRaw = [];
    this.meds = [];
    this.medsRaw = [];
    this.operations = [];
    this.asignacionType = 'especialidad-estado';
    this.operationType = 'create';
  }

  ngOnInit() { }

  async selectUsersFunction(type?: 'medico' | 'representante-medico') {
    try {
      const data: any[] = this.mainArray.map(e => e);
      const modal = await this.modalCtrl.create({
        component: SearchComponent,
        componentProps: {
          viewType: 'modal',
          selectedUsers: data,
          memberType: 'medico',
          searchMemberType: type
        }
      });
      modal.onDidDismiss().then(users => {
        if (users.data && users.data.users) {
          this.mainArray = users.data.users;
          if (this.mainArray.length > this.maxMedSize) {
            alert(
              `Has superado el límite máximo de operaciones, unicamente se actualizarán los primeros ${this.maxMedSize} registros.`
            );
            this.mainArray = this.mainArray.splice(0, this.maxMedSize);
          }
        }
      });
      await modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  step1() {
    this.step = 2;
    this.reps = this.mainArray.map(e => e.uid);
    this.repsRaw = this.mainArray;
    this.mainArray = [];
  }

  step2() {
    this.step = 3;
  }

  async step3() {
    if (this.asignacionType === 'email') {
      this.isLoading === true;
      const loadingOverlay = await this.loadingController.create({});
      loadingOverlay.present();
      try {
        let emails: string[] = await this.utilities.getEmails(this.stringEmails);
        if (emails.length > this.maxMedSize) {
          alert(
            `Has superado el límite máximo de operaciones, unicamente se actualizarán los primeros ${this.maxMedSize} registros.`
          );
          emails = emails.splice(0, this.maxMedSize);
        }
        let mainArray: any[] = [];
        for (let email of emails) {
          try {
            const response = await this.afs
              .collection('users')
              .ref.where('status', '==', 'active')
              .where('type', '==', 'medico')
              .where('email', '==', email)
              .get();
            if (response.empty === false) {
              const data: any = response.docs[0].data();
              const id = response.docs[0].id;
              mainArray.push({ id, ...data });
            }
          } catch (error) {
            log.error(error);
          }
        }
        if (mainArray && mainArray.length > 0) {
          this.meds = mainArray.map(e => e.id);
          this.medsRaw = mainArray;
          this.step = 4;
        } else {
          this.mainArray = [];
          this.utilities.toast('No se encontraron resultados de los emails ingresados.');
        }
      } catch (error) {
        log.error(error);
      }
      loadingOverlay.dismiss();
      this.isLoading === false;
    } else {
      this.meds = this.mainArray.map(e => e.id);
      this.medsRaw = this.mainArray;
      this.step = 4;
    }
  }

  step4() {
    if (this.operationType === 'create') {
      this.createConfirm();
    } else if (this.operationType === 'delete') {
      this.deleteConfirm();
    } else {
      this.utilities.toast('Ha ocurrido un error!');
    }
  }

  async createConfirm() {
    const alert = await this.alertController.create({
      header: `Confirmar conexiónes`,
      message: `Se crearán ${this.reps.length * this.meds.length} conexiónes.`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Crear',
          handler: () => {
            this.createFunction();
          }
        }
      ]
    });
    await alert.present();
  }

  async createFunction() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({ message: 'Por favor espere...' });
    loadingOverlay.present();
    try {
      const info = await this.create();
      await this.databaseService.updateFriendsObject(info);
      await this.databaseService.updateFriendsCountersAlphabet(info.ids);
      await this.databaseService.updateFriendsCountersFilters(info.ids);
      await this.databaseService.updateMyFriendsCounter(info.ids);
      await this.databaseService.updateFriendsCounters(info.ids);
      this.step = 5;
    } catch (e) {
      console.error(e);
    }
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async deleteFunction() {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({ message: 'Por favor espere...' });
    loadingOverlay.present();
    try {
      const info = await this.delete();
      await this.databaseService.updateMyFriendsCounter(info.ids);
      await this.databaseService.updateFriendsCounters(info.ids);
      this.step = 5;
    } catch (e) {
      console.error(e);
    }
    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async deleteConfirm() {
    const alert = await this.alertController.create({
      header: `Confirmar conexiónes`,
      message: `Se eliminarán  ${this.reps.length * this.meds.length} conexiónes.`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Eliminar',
          handler: () => {
            this.deleteFunction();
          }
        }
      ]
    });
    await alert.present();
  }

  async createFriendShip(initiator: string, requested: string) {
    let response: DocumentReference | undefined;
    try {
      response = await this.firebase.createFriendShip(initiator, requested);
    } catch (error) {
      log.error(error);
    }
    if (response && response.id) {
      const id = String(response.id);
      return { success: true, id: id, initiator: initiator, requested: requested };
    }
    return { success: false, id: undefined, initiator: initiator, requested: requested };
  }

  async create(): Promise<{ relations: any[]; ids: string[] }> {
    try {
      let promises: Promise<any>[] = [];
      let ids: string[] = [];
      let relations: any[] = [];

      for (let representante of this.reps) {
        let medicos: MedicoResult[] = [];
        for (let medico of this.meds) {
          medicos.push({ id: medico, success: false });
          promises.push(this.createFriendShip(representante, medico));
        }
        this.operations.push({ representante: representante, medicos: medicos });
      }

      const tmp = this.operations.map(e => e);

      tmp.forEach(e => {
        e.medicos.forEach(ee => {
          const index1: number = ids.indexOf(e.representante);
          const index2: number = ids.indexOf(ee.id);
          if (!(index1 > -1)) {
            ids.push(e.representante);
          }
          if (!(index2 > -1)) {
            ids.push(ee.id);
          }
          relations.push({ initiator: e.representante, requested: ee.id });
        });
      });

      const response = await Promise.all(promises);
      this.operations = this.operations.map(element => {
        const medicos = response.filter(e => {
          if (e.initiator === element.representante) {
            return element;
          }
          return undefined;
        });
        element.medicos.map(el => {
          let data = el;
          const index = medicos.map(e => e.requested).indexOf(el.id);
          if (index > -1) {
            data.success = medicos[index].success;
          }
          const index2 = this.medsRaw.map(e => e.id).indexOf(el.id);
          if (index2 > -1) {
            data.data = this.medsRaw[index];
          }
          return data;
        });
        const index = this.repsRaw.map(e => e.id).indexOf(element.representante);
        if (index > -1) {
          element.data = this.repsRaw[index];
        }
        return element;
      });
      this.results.type = 'create';
      this.results.operations = promises.length;
      this.results.successful = response.filter(e => {
        if (e && e.success === true) {
          return e;
        }
      }).length;
      return { relations: relations, ids: ids };
    } catch (error) {
      log.error(error);
    }
    return { relations: [], ids: [] };
  }

  async delete(): Promise<{ relations: any[]; ids: string[] }> {
    try {
      let promises1: Promise<{ ref: QueryDocumentSnapshot<DocumentData>; initiator: string; requested: string }>[] = [];
      let ids: string[] = [];
      let relations1: any[] = [];

      for (let representante of this.reps) {
        let medicos: MedicoResult[] = [];
        for (let medico of this.meds) {
          medicos.push({ id: medico, success: false });
          promises1.push(this.firebase.getRelationByIDs(representante, medico));
        }
        this.operations.push({ representante: representante, medicos: medicos });
      }

      const tmp = this.operations.map(e => e);

      tmp.forEach(e => {
        e.medicos.forEach(ee => {
          const index1: number = ids.indexOf(e.representante);
          const index2: number = ids.indexOf(ee.id);
          if (!(index1 > -1)) {
            ids.push(e.representante);
          }
          if (!(index2 > -1)) {
            ids.push(ee.id);
          }
          relations1.push({ initiator: e.representante, requested: ee.id });
        });
      });

      const response1 = await Promise.all(promises1);
      const relations = response1.map(e => {
        if (e && e.ref && e.ref.exists === true) {
          return { id: e.ref.id, initiator: e.initiator, requested: e.requested };
        } else {
          return { id: undefined, initiator: e.initiator, requested: e.requested };
        }
      });
      let promises2: Promise<{ success: boolean; initiator: string; requested: string }>[] = [];
      for (let relation of relations) {
        promises2.push(this.firebase.deleteFriendShip(relation.id, relation.initiator, relation.requested));
      }
      const response2 = await Promise.all(promises2);
      this.operations = this.operations.map(element => {
        const medicos = response2.filter(e => {
          if (e.initiator === element.representante) {
            return element;
          }
          return undefined;
        });
        element.medicos.map(el => {
          let data = el;
          const index = medicos.map(e => e.requested).indexOf(el.id);
          if (index > -1) {
            data.success = medicos[index].success;
          }
          const index2 = this.medsRaw.map(e => e.id).indexOf(el.id);
          if (index2 > -1) {
            data.data = this.medsRaw[index];
          }
          return data;
        });
        const index = this.repsRaw.map(e => e.id).indexOf(element.representante);
        if (index > -1) {
          element.data = this.repsRaw[index];
        }
        return element;
      });
      const results = response2.map(e => {
        if (e && e.success) {
          return e.success;
        } else {
          return false;
        }
      });
      this.results.type = 'delete';
      this.results.operations = promises1.length;
      this.results.successful = results.filter(e => {
        if (e === true) {
          return e;
        }
        return undefined;
      }).length;
      return { relations: relations1, ids: ids };
    } catch (error) {
      log.error(error);
    }
    return { relations: [], ids: [] };
  }

  confirmDelete(index: number) {
    this.mainArray.splice(index, 1);
    if (this.step === 1) {
      this.reps = this.mainArray.map(e => e.uid);
      this.repsRaw = this.mainArray;
    }
    if (this.step === 3) {
      this.meds = this.mainArray.map(e => e.uid);
      this.medsRaw = this.mainArray;
    }
  }
}
