import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { SurveysRoutingModule } from './surveys-routing.module';
import { SurveysComponent } from './surveys.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CardsModule } from '../shared/cards/cards.module';
import { AddSurveyComponent } from './add-survey/add-survey.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SurveysRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CardsModule
  ],
  declarations: [SurveysComponent, AddSurveyComponent]
})
export class SurveysModule {}
