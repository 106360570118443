import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Logger } from '@app/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
const log = new Logger('PlansComponent');
import { AddPlanComponent } from './add-plan/add-plan.component';
import { OptionsPlanComponent } from './options-plan/options-plan.component';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit, OnDestroy {
  mainCollection: AngularFirestoreCollection<any>;
  subscription: Subscription;
  public plans: any[] = [];
  public isLoading: boolean = false;

  constructor(
    private afs: AngularFirestore,
    private modalCtrl: ModalController,
    private popoverController: PopoverController
  ) {
    this.init();
  }

  init() {
    this.initPlans();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    try {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    } catch (error) {
      log.error(error);
    }
  }

  public async addPlan(id?: string) {
    try {
      const modal = await this.modalCtrl.create({
        component: AddPlanComponent,
        componentProps: { id: id }
      });
      return await modal.present();
    } catch (e) {
      log.error(e);
    }
  }

  private async initPlans() {
    try {
      this.mainCollection = this.afs.collection('plans', q => q.orderBy('date', 'asc'));
      this.subscription = this.mainCollection.snapshotChanges().subscribe(snap => {
        this.plans = snap.map(item => {
          const DATA: any = item.payload.doc.data();
          const ID: string = item.payload.doc.id;
          return { id: ID, ...DATA };
        });
      });
    } catch (e) {
      log.error(e);
    }
  }

  public async options(event: any, _default: boolean, id: string, status: string) {
    try {
      const popover = await this.popoverController.create({
        component: OptionsPlanComponent,
        event: event,
        translucent: true,
        componentProps: { _default: _default, id: id, status: status }
      });
      await popover.present();
      await popover.onDidDismiss();
    } catch (e) {
      log.error(e);
    }
  }
}
