import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WpServiceV2 } from '../../services/wpv2/wp.servicev2';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { CredentialsService } from '@app/core';
import { FirebaseService } from '@app/shared/services/firebase.service';
import { FIREBASE_API } from '../../../../environments/environment';
import { Logger } from '@app/core';
const log = new Logger('Specialties');

@Component({
  selector: 'app-specialites',
  templateUrl: './specialites.component.html',
  styleUrls: ['./specialites.component.scss']
})
export class SpecialitesComponent implements OnInit {
  public myArray: any[] = [];
  arrayData: any[] = [];
  isLoading: boolean;
  contacts: boolean;
  token: string;
  filter: string = '';

  constructor(
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private wpv2: WpServiceV2,
    private http: HttpClient,
    private credential: CredentialsService,
    private firebase: FirebaseService
  ) { }

  ngOnInit() {
    this.init();
  }

  private async init(): Promise<void> {
    this.isLoading = true;
    try {
      const specialites = await this.wpv2.getSpecialties();
      const inUse: any = await this.afs.collection('specialties-in-use').ref.get();
      let myArray = Array.from(specialites).filter((element: any) => {
        const index: number = inUse.docs.map((e: any) => e.data().id).indexOf(element.id);
        if (index > -1) {
          return element;
        }
      });
      myArray = myArray.map((element: any) => {
        const index: number = inUse.docs.map((e: any) => e.data().id).indexOf(element.id);
        if (index > -1) {
          return { id: element.id, label: element.name, isChecked: false, count: inUse.docs[index].data().count };
        } else {
          return { id: element.id, label: element.name, isChecked: false };
        }
      });
      if (this.contacts === true) {
        try {
          this.token = await this.firebase.getToken();
          const snap: any = await this.http
            .post(
              `${FIREBASE_API}filters`,
              { token: this.token, uid: this.credential.credentials.uid, filter: this.filter },
              { observe: 'body' }
            )
            .toPromise();
          const myFilters = snap['medico'];
          const reduced: any = myArray.reduce(function (filtered: any[], item: any) {
            try {
              if (item.id && myFilters && myFilters[item.id]) {
                filtered.push({ id: item.id, label: item.label, isChecked: item.isChecked, count: myFilters[item.id] });
              }
            } catch (e) {
              log.error(e);
            }
            return filtered;
          }, []);
          myArray = reduced;
        } catch (error) {
          log.error(error);
        }
      }
      this.myArray = myArray;
      this.refillData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  selectItem(item: any) {
    item.isChecked = true;
    const element = [item];
    this.modalCtrl.dismiss({ arrayData: element });
  }

  refillData() {
    if (this.arrayData && this.arrayData[0]) {
      this.arrayData.forEach(element => {
        const index: number = this.myArray.map(e => e.id).indexOf(element.id);
        if (index > -1) {
          this.myArray[index].isChecked = element.isChecked;
        }
      });
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({ arrayData: this.arrayData });
  }

  clearFilter() {
    this.modalCtrl.dismiss({ arrayData: [] });
  }
}
