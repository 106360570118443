import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pull-to-refresh',
  templateUrl: './pull-to-refresh.component.html',
  styleUrls: ['./pull-to-refresh.component.scss']
})
export class PullToRefreshComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
