import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import * as moment from 'moment';
import { Photo, CameraSource, CameraResultType, Camera } from '@capacitor/camera';
import { Logger, CredentialsService } from '@app/core';
import { FirebaseService } from './firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
const log = new Logger('FILES');

interface FileInterface {
  main: {
    url: string;
    path: string;
    metadata?: any;
  };
  thumbnail?: {
    url: string;
    path: string;
  };
  list?: {
    url: string;
    path: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  private thumbnailSrc: string;
  private middleSrc: string;
  private imageSrc: string;
  private imageFormats: string[] = ['png', 'jpg', 'jpeg'];

  constructor(
    private fireStorage: AngularFireStorage,
    private credential: CredentialsService,
    private firebase: FirebaseService,
    private utilities: UtilitiesService
  ) { }

  async uploadImage(imageSrc: string, path: string, thumbnailSrc?: any, middleSrc?: any): Promise<FileInterface> {
    try {
      let imageObj: FileInterface = {
        main: {
          url: '',
          path: '',
          metadata: {}
        },
        list: { url: '', path: '' },
        thumbnail: { url: '', path: '' }
      };
      const random =
        moment().days() +
        '-' +
        moment().month() +
        '-' +
        moment().year() +
        '_' +
        moment().hours() +
        '-' +
        moment().minutes() +
        '-' +
        moment().seconds() +
        '-' +
        moment().milliseconds();
      let format = 'png';
      let str = imageSrc;
      const indexJpg = str.search('image/jpeg');
      const indexPng = str.search('image/png');
      if (indexJpg > -1) {
        format = 'jpg';
      }
      if (indexPng > -1) {
        format = 'png';
      }

      const index = this.imageFormats.indexOf(String(format).toLowerCase());
      if (index > -1) {
        const myName = random + '-image.' + format;
        var storageRef1 = this.fireStorage.ref(`${path}/${myName}`);
        const imageResponse1 = await storageRef1.putString(imageSrc, 'data_url', { contentType: `image/${format}` });
        const downloadURL1 = await imageResponse1.ref.getDownloadURL();
        imageObj.main.url = downloadURL1;
        imageObj.main.path = `${path}/${myName}`;
        imageObj.main.metadata = {
          name: imageResponse1.metadata.name,
          contentType: imageResponse1.metadata.contentType
        };
        if (thumbnailSrc) {
          var storageRef2 = this.fireStorage.ref(`${path}/thumbnail/${myName}`);
          const imageResponse2 = await storageRef2.putString(thumbnailSrc, 'data_url', {
            contentType: `image/${format}`
          });
          const downloadURL2 = await imageResponse2.ref.getDownloadURL();
          imageObj.thumbnail.url = downloadURL2;
          imageObj.thumbnail.path = `${path}/thumbnail/${myName}`;
        }
        if (middleSrc) {
          var storageRef3 = this.fireStorage.ref(`${path}/list/${myName}`);
          const imageResponse3 = await storageRef3.putString(middleSrc, 'data_url', { contentType: `image/${format}` });
          const downloadURL3 = await imageResponse3.ref.getDownloadURL();
          imageObj.list.url = downloadURL3;
          imageObj.list.path = `${path}/list/${myName}`;
        }
      } else {
        this.utilities.toast('Formato de archivo no válido.', 'Error');
      }
      return imageObj;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async readURLFromDataUrl(image: Photo, thumbnailSrc: any, middleSrc: any) {
    try {
      thumbnailSrc = await this.thumbnailify(image.dataUrl, 100, 100);
      middleSrc = await this.thumbnailify(image.dataUrl, 320, 320);
      return { imageSrc: image.dataUrl, thumbnailSrc, middleSrc, file: image };
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async readURL(file: File, imageSrc: any, thumbnailSrc: any, middleSrc: any) {
    try {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => (imageSrc = reader.result);
        reader.readAsDataURL(file);
        const toBase64 = (file: any) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        const result: string = String(await toBase64(file).catch(e => Error(e)));
        thumbnailSrc = await this.thumbnailify(result, 100, 100);
        middleSrc = await this.thumbnailify(result, 320, 320);
        return { imageSrc, thumbnailSrc, middleSrc, file };
      }
      return undefined;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async thumbnailify(imageStr: string, targetWidth: number, targetHeight: number) {
    try {
      var img = new Image();
      const newImage = () =>
        new Promise((resolve, reject) => {
          img.onload = () => {
            var width = img.width,
              height = img.height,
              canvas = document.createElement('canvas'),
              ctx = canvas.getContext('2d');
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            ctx.drawImage(
              img,
              width > height ? (width - height) / 2 : 0,
              height > width ? (height - width) / 2 : 0,
              width > height ? height : width,
              width > height ? height : width,
              0,
              0,
              targetWidth,
              targetHeight
            );
            resolve(canvas.toDataURL());
          };
          img.onerror = error => reject(error);
        });
      img.src = imageStr;
      return newImage();
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async uploadAvatarFromCamera(image: Photo, path: string, uid: string = null) {
    try {
      const res = await this.readURLFromDataUrl(image, this.thumbnailSrc, this.middleSrc);
      this.middleSrc = res.middleSrc;
      this.thumbnailSrc = res.thumbnailSrc;
      this.imageSrc = res.imageSrc;
      const response = await this.uploadImage(res.imageSrc, path, this.thumbnailSrc, this.middleSrc);
      let Uid = null;
      if (uid) {
        Uid = uid;
      } else {
        Uid = this.credential.credentials.uid;
      }
      await this.firebase.updateAvatar(Uid, response);
      return response;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async uploadAvatarFromFile(file: File, path: string, uid: string = null): Promise<FileInterface> {
    try {
      const res = await this.readURL(file, this.imageSrc, this.thumbnailSrc, this.middleSrc);
      this.middleSrc = res.middleSrc;
      this.thumbnailSrc = res.thumbnailSrc;
      this.imageSrc = res.imageSrc;
      const response = await this.uploadImage(res.imageSrc, path, this.thumbnailSrc, this.middleSrc);
      let Uid = null;
      if (uid) {
        Uid = uid;
      } else {
        Uid = this.credential.credentials.uid;
      }
      await this.firebase.updateAvatar(Uid, response);
      return response;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async getImageChatFromCamera(image: Photo): Promise<FileInterface> {
    try {
      const res = await this.readURLFromDataUrl(image, this.thumbnailSrc, this.middleSrc);
      this.middleSrc = res.middleSrc;
      this.thumbnailSrc = res.thumbnailSrc;
      this.imageSrc = res.imageSrc;
      return {
        main: {
          path: this.imageSrc,
          url: ''
        }
      };
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async uploadImageChatFromFile(file: File, path: string): Promise<FileInterface> {
    try {
      const res = await this.readURL(file, this.imageSrc, this.thumbnailSrc, this.middleSrc);
      this.middleSrc = res.middleSrc;
      this.thumbnailSrc = res.thumbnailSrc;
      this.imageSrc = res.imageSrc;
      const response = await this.uploadImage(res.imageSrc, path);
      return response;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async uploadImageChatFromCamera(image: Photo, path: string): Promise<FileInterface> {
    try {
      const res = await this.readURLFromDataUrl(image, this.thumbnailSrc, this.middleSrc);
      this.middleSrc = res.middleSrc;
      this.thumbnailSrc = res.thumbnailSrc;
      this.imageSrc = res.imageSrc;
      const response = await this.uploadImage(res.imageSrc, path);
      return response;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async getImageChatFromFile(file: File): Promise<FileInterface> {
    try {
      const res = await this.readURL(file, this.imageSrc, this.thumbnailSrc, this.middleSrc);
      this.middleSrc = res.middleSrc;
      this.thumbnailSrc = res.thumbnailSrc;
      this.imageSrc = res.imageSrc;
      return {
        main: {
          path: this.imageSrc,
          url: ''
        }
      };
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  // tslint:disable-next-line: member-ordering
  public async takePicture(
    source: CameraSource,
    path: string,
    type: 'avatar' | 'preview' | 'chat',
    uid: string = null
  ): Promise<FileInterface> {
    let response: FileInterface;
    if (!source) {
      source = CameraSource.Camera;
    }
    try {
      const image: Photo = await Camera.getPhoto({
        quality: 80,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: source
      });
      switch (type) {
        case 'avatar':
          response = await this.uploadAvatarFromCamera(image, path, uid);
          break;
        case 'chat':
          response = await this.uploadImageChatFromCamera(image, path);
          break;
        case 'preview':
          response = await this.getImageChatFromCamera(image);
          break;
      }
      return response;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  public async selectImageFile(
    file: FileList,
    path: string,
    type: 'avatar' | 'preview' | 'chat',
    uid: string = null
  ): Promise<FileInterface> {
    let response: FileInterface;
    try {
      switch (type) {
        case 'avatar':
          response = await this.uploadAvatarFromFile(file.item(0), path, uid);
          break;
        case 'chat':
          response = await this.uploadImageChatFromFile(file.item(0), path);
          break;
        case 'preview':
          response = await this.getImageChatFromFile(file.item(0));
          break;
      }
      return response;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  public async selectAnyFile(file: FileList, path: string, formatsAccepted: string[]): Promise<FileInterface> {
    try {
      let fileObj: FileInterface = {
        main: { url: '', path: '' }
      };
      const random =
        moment().days() +
        '-' +
        moment().month() +
        '-' +
        moment().year() +
        '_' +
        moment().hours() +
        '-' +
        moment().minutes() +
        '-' +
        moment().seconds() +
        '-' +
        moment().milliseconds();
      const myName = random + '-file.' + file.item(0).name;
      let ext = myName.substr(myName.lastIndexOf('.') + 1);
      const index = formatsAccepted.indexOf(ext);
      if (index > -1) {
        var storageRef1 = this.fireStorage.ref(`${path}/${myName}`);
        const imageResponse1 = await storageRef1.put(file.item(0));
        const downloadURL1 = await imageResponse1.ref.getDownloadURL();
        fileObj.main.url = downloadURL1;
        fileObj.main.path = `${path}/${myName}`;
        return fileObj;
      } else {
        this.utilities.toast('Formato de archivo no válido.', 'Error');
        return undefined;
      }
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  public async deleteFile(url: string): Promise<any> {
    return await this.fireStorage.storage.refFromURL(url).delete();
  }
}
