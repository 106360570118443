<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>ClickMeeting Reportes</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-margin ion-no-padding" color="light">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-toggle style="text-transform: capitalize;" (ionChange)="changeStatus($event)">{{ conferenceStatus === 'active' ? 'inactivos' : 'activos' }}</ion-toggle>
        </ion-item>
        <ion-card *ngFor="let item of conferences" button [routerDirection]="'forward'" [routerLink]="[item?.id]">
          <ion-card-header color="primary">
            <ion-card-title>{{ item?.name }} </ion-card-title>
            <ion-card-subtitle *ngIf="item?.type === 0">
              <ion-icon name="time" color="success"></ion-icon> {{ item?.inicio }}
            </ion-card-subtitle>
            <ion-card-subtitle *ngIf="item?.type === 1">
              <ion-icon name="infinite" color="success"></ion-icon> Permanente
            </ion-card-subtitle>
            <ion-card-subtitle>
              <ion-badge color="success" *ngIf="item?.room_type === 'webinar'" size="small">WEBINAR EN VIVO</ion-badge>
              <ion-badge color="dark" *ngIf="item?.room_type === 'meeting'" size="small">REUNIÓN</ion-badge>
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content color="light">
            <div [innerHTML]="item?.trustHtml"></div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
