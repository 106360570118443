<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title><span translate>Notificaciones segmentadas</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <form [formGroup]="myForm" novalidate>
          <ion-list>
            <ion-item lines="inset">
              <ion-input type="text" labelPlacement="floating" label="Título" formControlName="title"
                placeholder="Máximo 30 caracteres"></ion-input>
            </ion-item>
            <ion-text color="danger"
              [hidden]="myForm.get('title').untouched || !myForm.get('title').getError('required')">
              <span class="ion-padding" translate>Título requerido</span>
            </ion-text>
            <ion-text color="danger" [hidden]="!myForm.get('title').getError('maxlength')">
              <span class="ion-padding" translate>Máximo 30 caracteres</span>
            </ion-text>
            <ion-item lines="inset">
              <ion-textarea labelPlacement="floating" label="Descripción" formControlName="message"
                placeholder="Máximo 180 caracteres"></ion-textarea>
            </ion-item>
            <ion-text color="danger"
              [hidden]="myForm.get('message').untouched || !myForm.get('message').getError('required')">
              <span class="ion-padding" translate>Descripción requerida</span>
            </ion-text>
            <ion-text color="danger" [hidden]="!myForm.get('message').getError('maxlength')">
              <span class="ion-padding" translate>Máximo 180 caracteres</span>
            </ion-text>
            <ion-item lines="inset">
              <ion-input labelPlacement="floating" label="Liga de referencia" type="text" formControlName="link"
                placeholder="Liga de referencia (Nota, producto, Representante)"></ion-input>
            </ion-item>
            <ion-text color="danger"
              [hidden]="myForm.get('link').untouched || !myForm.get('link').getError('required')">
              <span class="ion-padding" translate>Liga de requerida</span>
            </ion-text>
            <ion-text color="danger" [hidden]="!myForm.get('link').getError('noValidUrl')">
              <span class="ion-padding" translate>URL no válida</span>
            </ion-text>
            <ion-text color="danger" [hidden]="!myForm.get('link').getError('noValidDomain')">
              <span class="ion-padding" translate>La URL no corresponde a ninguno de los Dominios de Conectimed.</span>
            </ion-text>
            <ion-item lines="inset">
              <ion-input labelPlacement="floating" label="Etiqueta de Analytics" type="text" formControlName="analytics_label" placeholder="Máximo 30 caracteres"></ion-input>
            </ion-item>
            <ion-text color="danger" [hidden]="!myForm.get('analytics_label').getError('maxlength')">
              <span class="ion-padding" translate>Máximo 30 caracteres</span>
            </ion-text>
            <ion-text color="danger" [hidden]="!myForm.get('analytics_label').getError('noValidLabel')">
              <span class="ion-padding" translate>Puede incluir letras mayúsculas y minúsculas, números y los siguientes
                símbolos: '-', '~', '%'</span>
            </ion-text>
          </ion-list>
        </form>
      </ion-col>
    </ion-row>
    <!-- <ion-row>
      <ion-col>
        <ion-text class="ion-padding ion-margin">
          <ion-item lines="none">
            <ion-checkbox slot="start"></ion-checkbox>
            <ion-label>
              <strong>Especialidades</strong>
            </ion-label>
          </ion-item>
        </ion-text>
        <div class="scroll-y">
          <ion-list>
            <ion-item *ngFor="let item of specialties">
              <ion-label> {{ item?.label }} </ion-label>
              <ion-checkbox slot="start"></ion-checkbox>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
      <ion-col>
        <ion-text class="ion-padding">
          <ion-item lines="none">
            <ion-checkbox slot="start"></ion-checkbox>
            <ion-label>
              <strong>Zona geográfica</strong>
            </ion-label>
          </ion-item>
        </ion-text>
        <div class="scroll-y">
          <ion-list>
            <ion-item *ngFor="let item of locations">
              <ion-label> {{ item?.label }} </ion-label>
              <ion-checkbox slot="start"></ion-checkbox>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
    </ion-row> -->
    <!-- <ion-row>
      <ion-col>
        <ion-text class="ion-padding ion-margin">
          <ion-item lines="none">
            <ion-checkbox slot="start"></ion-checkbox>
            <ion-label>
              <strong>Correos de usuario</strong>
            </ion-label>
          </ion-item>
        </ion-text>
        <ion-item lines="none">
          <ion-textarea placeholder="Emails de usuarios" rows="6" cols="20"></ion-textarea>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-text class="ion-padding">
          <ion-item lines="none">
            <ion-checkbox slot="start"></ion-checkbox>
            <ion-label>
              <strong>Toda la base</strong>
            </ion-label>
          </ion-item>
        </ion-text>
      </ion-col>
    </ion-row> -->
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <ion-button (click)="send()" expand="block" fill="clear" [disabled]="myForm.invalid || isLoading === true">
          <ion-icon slot="start" name="add"></ion-icon>
          Enviar notificación
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>