<ion-app>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title><span translate>Gestionar Publicidad</span></ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="canReOrder = !canReOrder" fill="clear" slot="end">
          <ion-icon slot="icon-only" [name]="canReOrder === false ? 'list' : 'checkmark'"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="read-content">
      <ion-list>
        <ion-reorder-group (ionItemReorder)="doReorder($event)" [disabled]="!canReOrder">
          <ion-item *ngFor="let advertising of advertisings">
            <ion-label>
              <h2>{{ advertising?.content }}</h2>
              <ion-chip color="primary" *ngFor="let tag of advertising?.theTags">{{ tag?.name }}</ion-chip>
            </ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="openAdvertising(advertising, 'Editar')">
                <ion-icon slot="icon-only" name="create" color="warning"></ion-icon>
              </ion-button>
              <ion-button (click)="deleteAdvertising(advertising.id)">
                <ion-icon slot="icon-only" name="trash" color="danger"></ion-icon>
              </ion-button>
            </ion-buttons>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
        </ion-reorder-group>
      </ion-list>

      <ion-button (click)="saveOrder()" *ngIf="canReOrder === true" expand="block" color="success">
        Guardar orden
      </ion-button>
    </div>
  </ion-content>

  <ion-footer>
    <ion-button (click)="openAdvertising()" expand="full" fill="clear">
      <ion-icon slot="start" name="add"></ion-icon>
      Crear Nueva Publicidad
    </ion-button>
  </ion-footer>
</ion-app>