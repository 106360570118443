<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">
      Ver Perfil
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)" class="only-movil">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12" class="ion-text-center profilehero">
        <ion-avatar>
          <img [src]="user?.avatar ? user.avatar : 'assets/img/default-avatar.svg'" class="iab-disabled" />
        </ion-avatar>
        <h1>
          {{ user?.name }}
        </h1>
        <h4>
          {{ user?.type === 'medico' ? 'medico' : 'representante' }}
        </h4>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <app-pull-to-refresh *ngIf="!isLoading == true" class="only-movil"></app-pull-to-refresh>
        <app-refresh-web class="only-web" (click)="doRefresh($event)"></app-refresh-web>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item>
            <ion-label>
              <p><b>Nombre</b></p>
              <h2>
                {{ user?.name }}
              </h2>
            </ion-label>
          </ion-item>
          <div *ngIf="user?.type === 'medico'">
            <ion-item>
              <ion-label>
                <span *ngIf="user && user.estado1 && user.ciudad1">
                  <p><b>Zona geográfica 1</b></p>
                  <h2 *ngIf="user.estado1 === 'Ciudad de México'">
                    {{ user.estado1 + ', ' + user.colonia1 }}
                  </h2>
                  <h2 *ngIf="user.estado1 !== 'Ciudad de México'">
                    {{ user.estado1 + ', ' + user.ciudad1 }}
                  </h2>
                </span>
                <div *ngIf="user && user.estado2 && user.ciudad2">
                  <p><b>Zona geográfica 2</b></p>
                  <h2 *ngIf="user.estado2 === 'Ciudad de México'">
                    {{ user.estado2 + ', ' + user.colonia2 }}
                  </h2>
                  <h2 *ngIf="user.estado2 !== 'Ciudad de México'">
                    {{ user.estado2 + ', ' + user.ciudad2 }}
                  </h2>
                </div>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                <span *ngIf="user && user.especialidad1 as data">
                  <p><b>Especialidad 1</b></p>
                  <h2>
                    {{ data }}
                  </h2>
                </span>
                <div *ngIf="user && user.especialidad2 as data">
                  <p><b>Especialidad 2</b></p>
                  <h2>
                    {{ data }}
                  </h2>
                </div>
              </ion-label>
            </ion-item>
          </div>
          <div *ngIf="user?.type === 'representante-medico'">
            <ion-item lines="none">
              <ion-label>
                <p><b>Marca / Giro</b></p>
                <h2>
                  <span *ngIf="user && user.empresa as data">
                    {{ data }}
                  </span>
                  <small
                    >/
                    <span *ngIf="user && user.giro as data">
                      {{ data }}
                    </span>
                  </small>
                </h2>
              </ion-label>
            </ion-item>
          </div>
          <div *ngIf="isMyFriend == false">
            <ion-button
              fill="clear"
              expand="block"
              color="danger"
              (click)="confirmCancel()"
              [disabled]="isLoading == true"
              *ngIf="sentFalse == true"
            >
              <ion-icon name="close" slot="start"></ion-icon>
              Cancelar solicitud
            </ion-button>
            <ion-button
              fill="solid"
              expand="block"
              color="secondary"
              (click)="confirmAccept()"
              [disabled]="isLoading == true"
              *ngIf="requestFalse == true"
            >
              Aceptar solicitud
            </ion-button>
            <ion-button
              fill="clear"
              expand="block"
              color="danger"
              (click)="confirmReject()"
              [disabled]="isLoading == true"
              *ngIf="requestFalse == true"
            >
              <ion-icon name="close" slot="start"></ion-icon>
              Rechazar solicitud
            </ion-button>
            <ion-button
              fill="solid"
              expand="block"
              color="primary"
              (click)="confirmSend()"
              *ngIf="!(requestFalse == true || sentFalse == true) && !(isLoading == true)"
            >
              Solicitar conexión
            </ion-button>
            <p *ngIf="user?.type === 'medico'">
              Al enviar esta solicitud se descontarán <b>$200.00</b> + IVA a tu saldo que cargarán y facturarán
              únicamente cuando el médico haya aceptado.
            </p>
            <ion-button
              fill="solid"
              expand="block"
              color="tertiary"
              [routerDirection]="'forward'"
              [routerLink]="['/products/user/' + userid]"
              [disabled]="isLoading == true"
              *ngIf="user?.type === 'representante-medico'"
              (click)="closeModal()"
            >
              Productos
            </ion-button>
          </div>
          <div *ngIf="isMyFriend == true">
            <ion-button
              fill="solid"
              expand="block"
              [routerDirection]="'forward'"
              [routerLink]="['/products/user/' + userid]"
              [disabled]="isLoading == true"
              *ngIf="user?.type === 'representante-medico'"
              (click)="closeModal()"
            >
              <ion-icon slot="start" name="cube"></ion-icon>
              Productos
            </ion-button>
            <ion-button fill="solid" expand="block" (click)="chat()" [disabled]="isLoading == true">
              <ion-icon slot="start" name="chatbubbles"></ion-icon>
              Chat
            </ion-button>
            <ion-button
              fill="solid"
              expand="block"
              (click)="call($event)"
              [disabled]="isLoading == true"
              *ngIf="user?.type === 'representante-medico'"
            >
              <ion-icon slot="start" name="call"></ion-icon>
              Llamada
            </ion-button>
            <ion-button fill="solid" expand="block" (click)="scheduleCall($event)" [disabled]="isLoading == true">
              <ion-icon slot="start" name="calendar"></ion-icon>
              Agendar
            </ion-button>
            <ion-button fill="solid" expand="block" (click)="confirmDelete()" [disabled]="isLoading == true">
              <ion-icon slot="start" name="trash"></ion-icon>
              Eliminar Contacto
            </ion-button>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
