import { FormControl } from '@angular/forms';
import { APP_DOMAINS } from '../../../environments/environment';

export class ValidateDomain {
  static isValid(control: FormControl) {
    let result: boolean[] = [];
    const url: string = String(control.value);
    for (const domain of APP_DOMAINS) {
      const include: boolean = url.includes(domain);
      result.push(include);
    }
    const index: number = result.indexOf(true);
    let isValid: boolean;
    if (index > -1 || control.value === '') {
      isValid = true;
    }
    return isValid === true ? null : { noValidDomain: true };
  }
}
