import { env } from './.env';

export const environment = {
  production: false,
  hmr: false,
  version: env.npm_package_version,
  serverUrl: 'https://api.chucknorris.io',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  apiKey: 'AIzaSyDNse9Pg_XQHuA5Rq9Kl7mK2xj7EmS1-Ps',
  authDomain: 'conectimed-9d22c.firebaseapp.com',
  databaseURL: 'https://conectimed-9d22c.firebaseio.com',
  projectId: 'conectimed-9d22c',
  storageBucket: 'conectimed-9d22c.appspot.com',
  messagingSenderId: '373046668834',
  appId: '1:373046668834:web:2e90f93e4132ab902f247b',
  measurementId: 'G-4HZE3BRY9V',
  sendEmails: true
};

export const WP_JSON_URL = 'https://panel.conectimed.site/';

export const CLOUD_MESSAGING_SERVER_KEY =
  'AAAAVttQbiI:APA91bG4YpPb8Vfm5rEN5r3NMHFFeuVhO9dEzjDlim441QstGH_uMVcclfqQPBaR2l7frG-8nHTUsQnmC9FncPCDkpE0-s2AKNrNcheVXMaGiwII8CQqp8upmeVt-5ZWmuD_D7zLBfYp';

export const FIREBASE_API = 'https://us-central1-conectimed-9d22c.cloudfunctions.net/';

export const OPEN_PAY_ID = 'mnk1grtuhq4tioa0za8y';

export const OPEN_PAY_API_KEY = 'pk_903a7285320a4c97a93c6fd8d2f16e9a';

export const OPEN_PAY_SANDBOX = true;

export const CONTENT_EMAIL = 'contenidos@conectimed.com';

export const SALES_EMAIL = 'ventas@conectimed.com';

export const CONTACT_EMAIL = 'contacto@conectimed.com';

export const APP_URL = 'https://app.conectimed.site';

export const ADMIN_APP_URL = 'https://admin.conectimed.site';

export const DEEPLINK_URL = 'app.conectimed.site';

export const MAIN_PHONE = '5525893520';

export const WHATSAPP_PHONE = '5534303549';

export const URL_SALUD_360 = 'https://salud-360.com.mx/categorias/conectymed?sort_by=name-ascending';

export const WEB_VERSION = '5';

export const APP_DOMAINS = [
  'http://localhost:4200',
  'http://localhost:5000',
  'http://localhost:5005',
  'https://app.conectimed.site',
  'https://app.conectimed.com'
];

export const DEFAULT_USERS_CONNECTIONS = [
  {
    email: 'contacto@conectimed.com',
    name: 'Pablo Gorozpe',
    id: 683
  },
  {
    email: 'comercial@conectimed.com',
    name: 'Arturo Pando',
    id: 684
  },
  {
    email: 'representante@yopmail.com',
    name: 'Ernesto Suarez',
    id: 617
  }
];

export const DEFAULT_USERS_CONNECTIONS_IDS = ['h4f8ekk2I0QRLhdmqmTzeE6rwZE2', 'tVKSHEGHCFPdPS7DcoiRj3IxxAO2'];

export const DEFAULT_PLAN = 'zBDRyI7MrfDMPLMzNuj6';

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDNse9Pg_XQHuA5Rq9Kl7mK2xj7EmS1-Ps',
  authDomain: 'conectimed-9d22c.firebaseapp.com',
  databaseURL: 'https://conectimed-9d22c.firebaseio.com',
  projectId: 'conectimed-9d22c',
  storageBucket: 'conectimed-9d22c.appspot.com',
  messagingSenderId: '373046668834',
  appId: '1:373046668834:web:2e90f93e4132ab902f247b',
  measurementId: 'G-4HZE3BRY9V'
};

export const CLICK_MEETING = {
  url: 'https://api.clickmeeting.com/v1',
  'X-Api-Key': 'usd7a6b6b319b8978f1d9ed5a78ebcc811564174b4'
};
